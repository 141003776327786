import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'views/Root/Root';
import { Provider } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import { store } from './redux/store';
import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';

ReactDOM.render(
  <Provider store={store}>
    <Root />
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
      transition={Slide}
      theme="colored"
    />
  </Provider>,
  document.getElementById('root'),
);
