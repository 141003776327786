import { createSlice } from '@reduxjs/toolkit';

export const templateOptionsSlice = createSlice({
  name: 'templateOptions',
  initialState: {
    isDirty: false,
  },
  reducers: {
    setIsDirty: (state, action) => ({
      ...state,
      isDirty: action.payload,
    }),
  },
});

export const { setIsDirty } = templateOptionsSlice.actions;

export default templateOptionsSlice.reducer;
