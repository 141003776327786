import { notification } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';
import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ['notification'],
});

export const NotificationApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationsHistory: builder.query({
      query: ({ page, size, sort }) => {
        const params = new URLSearchParams({
          ...(page && { page }),
          ...(size && { size }),
          ...(sort && { sort }),
        });

        const keysForDel = [];
        params.forEach((value, key) => {
          if (value === 'undefined' || value === '') {
            keysForDel.push(key);
          }
        });

        keysForDel.forEach((key) => {
          params.delete(key);
        });

        return {
          url: `${notification}/notifications-history?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['notification'],
    }),

    getNewNotifications: builder.query({
      query: () => ({
        url: `${notification}/new-notifications`,
        method: 'GET',
        headers: {
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['notification'],
    }),

    putMarkReadNotification: builder.mutation({
      query: (notificationId) => ({
        url: `${notification}/mark-read-notification/${notificationId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['notification'],
    }),
  }),
});

export const {
  useGetNotificationsHistoryQuery,
  useLazyGetNotificationsHistoryQuery,
  useGetNewNotificationsQuery,
  usePutMarkReadNotificationMutation,
} = NotificationApi;
