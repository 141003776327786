import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import DataPickerComponent from 'components/atoms/DataPicker/DataPicker';
import Button from 'components/atoms/Button/Button';
import FileDropdownInput from 'components/molecules/FileDropdownInput/FileDropdownInput';
import FormColumn from 'components/atoms/FormColumn/FormColumn';
import FormField from 'components/molecules/FormField/FormField';
import FormDoubleField from 'components/molecules/FormDoubleField/FormDoubleField';
import { toast } from 'react-toastify';
import PopupFooter from 'components/molecules/PopupFooter/PopupFooter';
import { enums } from 'staticData/enums';
import { useLazyGetStageQuery } from 'redux/services/stageApi';
import SimilarTextInput from 'components/molecules/SimilarTextInput/SimilarTextInput';
import CustomTextarea from 'components/molecules/CustomTextarea/CustomTextarea';
import ButtonWithPopup from 'components/molecules/ButtonWithPopup/ButtonWithPopup';
import PopupConfirm from 'components/organisms/PopupConfirm/PopupConfirm';
import { useGetUserPositionQuery } from 'redux/services/usersApi';
import { useGetInvestmentListQuery } from 'redux/services/investmentApi';
import {
  useDeleteAbsenceMutation,
  usePostAbsenceMutation,
  usePutAbsenceMutation,
} from 'redux/services/absenceApi';
import Loader from 'components/atoms/Loader/Loader';
import DateTimePicker from 'components/atoms/DateTimePicker/DateTimePicker';
import SelectComponent from 'components/molecules/SelectComponent/SelectComponent';
import { handleNewField } from 'helpers/formsHelpers';
import { useSelector } from 'react-redux';
import { handleDownload } from 'helpers/handleDownload';
import LoaderBtn from 'components/atoms/LoaderBtn/LoaderBtn';
import AsyncSelectComponent from 'components/molecules/AsyncSelectComponent/AsyncSelectComponent';
import { todayWithNearestNextHour } from 'helpers/dateHelpers';
import AddAbsenseSchemaValidate from './AddAbsenceSchemaValidate';

const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  flex-wrap: wrap;
  cursor: auto;
`;

const EditButton = styled.button`
  padding: 6px 16px;
  border-radius: 13px;
  border: 0;
  font-size: 13px;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  background: ${({ theme }) => theme.accentColor};
  color: #ffffff;
  font-weight: 500;
  &:hover {
    background: ${({ theme }) => theme.accentColorHover};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const PopupAddAbsence = ({ data, handlePopup, permission }) => {
  const absencePermission = useSelector((state) => state.permissionsReducer.data.Nieobecności);
  const user = useSelector((state) => state.userReducer.data);
  const [investmentId, setInvestmentId] = useState('');
  const [activeEdit, setActiveEdit] = useState(false);
  const [postAbsence, { isSuccess: postSuccess, isLoading: isLoadingPost }] =
    usePostAbsenceMutation();
  const [putAbsence, { isSuccess: putSuccess, isLoading: isLoadingPut }] = usePutAbsenceMutation();

  const [deleteAbsence] = useDeleteAbsenceMutation();
  const { data: usersData } = useGetUserPositionQuery();
  const [trigger, { data: stageData }] = useLazyGetStageQuery();
  useEffect(() => {
    if (investmentId) {
      trigger({ id: investmentId }, { skip: !investmentId });
    }
  }, [investmentId]);

  useEffect(() => {
    setActiveEdit(data?.id ? !data?.id : !data?.id);
  }, [data]);

  useEffect(() => {
    if (postSuccess) {
      toast.success('Nieobecność dodana pomyślnie');
      handlePopup();
    } else if (putSuccess) {
      toast.success('Nieobecność edytowana pomyślnie');
      handlePopup();
    }
  }, [postSuccess, putSuccess]);

  return (
    <>
      {data ? (
        <Formik
          initialValues={{
            title: data.title || '',
            type: data.type || '',
            status: data.status || 'FOR_ACCEPTANCE',
            accepting: data.accepting ? data.accepting?.id : '',
            employee: data.employee?.id || '',
            series: data.series || '',
            until: data.until || '',
            startDate: data.calendar?.startDate || todayWithNearestNextHour,
            endDate: data.calendar?.endDate || '',
            investment: data.investment?.id || '',
            stage: data.stage?.id || '',
            adding: data.adding?.id || user.id,
            description: data.description || '',
            readRights: data.readRights || '',
            writeRights: data.writeRights || '',
            file: [],
          }}
          onSubmit={async (values) => {
            const requestBody = {
              ...(data?.id && {
                id: data.id,
              }),
              title: values.title,
              ...(values.type && { type: values.type }),
              ...(values.accepting && {
                accepting: {
                  id: values.accepting,
                },
              }),
              employee: {
                id: values.employee,
              },
              ...(values.series && { series: values.series }),
              ...(values.until && { until: values.until }),
              calendar: {
                startDate: values.startDate,
                endDate: values.endDate,
                status: values.status,
              },
              ...(values.investment && {
                investment: {
                  id: values.investment,
                },
              }),
              ...(values.stage && {
                stage: {
                  id: values.stage,
                },
              }),
              adding: {
                id: values.adding,
              },
              ...(values.description && { description: values.description }),
              readRights: values.readRights,
              writeRights: values.writeRights,
              files: values.file.map((item) => ({
                tags: ['nieobecność'],
                type: 'ABSENCE',
                file: item,
              })),
            };

            const { error, isLoading } = (await data.id)
              ? putAbsence(requestBody)
              : postAbsence(requestBody);

            if (error) {
              toast.error(error.data);
            }
          }}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={AddAbsenseSchemaValidate}
        >
          {({ values, setFieldValue, errors }) => {
            useEffect(() => setInvestmentId(values.investment), [values]);
            return (
              <StyledForm>
                <FormColumn col={6} padding="0 15px 0 0">
                  <FormField
                    label="*Temat"
                    render={
                      <SimilarTextInput
                        name="title"
                        error={errors.title}
                        disabled={!activeEdit}
                        value={values.title}
                      />
                    }
                  />
                  <FormField
                    label="*Typ"
                    render={
                      <SelectComponent
                        value={values.type}
                        data={handleNewField(enums.absenceType)}
                        fullWidth
                        error={errors.type}
                        onChange={(e) => setFieldValue('type', e.value)}
                        isClearable
                        disabled={!activeEdit}
                      />
                    }
                  />
                  <FormField
                    label="*Akceptujący"
                    render={
                      <SelectComponent
                        value={values.accepting}
                        data={handleNewField(usersData)}
                        fullWidth
                        error={errors.accepting}
                        onChange={(e) => setFieldValue('accepting', e.value)}
                        isClearable
                        disabled={!activeEdit}
                      />
                    }
                  />

                  <FormDoubleField
                    label="*Data"
                    render={
                      <div className="flex w-full flex-col gap-1 lg:flex-row">
                        <DateTimePicker
                          value={values.startDate}
                          placeholderText="Data od"
                          name="startDate"
                          error={errors.startDate}
                          disabled={!activeEdit}
                          marginRight="0"
                        />

                        <DateTimePicker
                          value={values.endDate}
                          placeholderText="Data do"
                          name="endDate"
                          disabled={!values.startDate || !activeEdit}
                          minDate={new Date(values.startDate)}
                          clear={!values.startDate}
                          marginRight="0"
                          error={errors.endDate}
                          key={2}
                        />
                      </div>
                    }
                  />

                  <FormDoubleField
                    label="Seria/Do"
                    render={[
                      <SelectComponent
                        value={values.series}
                        data={handleNewField(enums.series)}
                        fullWidth
                        error={errors.series}
                        onChange={(e) => setFieldValue('series', e.value)}
                        isClearable
                        disabled={!activeEdit}
                        key={1}
                      />,

                      <DataPickerComponent
                        placeholderText="Do"
                        name="until"
                        error={errors.until}
                        marginLeft="7px"
                        marginRight="0"
                        key={2}
                        value={values.until}
                        disabled={!activeEdit}
                        fw400
                      />,
                    ]}
                  />
                  <FormField
                    label="*Pracownik"
                    render={
                      <SelectComponent
                        value={values.employee}
                        data={handleNewField(usersData)}
                        fullWidth
                        error={errors.employee}
                        onChange={(e) => setFieldValue('employee', e.value)}
                        isClearable
                        disabled={!activeEdit}
                      />
                    }
                  />
                </FormColumn>
                <FormColumn col={6} padding="0 0 0 15px">
                  <FormField
                    label="Inwestycja"
                    render={
                      <AsyncSelectComponent
                        value={values.investment}
                        dataFunction={useGetInvestmentListQuery}
                        fullWidth
                        onChange={(e) => {
                          setFieldValue('investment', e.value);
                          setFieldValue('stage', '');
                        }}
                        error={errors.investment}
                        isClearable
                        disabled={!activeEdit}
                      />
                    }
                  />
                  <FormField
                    label="Etap inwestycji"
                    render={
                      <SelectComponent
                        value={values.stage}
                        data={handleNewField(stageData?.content)}
                        fullWidth
                        error={errors.stage}
                        onChange={(e) => setFieldValue('stage', e.value)}
                        isClearable
                        disabled={!stageData?.content.length || !investmentId || !activeEdit}
                        clear={!values.stage || !values.investment}
                      />
                    }
                  />

                  <FormField
                    label="Pliki"
                    render={
                      <FileDropdownInput
                        name="file"
                        error={errors.file}
                        marginBottom="0px"
                        onChange={setFieldValue}
                        placeholder="Dodaj pliki"
                        fileName={data?.files?.map((item) => (
                          <button
                            type="button"
                            className="px-1"
                            onClick={() => !activeEdit && handleDownload(item)}
                          >
                            {item.name}
                          </button>
                        ))}
                        updateFiles={(file) => {
                          setFieldValue('file', file);
                        }}
                        multiple
                        disabled={!activeEdit}
                      />
                    }
                  />
                  <FormField
                    label="*Dodający"
                    render={
                      <SelectComponent
                        value={values.adding}
                        data={handleNewField(usersData)}
                        fullWidth
                        error={errors.adding}
                        onChange={(e) => setFieldValue('adding', e.value)}
                        isClearable
                        disabled={!activeEdit}
                      />
                    }
                  />
                </FormColumn>
                <FormField
                  label="Opis"
                  render={<CustomTextarea name="description" disabled={!activeEdit} />}
                />
                {/* <FormField
                  label="*Prawa"
                  render={
                    <StyledSelectWrapper>
                      <FormField
                        label="Odczyt"
                        width="100%"
                        render={
                          <SelectComponent
                            value={values.readRights}
                            data={handleNewField(enums.rights)}
                            fullWidth
                            error={errors.readRights}
                            onChange={(e) => setFieldValue('readRights', e.value)}
                            isClearable
                            disabled={!activeEdit}
                          />
                        }
                      />
                      <FormField
                        label="Zapis"
                        width="100%"
                        render={
                          <SelectComponent
                            value={values.writeRights}
                            data={handleNewField(enums.rights)}
                            fullWidth
                            error={errors.writeRights}
                            onChange={(e) => setFieldValue('writeRights', e.value)}
                            isClearable
                            disabled={!activeEdit}
                          />
                        }
                      />
                    </StyledSelectWrapper>
                  }
                /> */}

                <PopupFooter spaceBetween={!!data?.id}>
                  {(permission?.[`usuwanie nieobecności`] ||
                    absencePermission?.[`usuwanie nieobecności`]) &&
                    data?.id && (
                      <ButtonWithPopup
                        width="small"
                        type="button"
                        content={PopupConfirm}
                        buttonText="Usuń"
                        secondary
                        data={{
                          elementName: data.title,
                          actionHandler: () => {
                            deleteAbsence(data.id);
                            handlePopup();
                          },
                        }}
                      />
                    )}
                  <div className="flex w-full justify-end ">
                    {(permission?.[`edytowanie nieobecności`] ||
                      absencePermission?.[`edytowanie nieobecności`]) &&
                    data.id &&
                    !activeEdit ? (
                      <EditButton type="button" onClick={() => setActiveEdit(!activeEdit)}>
                        Edytuj
                      </EditButton>
                    ) : (
                      (permission?.[`edytowanie nieobecności`] ||
                        absencePermission?.[`edytowanie nieobecności`]) &&
                      data.id && (
                        <ButtonsWrapper>
                          <Button type="button" cancel onClick={() => setActiveEdit(false)}>
                            Anuluj
                          </Button>
                          <Button disabled={isLoadingPost || isLoadingPut} type="submit">
                            Zapisz {(isLoadingPost || isLoadingPut) && <LoaderBtn />}
                          </Button>
                        </ButtonsWrapper>
                      )
                    )}
                    {!data.id && (
                      <Button disabled={isLoadingPost || isLoadingPut} type="submit">
                        Zapisz {(isLoadingPost || isLoadingPut) && <LoaderBtn />}
                      </Button>
                    )}
                  </div>
                </PopupFooter>
              </StyledForm>
            );
          }}
        </Formik>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PopupAddAbsence;
