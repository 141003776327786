import moment from 'moment';
import 'moment/locale/pl';

export const roundToNearestHour = (date, minutes) => {
  date.setMinutes(date.getMinutes() + minutes);
  date.setMinutes(0, 0, 0);

  return date;
};

export const dateFormat = (date) => moment(new Date(date)).format('YYYY-MM-DD');

export const dateTimeFormat = (date) => moment(new Date(date)).format('YYYY-MM-DD HH:mm');

export const today = moment(new Date()).format('YYYY-MM-DD');

export const todayWithTime = moment(new Date()).format('YYYY-MM-DD HH:mm');

export const dateWithNearestHour = (date) =>
  moment(roundToNearestHour(date, 30)).format('YYYY-MM-DD HH:mm');

export const todayWithNearestNextHour = moment(roundToNearestHour(new Date(), 60)).format(
  'YYYY-MM-DD HH:mm',
);

export const dateWithMinutes = (date, minutes) =>
  moment(date.setMinutes(date.getMinutes() + minutes)).format('YYYY-MM-DD HH:mm');
