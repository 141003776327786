import React from 'react';
import MenuList from 'components/atoms/MenuList/MenuList';
import MenuIconItem from 'components/molecules/MenuIconItem/MenuIconItem';
import styled from 'styled-components';
import AccessControl from 'components/atoms/AccessControl/AccessControl';
import { useSelector } from 'react-redux';
import {
  menuItemListFirstSection,
  menuItemListThirdSection,
  menuItemListSecondSection,
} from './menuItemList';

const StyledNav = styled.nav`
  overflow-y: auto;
`;

const SidebarMenu = () => {
  const user = useSelector((state) => state.userReducer.data?.customAccessGroup?.permissionGroups);

  const permissions = user?.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.groupName]: currentValue.permissions,
    }),
    '',
  );

  return (
    <StyledNav className="w-32 lg:w-60">
      <MenuList>
        {menuItemListFirstSection.map((item) => (
          <AccessControl
            key={item.name}
            permission={
              item?.permission
                ? permissions?.[item?.permission]?.some((permission) => permission.active === true)
                : true
            }
            renderNoAccess={
              <MenuIconItem
                key={item.name}
                icon={item.Icon}
                to={item.to}
                submenu={item.submenu}
                disabled
              >
                {item.name}
              </MenuIconItem>
            }
          >
            <MenuIconItem icon={item.Icon} to={item.to} submenu={item.submenu}>
              {item.name}
            </MenuIconItem>
          </AccessControl>
        ))}
      </MenuList>

      <MenuList>
        {menuItemListSecondSection().map((item) => (
          <AccessControl
            key={item.name}
            permission={
              item?.permission
                ? permissions?.[item?.permission]?.some((permission) => permission.active === true)
                : true
            }
            renderNoAccess={
              <MenuIconItem
                key={item.name}
                icon={item.Icon}
                to={item.to}
                submenu={item.submenu}
                disabled
              >
                {item.name}
              </MenuIconItem>
            }
          >
            <MenuIconItem icon={item.Icon} to={item.to} submenu={item.submenu}>
              {item.name}
            </MenuIconItem>
          </AccessControl>
        ))}
      </MenuList>
      <MenuList>
        {menuItemListThirdSection.map((item) => (
          <AccessControl
            key={item.name}
            permission={
              item?.permission
                ? permissions?.[item?.permission]?.some((permission) => permission.active === true)
                : true
            }
            renderNoAccess={
              <MenuIconItem
                key={item.name}
                icon={item.Icon}
                to={item.to}
                submenu={item.submenu}
                disabled
              >
                {item.name}
              </MenuIconItem>
            }
          >
            <MenuIconItem icon={item.Icon} to={item.to} submenu={item.submenu}>
              {item.name}
            </MenuIconItem>
          </AccessControl>
        ))}
      </MenuList>
    </StyledNav>
  );
};

export default SidebarMenu;
