import React from 'react';

const WorldSVG = ({ className }) => (
  <svg
    className={className}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="#498FE2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.5601 3.77063L15.3058 3.85126L13.9513 3.97193L13.5688 4.58286L13.2912 4.49466L12.2133 3.5228L12.0569 3.01739L11.8475 2.47842L11.17 1.87074L10.3707 1.71436L10.3523 2.08016L11.1353 2.84423L11.5184 3.29552L11.0877 3.52063L10.7371 3.41728L10.2116 3.19812L10.2295 2.77442L9.54009 2.49087L9.3112 3.48708L8.61639 3.64455L8.68512 4.20029L9.59042 4.37453L9.7468 3.48654L10.4941 3.59693L10.8415 3.80039H11.3989L11.7804 4.56446L12.7917 5.59044L12.7176 5.98925L11.9021 5.88535L10.493 6.59693L9.47841 7.81392L9.34637 8.35288H8.98219L8.30362 8.04011L7.64453 8.35288L7.80849 9.04823L8.09529 8.7176L8.59962 8.70191L8.56444 9.32637L8.98219 9.44866L9.3994 9.91728L10.0807 9.72572L10.8588 9.84855L11.7625 10.0915L12.2138 10.1446L12.9789 11.0131L14.4557 11.8816L13.5006 13.7062L12.4925 14.1749L12.1099 15.2176L10.651 16.1916L10.4957 16.7533C14.2252 15.855 16.9995 12.5049 16.9995 8.49953C16.9984 6.75007 16.4681 5.12182 15.5601 3.77063Z" />
    <path d="M9.4776 12.942L8.85855 11.7943L9.42674 10.6103L8.85855 10.4404L8.22057 9.79971L6.80715 9.48261L6.33799 8.50101V9.0838H6.13128L4.91321 7.43228V6.07568L4.02035 4.62384L2.6026 4.87655H1.64752L1.167 4.56161L1.78009 4.07568L1.16862 4.21692C0.431607 5.47558 0.00195312 6.93607 0.00195312 8.50047C0.00195312 13.1936 3.80661 16.9999 8.50033 16.9999C8.8618 16.9999 9.21624 16.968 9.56689 16.9258L9.47814 15.896C9.47814 15.896 9.86829 14.3668 9.86829 14.3149C9.86775 14.2624 9.4776 12.942 9.4776 12.942Z" />
    <path d="M3.1596 2.7408L4.66934 2.5303L5.36523 2.14881L6.14823 2.37446L7.39932 2.30519L7.82789 1.63149L8.45289 1.73431L9.97075 1.59199L10.389 1.13095L10.9789 0.737013L11.8133 0.862554L12.1174 0.816558C11.0184 0.299242 9.79542 0 8.49943 0C5.86144 0 3.50267 1.20238 1.94531 3.09037H1.94964L3.1596 2.7408ZM8.85873 0.845238L9.7267 0.367424L10.2841 0.689394L9.47724 1.30357L8.70668 1.38095L8.35981 1.15584L8.85873 0.845238ZM6.28785 0.915043L6.67096 1.07468L7.17259 0.915043L7.44585 1.38853L6.28785 1.69264L5.73103 1.36688C5.73049 1.36688 6.2754 1.01623 6.28785 0.915043Z" />
  </svg>
);

export default WorldSVG;
