import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import AttachmentSVG from 'components/atoms/SVG/AttachmentSVG/AttachmentSVG';

const toBase64 = (acceptedFiles, updateFiles) => {
  updateFiles([]);
  acceptedFiles.forEach((file) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      updateFiles((prev) => [...prev, `[${file.name}]${reader.result}`]);
    };
  });
};

const FileDropdownInput = ({
  multiple,
  updateFiles,
  marginBottom,
  fileName,
  error,
  disabled,
  className,
}) => {
  const { acceptedFiles, fileRejections, isDragActive, getRootProps, getInputProps } = useDropzone({
    maxFiles: multiple ? 5 : 1,
    minSize: 0,
    maxSize: 3145728,
    disabled,
  });

  const [files, setFiles] = useState([]);

  useEffect(() => {
    updateFiles(files);
  }, [files]);

  useEffect(() => {
    toBase64(acceptedFiles, setFiles);
  }, [acceptedFiles]);

  const acceptedFileItems = acceptedFiles.map((file) => <span className="px-1">{file.path}</span>);

  return (
    <StyledWrapper marginBottom={marginBottom} className={className}>
      <StyledLabel
        className={disabled ? 'cursor-auto' : 'cursor-pointer'}
        {...getRootProps()}
        error={!!fileRejections.length || error}
        isDragActive={isDragActive}
        disabled={disabled}
      >
        <AttachmentSVG />
        <StyledSpan>
          {(acceptedFileItems?.length && acceptedFileItems) ||
            (fileName?.length && fileName) ||
            'Wybierz'}
          {/* {acceptedFileItems.length ? acceptedFileItems : 'Wybierz'} */}
        </StyledSpan>
        <StyledField {...getInputProps()} type="file" multiple={multiple} />
      </StyledLabel>
    </StyledWrapper>
  );
};

export default FileDropdownInput;

const StyledField = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 10px;
  border: 1px solid #e6e6e6;
  justify-content: center;

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: #f2f2f2;
        `
      : null}

  ${({ error }) =>
    error
      ? css`
          border: 1px solid ${({ theme }) => theme.warrningColor};
        `
      : null}
  ${({ isDragActive }) =>
    isDragActive
      ? css`
          border: 1px solid ${({ theme }) => theme.accentColor};
        `
      : null}
  svg {
    margin-right: 10px;
  }
`;

const StyledSpan = styled.span`
  font-size: 14px;
  text-decoration: underline;
  color: #666666;
  margin: 0 !important;
  display: flex;
  flex-wrap: wrap;
`;

const StyledWrapper = styled.section`
  position: relative;
  margin-bottom: ${({ marginBottom }) => marginBottom || '3.6rem'};
  width: 100%;
  overflow: hidden;
`;

FileDropdownInput.propTypes = {
  name: PropTypes.string.isRequired,
  marginBottom: PropTypes.string,
};

FileDropdownInput.defaultProps = {
  marginBottom: '3.6rem',
};
