import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import DataPickerComponent from 'components/atoms/DataPicker/DataPicker';
import Button from 'components/atoms/Button/Button';
import FileDropdownInput from 'components/molecules/FileDropdownInput/FileDropdownInput';
import FormColumn from 'components/atoms/FormColumn/FormColumn';
import FormField from 'components/molecules/FormField/FormField';
import FormDoubleField from 'components/molecules/FormDoubleField/FormDoubleField';
import { useGetSearchUserQuery, useGetUserPositionQuery } from 'redux/services/usersApi';
import { enums } from 'staticData/enums';
import { theme as mainTheme } from 'theme/mainTheme';
import {
  usePostTaskMutation,
  useDeleteTaskMutation,
  usePutTaskMutation,
} from 'redux/services/taskApi';
import { useLazyGetInvestmentContractorQuery } from 'redux/services/contractorApi';
import { useLazyGetStageQuery } from 'redux/services/stageApi';
import CustomTextarea from 'components/molecules/CustomTextarea/CustomTextarea';
import { toast } from 'react-toastify';
import PopupFooter from 'components/molecules/PopupFooter/PopupFooter';
import ButtonWithPopup from 'components/molecules/ButtonWithPopup/ButtonWithPopup';
import {
  useGetInvestmentListQuery,
  useLazyGetInvestmentListQuery,
} from 'redux/services/investmentApi';
import DateTimePicker from 'components/atoms/DateTimePicker/DateTimePicker';
import SelectComponent from 'components/molecules/SelectComponent/SelectComponent';
import { handleNewField } from 'helpers/formsHelpers';
import Checkbox from 'components/molecules/Checkbox/Checkbox';
import { handleDownload } from 'helpers/handleDownload';
import { useLazyGetContactsQuery } from 'redux/services/contactApi';
import LoaderBtn from 'components/atoms/LoaderBtn/LoaderBtn';
import { useSelector } from 'react-redux';
import AsyncSelectComponent from 'components/molecules/AsyncSelectComponent/AsyncSelectComponent';
import { dateWithMinutes, todayWithNearestNextHour } from 'helpers/dateHelpers';
import SimilarTextInput from 'components/molecules/SimilarTextInput/SimilarTextInput';
// import { useGetTaskTypeQuery } from 'redux/services/taskTypeApi';
// import { useGetTaskKindQuery } from 'redux/services/taskKindApi';
import taskFormSchemaValidate from './taskFormSchemaValidate';
import PopupConfirm from '../PopupConfirm/PopupConfirm';

const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  flex-wrap: wrap;
  cursor: auto;
`;

const EditButton = styled.button`
  padding: 6px 16px;
  border-radius: 13px;
  border: 0;
  font-size: 13px;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  background: ${({ theme }) => theme.accentColor};
  color: #ffffff;
  font-weight: 500;
  &:hover {
    background: ${({ theme }) => theme.accentColorHover};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const FormCalendarTask = ({ data, handlePopup, typeKind, setType, permission }) => {
  const { isDuplicated, setIsDuplicated, activeEdit, setActiveEdit } = data;
  const [contractor, setContractor] = useState(data?.task ? data.task?.contractor?.id : null);
  const [investment, setInvestment] = useState(data?.investment || null);
  const [contactData, setContactData] = useState(null);
  const { data: usersData } = useGetUserPositionQuery();
  const { data: currUser } = useSelector((state) => state.userReducer);
  // const { data: taskTypeData } = useGetTaskTypeQuery();
  // const { data: taskKindData } = useGetTaskKindQuery();

  const [getInvestmentContractor, { data: investmentContractors }] =
    useLazyGetInvestmentContractorQuery();

  const [getStage, { data: stageData }] = useLazyGetStageQuery();

  useEffect(() => {
    if (investment.id) {
      getInvestmentContractor(investment.id);
      getStage({ id: investment.id }, { skip: !investment });
    }
  }, [investment]);
  const [trigger, { data: contractorContactData }] = useLazyGetContactsQuery();
  useEffect(() => {
    if (contractor) {
      trigger({ contractorId: contractor });
    }
  }, [contractor]);

  const [getInvestmentList, { data: investmentList }] = useLazyGetInvestmentListQuery();

  useEffect(() => {
    if (data?.id || data?.data?.id) {
      getInvestmentList();
    }
  }, []);

  useEffect(() => {
    setActiveEdit(!data?.id);
  }, []);

  const [postTask, { isSuccess: postSuccess, isError: postError, isLoading: isLoadingPost }] =
    usePostTaskMutation();
  const [putTask, { isSuccess: putSuccess, isError: putError, isLoading: isLoadingPut }] =
    usePutTaskMutation();
  const [deleteTask] = useDeleteTaskMutation();

  useEffect(() => {
    if (contractorContactData)
      setContactData(
        contractorContactData.content?.map((item) => ({
          id: item.id,
          name: item.nameSurname,
        })),
      );
  }, [contractorContactData]);

  useEffect(() => {
    if (postSuccess) {
      toast.success('Zadanie dodane pomyślnie');
      handlePopup(false);
    } else if (putSuccess) {
      toast.success('Zadanie edytowane pomyślnie');
      // eslint-disable-next-line no-unused-expressions
      !isDuplicated && handlePopup(false);
    }

    if (postError || putError) {
      toast.error('Coś poszło nie tak');
    }
  }, [postSuccess, putSuccess, postError, putError]);

  return (
    <Formik
      initialValues={{
        type: data.taskType || '',
        kind: data.taskKind || '',
        participants: data?.participants?.map((item) => item.id) || '',
        series: data.series || '',
        producer: data.producer?.id || '',
        startDate: data.calendar.startDate || '',
        endDate: data.calendar.endDate || '',
        delegator: data.delegator?.id || '',
        until: data.until || '',
        priority: data.priority || '',
        file: [],
        remindDelegator: data.remindDelegator || '',
        remindProducer: data.remindProducer || '',
        status: data.calendar.status || '',
        contractor: data.contractor?.id || '',
        contact: data.contact?.id || '',
        investment: data.investment || '',
        stage: data.stage?.id || '',
        producerDescription: data.producerDescription || '',
        delegatorDescription: data.delegatorDescription || '',
        sendMail: data.sendMail || false,
        id: data.id,
      }}
      onSubmit={async (values) => {
        const requestBody = {
          ...(values.id && {
            id: values.id,
          }),
          taskType: values.type,
          taskKind: values.kind,
          participants: values.participants.map((participant) => ({
            id: participant,
          })),
          calendar: {
            startDate: values.startDate,
            endDate: values.endDate,
            status: values.status,
          },
          sendMail: values.sendMail,
          ...(values.series && { series: values.series }),
          ...(values.until && { until: values.until }),
          producer: {
            id: values.producer,
          },
          ...(values.delegator && {
            delegator: {
              id: values.delegator,
            },
          }),

          files: values.file.map((item) => ({
            tags: ['task'],
            type: 'TASK',
            file: item,
          })),

          ...(values.priority && { priority: values.priority }),
          ...(values.remindProducer && { remindProducer: values.remindProducer }),
          ...(values.remindDelegator && { remindDelegator: values.remindDelegator }),
          ...(values.contractor && {
            contractor: {
              id: values.contractor,
            },
          }),
          ...(values.contact && {
            contact: {
              id: values.contact,
            },
          }),
          investment: {
            id: values.investment.id || data.data?.id,
          },
          ...(values.stage && {
            stage: {
              id: values.stage,
            },
          }),
          ...(values.producerDescription && { producerDescription: values.producerDescription }),
          ...(values.delegatorDescription && { delegatorDescription: values.delegatorDescription }),
        };

        const { error } =
          (await data.id) && !isDuplicated ? putTask(requestBody) : postTask(requestBody);

        if (error) {
          toast.error(error.data);
        }
      }}
      validationSchema={taskFormSchemaValidate}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors, resetForm, setFieldValue, dirty, handleSubmit }) => {
        useEffect(() => {
          setType(values.type);
          setInvestment(values.investment);
          setContractor(values.contractor || '');
        }, [values]);

        useEffect(() => {
          if (!values.endDate && values.type === 'PHONE') {
            setFieldValue('endDate', dateWithMinutes(new Date(values.startDate), 15));
          } else if (!values.endDate && values.type !== '') {
            setFieldValue('endDate', dateWithMinutes(new Date(values.startDate), 60));
          }
        }, [values.type]);

        return (
          <StyledForm>
            <FormColumn col={6} padding="0 15px 0 0">
              <FormField
                label="*Typ"
                render={
                  <SelectComponent
                    value={values.type}
                    // data={handleNewField(taskTypeData)}
                    data={handleNewField(enums.taskKind)}
                    fullWidth
                    error={errors.type}
                    onChange={(e) => {
                      setFieldValue('kind', '');
                      setFieldValue('type', e.value);
                    }}
                    isClearable
                    disabled={!activeEdit}
                    clear={!values.type}
                  />
                }
              />
              {typeKind && (
                <FormField
                  label="Rodzaj"
                  render={
                    <SelectComponent
                      value={values.kind}
                      // data={handleNewField(taskKindData)}
                      data={handleNewField(typeKind)}
                      fullWidth
                      error={errors.kind}
                      onChange={(e) => setFieldValue('kind', e.label)}
                      isClearable
                      disabled={!activeEdit}
                      clear={!values.type || !values.kind}
                    />
                  }
                />
              )}
              <FormField
                label="*Uczestnicy"
                render={
                  <AsyncSelectComponent
                    value={values.participants}
                    dataFunction={useGetSearchUserQuery}
                    fullWidth
                    error={errors.participants}
                    onChange={(e) => setFieldValue('participants', e)}
                    isClearable
                    disabled={!activeEdit}
                    isMulti
                  />
                }
              />
              <FormDoubleField
                label="*Data"
                render={
                  <div className="flex w-full flex-col gap-1 lg:flex-row">
                    <DateTimePicker
                      value={values.startDate}
                      placeholderText="Data od"
                      name="startDate"
                      error={errors.startDate}
                      disabled={!activeEdit}
                      marginRight="0"
                      clear={!values.startDate}
                    />

                    <DateTimePicker
                      value={values.endDate}
                      placeholderText="Data do"
                      name="endDate"
                      disabled={!values.startDate || !activeEdit}
                      minDate={new Date(values.startDate)}
                      clear={!values.startDate || !values.endDate}
                      marginRight="0"
                      error={errors.endDate}
                      key={2}
                    />
                  </div>
                }
              />

              <FormDoubleField
                label="Seria/Do"
                render={[
                  <SelectComponent
                    value={values.series}
                    data={handleNewField(enums.series)}
                    fullWidth
                    error={errors.series}
                    onChange={(e) => setFieldValue('series', e.value)}
                    isClearable
                    disabled={!activeEdit}
                    key={1}
                    clear={!values.endDate || !values.series}
                  />,

                  <DataPickerComponent
                    placeholderText="Do"
                    name="until"
                    error={errors.until}
                    marginLeft="7px"
                    marginRight="0"
                    disabled={!activeEdit}
                    key={2}
                    value={values.until}
                    fw400
                    clear={!values.until}
                  />,
                ]}
              />

              <FormField
                label="*Realizator"
                render={
                  <SelectComponent
                    value={values.producer}
                    data={handleNewField(usersData)}
                    fullWidth
                    error={errors.producer}
                    onChange={(e) => setFieldValue('producer', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />

              <FormField
                label="*Delegujący"
                render={
                  <SelectComponent
                    value={values.delegator}
                    data={handleNewField(usersData)}
                    fullWidth
                    error={errors.delegator}
                    onChange={(e) => setFieldValue('delegator', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />
              <FormField
                label="Piorytet"
                render={
                  <SelectComponent
                    value={values.priority}
                    data={handleNewField(enums.priority)}
                    fullWidth
                    error={errors.priority}
                    onChange={(e) => setFieldValue('priority', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />
              <FormField
                label="Opis realizatora"
                render={
                  <CustomTextarea
                    name="producerDescription"
                    error={errors.producerDescription}
                    value={values.producerDescription}
                    disabled={!activeEdit}
                  />
                }
              />
            </FormColumn>
            <FormColumn col={6} padding="0 0 0 15px">
              <FormField
                label="*Status"
                render={
                  <SelectComponent
                    value={values.status}
                    data={handleNewField(enums.taskStatus)}
                    fullWidth
                    error={errors.status}
                    onChange={(e) => setFieldValue('status', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />
              <FormField
                label="*Inwestycja"
                render={
                  !data.inCalendar && data?.investment?.id ? (
                    <SimilarTextInput name="investment" value={data?.investment?.name} disabled />
                  ) : (
                    <AsyncSelectComponent
                      value={values.investment.id}
                      dataFunction={useGetInvestmentListQuery}
                      fullWidth
                      currData={values.investment}
                      error={errors.investment}
                      onChange={(e) => {
                        setFieldValue('investment', { id: e.value, name: e.label });
                        setFieldValue('contractor', '');
                        setFieldValue('stage', '');
                      }}
                      isClearable
                      disabled={!activeEdit}
                    />
                  )
                }
                error={errors.investment}
              />
              <FormField
                label="Kontrahent"
                render={
                  <SelectComponent
                    value={values.contractor}
                    data={handleNewField(investmentContractors)}
                    fullWidth
                    error={errors.contractor}
                    onChange={(e) => {
                      setFieldValue('contractor', e.value);
                      setFieldValue('contact', '');
                    }}
                    isClearable
                    disabled={!investment || !investmentContractors?.length || !activeEdit}
                    clear={!values.contractor || !values.investment}
                  />
                }
              />
              <FormField
                label="Kontakt"
                render={
                  <SelectComponent
                    value={values.contact}
                    data={handleNewField(contactData)}
                    fullWidth
                    error={errors.contact}
                    onChange={(e) => {
                      setFieldValue('contact', e.value);
                    }}
                    isClearable
                    disabled={
                      !contractorContactData?.content?.length ||
                      !contractor ||
                      !investment ||
                      !activeEdit
                    }
                    clear={!values.contact || !values.contractor || !values.investment}
                  />
                }
              />

              <FormField
                label="Etap inwestycji"
                render={
                  <SelectComponent
                    value={values.stage}
                    data={handleNewField(stageData?.content)}
                    fullWidth
                    error={errors.stage}
                    onChange={(e) => {
                      setFieldValue('stage', e.value);
                    }}
                    isClearable
                    disabled={!stageData?.content?.length || !activeEdit}
                    clear={!stageData?.content?.length}
                  />
                }
              />
              <FormField
                label="Przyp. del"
                render={
                  <SelectComponent
                    value={values.remindDelegator}
                    data={handleNewField(enums.remindDelegator)}
                    fullWidth
                    error={errors.remindDelegator}
                    onChange={(e) => setFieldValue('remindDelegator', e.value)}
                    isClearable
                    disabled={!activeEdit}
                    clear={!values.remindDelegator}
                  />
                }
              />
              <FormField
                label="Przyp. real."
                render={
                  <SelectComponent
                    value={values.remindProducer}
                    data={handleNewField(enums.remindProducer)}
                    fullWidth
                    error={errors.remindProducer}
                    onChange={(e) => setFieldValue('remindProducer', e.value)}
                    isClearable
                    disabled={!activeEdit}
                    clear={!values.remindProducer}
                  />
                }
              />
              <FormField
                label="Pliki"
                render={
                  <FileDropdownInput
                    name="file"
                    error={errors.file}
                    marginBottom="0px"
                    onChange={setFieldValue}
                    placeholder="Dodaj pliki"
                    fileName={data?.files?.map((item) => (
                      <button
                        type="button"
                        className="px-1"
                        onClick={() => !activeEdit && handleDownload(item)}
                      >
                        {item.name}
                      </button>
                    ))}
                    updateFiles={(file) => {
                      setFieldValue('file', file);
                    }}
                    multiple
                    disabled={!activeEdit}
                  />
                }
              />

              <FormField
                label="Opis delegującego"
                render={
                  <CustomTextarea
                    name="delegatorDescription"
                    error={errors.delegatorDescription}
                    value={values.delegatorDescription}
                    disabled={!activeEdit}
                  />
                }
              />
            </FormColumn>

            <PopupFooter spaceBetween={!!data?.id}>
              {permission?.[`edytowanie zadań`] && (
                <div className="absolute left-1/2 -translate-x-1/2 transform">
                  <Checkbox
                    name="sendMail"
                    label="wyślij e-mail"
                    checked={values.sendMail}
                    accentColor={mainTheme.accentColorHover}
                    disabled={!activeEdit}
                  />
                </div>
              )}
              {permission?.[`usuwanie zadań`] && data?.id && (
                <ButtonWithPopup
                  width="small"
                  type="button"
                  content={PopupConfirm}
                  buttonText="Usuń"
                  secondary
                  zIndex={100}
                  data={{
                    elementName: data.taskKind,
                    actionHandler: () => {
                      deleteTask(data.id);
                      handlePopup(false);
                    },
                  }}
                />
              )}

              <div className="flex w-full justify-end gap-2">
                {!isDuplicated && (
                  <Button
                    disabled={isLoadingPost || isLoadingPut}
                    type="button"
                    onClick={() => {
                      setIsDuplicated(true);
                      // eslint-disable-next-line no-unused-expressions
                      dirty && handleSubmit();
                      setActiveEdit(true);

                      setFieldValue('series', '');
                      setFieldValue('startDate', todayWithNearestNextHour);
                      setFieldValue('endDate', '');
                      setFieldValue('delegator', currUser.id);
                      setFieldValue('remindDelegator', '');
                      setFieldValue('remindProducer', '');
                      // setFieldValue('delegatorDescription', '');
                      // setFieldValue('producerDescription', '');
                      setFieldValue('file', []);
                      setFieldValue('id', '');
                      setFieldValue('until', '');
                    }}
                  >
                    Duplikuj {(isLoadingPost || isLoadingPut) && <LoaderBtn />}
                  </Button>
                )}
                {!isDuplicated && permission?.[`edytowanie zadań`] && data.id && !activeEdit ? (
                  <EditButton type="button" onClick={() => setActiveEdit(!activeEdit)}>
                    Edytuj
                  </EditButton>
                ) : (
                  !isDuplicated &&
                  permission?.[`edytowanie zadań`] && (
                    <ButtonsWrapper>
                      {data.id && (
                        <Button
                          type="button"
                          cancel
                          onClick={() => {
                            setActiveEdit(false);
                            resetForm();
                          }}
                        >
                          Anuluj
                        </Button>
                      )}
                      <Button disabled={isLoadingPost || isLoadingPut} type="submit">
                        Zapisz {(isLoadingPost || isLoadingPut) && <LoaderBtn />}
                      </Button>
                    </ButtonsWrapper>
                  )
                )}

                {isDuplicated && (
                  <Button disabled={isLoadingPost || isLoadingPut} type="submit">
                    Zapisz {(isLoadingPost || isLoadingPut) && <LoaderBtn />}
                  </Button>
                )}
              </div>
            </PopupFooter>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default FormCalendarTask;
