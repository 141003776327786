import React, { useState, useRef } from 'react';
import ProfilIcon from 'components/atoms/ProfilIcon/ProfilIcon';
import cowSrc from 'assets/jpg/cow.jpg';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import styled from 'styled-components';
import ArrowDownSVG from 'components/atoms/SVG/ArrowDownSVG/ArrowDownSVG';
import ProfilInformationMenuDropdown from 'components/molecules/ProfilInformationMenuDropdown/ProfilInformationMenuDropdown';
import { useDropdown } from 'hooks/useDropdown';
import { useSelector } from 'react-redux';
import Loader from 'components/atoms/Loader/Loader';

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 46px 1fr 9px;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  column-gap: 12px;
  position: relative;
`;

const StyledIconWrapper = styled.button`
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-end: 4;
  display: flex;
  border: 0;
  background: transparent;
  padding: 0;
  svg {
    cursor: pointer;
  }
  transform: ${({ isactive }) => (!isactive ? 'rotate(0)' : 'rotate(180deg)')};
`;

const ProfilInformation = () => {
  const [isActive, setActive] = useState(false);
  const dropdownRef = useRef(null);

  const user = useSelector((state) => state.userReducer.data);

  useDropdown(dropdownRef, setActive);
  return (
    <StyledWrapper ref={dropdownRef}>
      {user.name ? (
        <>
          <ProfilIcon user={user} onClick={() => setActive(!isActive)} />
          <Paragraph fontWeight={600} marginTop="0.5rem" marginBottom="0.3rem">
            {user.name}
          </Paragraph>
          <Paragraph fontWeight={600} marginTop="0.3rem" marginBottom="0.5rem">
            {user.email}
          </Paragraph>
        </>
      ) : (
        <Loader />
      )}
      <StyledIconWrapper isactive={isActive} type="button" onClick={() => setActive(!isActive)}>
        <ArrowDownSVG />
      </StyledIconWrapper>
      <ProfilInformationMenuDropdown isActive={isActive} />
    </StyledWrapper>
  );
};

export default ProfilInformation;
