import React, { useState, useRef, useEffect } from 'react';
import NotificationsIcon from 'components/atoms/NotificationsIcon/NotificationsIcon';
import styled from 'styled-components';
import { useDropdown } from 'hooks/useDropdown';
import OutsideClickHandler from 'react-outside-click-handler';
import NotificationsDropdown from '../NotificationsDropdown/NotificationsDropdown';

const StyledWrapper = styled.div`
  margin-right: 26px;
  position: relative;
  cursor: pointer;
`;
const StyledCounter = styled.div`
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.accentColor};
  font-size: 11px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  right: -11px;
`;

const Notifications = ({ notifications, newNotifications, isActive, setActive }) => {
  const dropdownRef = useRef(null);

  useDropdown(dropdownRef, setActive);
  return (
    <StyledWrapper>
      <NotificationsIcon onClick={() => setActive(!isActive)} />
      {!!newNotifications && (
        <StyledCounter onClick={() => setActive(!isActive)}>
          {newNotifications > 99 ? '99+' : newNotifications}
        </StyledCounter>
      )}

      <OutsideClickHandler
        onOutsideClick={(e) => {
          if (
            e.target.firstChild?.id === 'modal' ||
            e.srcElement.offsetParent?.id === 'modal' ||
            e.srcElement.parentElement?.id === 'modal' ||
            e.toElement.parentElement.offsetParent?.id === 'modal' ||
            e.toElement.offsetParent?.offsetParent?.id === 'modal' ||
            e.toElement.offsetParent?.offsetParent?.offsetParent?.id === 'modal' ||
            e.toElement.offsetParent?.offsetParent?.offsetParent?.offsetParent?.id === 'modal' ||
            e.toElement.offsetParent?.offsetParent?.offsetParent?.offsetParent?.offsetParent?.id ===
              'modal' ||
            e.toElement.offsetParent?.offsetParent?.offsetParent?.offsetParent?.offsetParent
              ?.offsetParent?.id === 'modal' ||
            e.toElement.nodeName === 'svg'
          ) {
            setActive((prev) => prev);
          } else {
            setActive(false);
          }
        }}
      >
        <NotificationsDropdown isActive={isActive} data={notifications} setActive={setActive} />
      </OutsideClickHandler>
    </StyledWrapper>
  );
};

export default Notifications;
