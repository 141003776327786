import React from 'react';
import { Field } from 'formik';
import styled, { css } from 'styled-components';

const StyledSimilarInput = styled(Field)`
  font-size: 14px;
  padding: 4px 7px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  font-weight: 400;
  width: 100%;
  min-width: 44px;
  text-align: left;
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  outline: none;

  ${({ error }) =>
    error
      ? css`
          border: 1px solid ${({ theme }) => theme.warrningColor};

          &:focus {
            border: 1px solid ${({ theme }) => theme.warrningColor};
          }
        `
      : null}

  &:disabled {
    cursor: auto;
    color: hsl(0, 0%, 50%);
    background: hsl(0, 0%, 95%) !important;
  }
`;

const SimilarTextInput = ({ error, disabled, className, value, ...rest }) => (
  <StyledSimilarInput
    error={error}
    disabled={disabled}
    {...rest}
    className={className}
    value={value || ''}
    autoComplete="off"
  />
);

export default SimilarTextInput;
