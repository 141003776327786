import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormLabel from '../../atoms/FormLabel/FormLabel';

const StyledCustomSelectWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : 'calc(100% - 120px)')};
  display: flex;
  justify-content: flex-end;
  justify-content: ${(start) => (start ? 'flex-start' : 'flex-end')};
`;

const StyledFieldForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
`;

const FormField = ({ label, start, render, width, className }) => (
  <StyledFieldForm className={className}>
    <FormLabel width={width}>{label}</FormLabel>
    <StyledCustomSelectWrapper start={start} width={width}>
      {render}
    </StyledCustomSelectWrapper>
  </StyledFieldForm>
);

FormField.propTypes = {
  label: PropTypes.string,
  render: PropTypes.element.isRequired,
};

export default FormField;
