import * as Yup from 'yup';

const taskFormSchemaValidate = Yup.object().shape({
  type: Yup.string().required('Pole jest wymagane'),
  participants: Yup.array().min(1, 'at least 1').required('Pole jest wymagane'),
  producer: Yup.string().required('Pole jest wymagane'),
  delegator: Yup.string().required('Pole jest wymagane'),
  startDate: Yup.date().required('Pole jest wymagane'),
  endDate: Yup.date()
    .when('startDate', (startDate, schema) => {
      if (startDate) {
        const dayAfter = new Date(startDate.getTime() + 1 * 60 * 1000);

        return schema.min(dayAfter, 'End date has to be after than start date');
      }

      return schema;
    })
    .required('Pole jest wymagane'),
  investment: Yup.string().required('Pole jest wymagane'),
  status: Yup.string().required('Pole jest wymagane'),
});

export default taskFormSchemaValidate;
