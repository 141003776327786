import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from 'actions/user/user.actions';
import PropTypes from 'prop-types';
import PopupContentInvestmentsAdd from 'components/organisms/PopupContentInvestmentsAdd/PopupContentInvestmentsAdd';
import PopupCalendarTask from 'components/organisms/PopupCalendarTask/PopupCalendarTask';
import { usePutMarkReadNotificationMutation } from 'redux/services/notificationApi';
import { v4 as uuidv4 } from 'uuid';
import ComponentWithPopup from '../ComponentWithPopup/ComponentWithPopup';

const StyledDropdown = styled.div`
  position: absolute;
  top: 49px;
  left: -150px;
  width: 400px;
  padding: 0px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px -2px #4a4a4a;
  -moz-box-shadow: 0px 0px 10px -2px #4a4a4a;
  box-shadow: 0px 0px 10px -2px #4a4a4a;
  margin: 0;
  background: #fff;
  display: ${({ isactive }) => (isactive ? 'block' : 'none')};
  max-height: 600px;
  min-height: 100%;
  z-index: 40;
`;
const StyledLi = styled.li`
  font-size: 14px;
  margin-bottom: 10px;
  list-style: none;
  cursor: pointer;
  padding: 15px 5px 15px 10px;

  &:hover {
    color: ${({ theme }) => theme.accentColor};
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledHeader = styled.p`
  font-weight: 600;
  display: flex;
  justify-content: space-between;

  padding: 20px 15px;
  background: #f3f7ff;
  border-radius: 5px 5px 0 0;
`;

const ShowAllLink = styled(Link)`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.textColor};
  text-decoration: none;
  line-height: 14px;
  &:hover {
    color: ${({ theme }) => theme.accentColor};
  }
`;

const StyledDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 12px;
  }
`;

const StyledNotificationContent = styled.div`
  padding-left: 30px;
  position: relative;

  ${({ markAsRead }) =>
    !markAsRead
      ? css`
          &:before {
            content: '';
            width: 10px;
            height: 10px;
            display: block;
            position: absolute;
            left: 0;
            background: ${({ theme }) => theme.accentColor};
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
          }
        `
      : null}
`;

const handlePopupType = (type) => {
  switch (type) {
    case 'INVESTMENT':
      return PopupContentInvestmentsAdd;
    case 'TASK':
      return PopupCalendarTask;
    default:
      return null;
  }
};

const NotificationsDropdown = ({ isActive, data, setActive, ...rest }) => {
  const [putMarkReadNotification] = usePutMarkReadNotificationMutation();

  return (
    <StyledDropdown isactive={isActive} {...rest}>
      <StyledHeader>
        Powiadomienia{' '}
        <ShowAllLink onClick={() => setActive(false)} to="/powiadomienia">
          Pokaż wszystkie
        </ShowAllLink>
      </StyledHeader>
      {!data?.length && (
        <div className="flex justify-center p-3">
          <span>Brak powiadomień</span>
        </div>
      )}
      <ul className="max-h-[540px] overflow-auto">
        {data?.map((item) => (
          <ComponentWithPopup
            key={uuidv4()}
            content={handlePopupType(item.eventType)}
            data={{ objectId: item.eventId }}
            handler={() => putMarkReadNotification(item.id)}
            markAsRead={item.markAsRead}
          >
            <StyledLi
              className="grid grid-cols-4 items-center gap-x-3"
              // onClick={() => putMarkReadNotification(item.id)}
            >
              <StyledNotificationContent className="col-span-3" markAsRead={item.markAsRead}>
                {item.eventName}: {item.description}
              </StyledNotificationContent>
              <StyledDataWrapper>
                <span>{item.notificationDate.slice(0, 10)}</span>
                <span>{item.notificationDate.slice(11, 16)}</span>
              </StyledDataWrapper>
            </StyledLi>
          </ComponentWithPopup>
        ))}
      </ul>
    </StyledDropdown>
  );
};

NotificationsDropdown.propTypes = {
  isActive: PropTypes.bool,
};

NotificationsDropdown.defaultProps = {
  isActive: false,
};
const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(NotificationsDropdown);
