import React from 'react';
import SidebarMenu from 'components/molecules/SidebarMenu/SidebarMenu';
import styled from 'styled-components';
import Logo from 'components/atoms/Logo/Logo';

import SidebarMenuQuickAction from 'components/molecules/SidebarMenuQuickAction/SidebarMenuQuickAction';

const DashboardAside = styled.aside`
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items: center;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 37;
`;

const LeftSidebar = () => (
  <DashboardAside>
    <Logo />
    <SidebarMenuQuickAction />
    <SidebarMenu />
  </DashboardAside>
);

export default LeftSidebar;
