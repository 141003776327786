/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */
import SelectIconSVG from 'components/atoms/SVG/SelectIconSVG/SelectIconSVG';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';

const StyledSelect = styled(Select)`
  width: 100%;
  height: 26px;
  margin-right: ${(props) => (props.fullWidth ? '0' : '15px')};
  font-size: 14px;

  .select__control {
    border-color: #dddddd;
    min-height: 26px !important;
    cursor: pointer;
    &:hover {
      border-color: #dddddd;

      ${({ error }) =>
        error
          ? css`
              border-color: ${({ theme }) => theme.warrningColor};
            `
          : null}
    }

    &:focus {
      border-color: #dddddd;

      ${({ error }) =>
        error
          ? css`
              border-color: ${({ theme }) => theme.warrningColor};
            `
          : null}
    }

    ${({ error }) =>
      error
        ? css`
            border-color: ${({ theme }) => theme.warrningColor};
          `
        : null}
  }

  .select__value-container {
    align-items: center;
    height: 26px;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__indicators {
    margin-right: 6px;
  }

  .select__option--is-disabled {
    background-color: #dfdfdf;
  }

  .select__option {
    &:active {
      background-color: #dddddd;
    }
  }

  .select__menu {
    z-index: 11;
  }
`;

const DropdownIndicator = () => <SelectIconSVG />;

const colourStyles = {
  control: (styles, { base }) => ({
    ...styles,
    ...base,
    boxShadow: 'none',
    height: 20,
    minHeight: 20,
  }),
  input: (styles, { base }) => ({
    ...styles,
    paddingTop: 0,
    fontWeight: 400,
    color: 'black',
  }),

  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? '#dddddd' : 'inherit',
    '&:hover': { backgroundColor: isSelected ? '#dddddd' : '#fafafa' },
    color: '#000',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),

  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 4,
    paddingBottom: 4,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    paddingTop: 4,
    paddingBottom: 4,
  }),
};

const SelectComponent = ({
  data,
  fullWidth,
  error,
  disabled,
  value,
  onChange,
  isClearable,
  placeholder,
  clear,
  className,
  defaultValue,
}) => {
  function isValue(item) {
    return item.value === value || item.label === value || item.label === defaultValue;
  }

  const [optionSelected, setOptionSelected] = useState(
    (value && data?.find(isValue)) || (defaultValue && data?.find(isValue)) || null,
  );

  useEffect(() => {
    if (value) {
      setOptionSelected(data?.find(isValue));
    }
  }, [value, data]);

  useEffect(() => {
    if (clear) {
      setOptionSelected(null);
    }
  }, [clear]);

  function handleChange(event) {
    if (!event) {
      event = '';
    }

    onChange(event);
    setOptionSelected(event);
  }

  return (
    <StyledSelect
      components={{ DropdownIndicator }}
      options={data}
      isDisabled={disabled}
      onChange={handleChange}
      fullWidth={fullWidth}
      error={error}
      styles={colourStyles}
      classNamePrefix="select"
      noOptionsMessage={() => 'Brak danych'}
      placeholder={placeholder || 'Wybierz'}
      isClearable={isClearable}
      value={optionSelected}
      className={className}
      menuPlacement="auto"
      minMenuHeight={300}
      // isLoading={!data.length}
    />
  );
};
export default SelectComponent;
