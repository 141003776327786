import React from 'react';

const PhoneQuickSVG = () => (
  <svg width="18" height="18" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0947 16.7354C12.9359 16.735 12.7776 16.7176 12.6225 16.6835C9.66953 16.0726 6.94905 14.6404 4.77418 12.5515C2.64572 10.4581 1.16952 7.79277 0.524181 4.87794C0.448088 4.51466 0.466764 4.13791 0.578413 3.78394C0.690062 3.42998 0.890926 3.1107 1.16168 2.85682L3.05057 1.03877C3.14705 0.947529 3.26168 0.87766 3.38699 0.833721C3.5123 0.789782 3.64546 0.772761 3.77779 0.783768C3.915 0.798083 4.04741 0.842283 4.16571 0.91326C4.284 0.984238 4.38531 1.08027 4.46251 1.1946L6.82363 4.68432C6.91217 4.81973 6.95491 4.97998 6.94556 5.1415C6.93621 5.30301 6.87526 5.45726 6.77168 5.58155L5.5864 6.99821C6.05846 8.04298 6.72698 8.98725 7.55557 9.7796C8.37879 10.6007 9.35182 11.2563 10.422 11.711L11.8906 10.5446C12.0139 10.4471 12.164 10.3895 12.3209 10.3794C12.4778 10.3692 12.6341 10.4071 12.7689 10.4879L16.3342 12.7924C16.4565 12.8654 16.5608 12.9652 16.6391 13.0843C16.7174 13.2033 16.7677 13.3386 16.7864 13.4798C16.805 13.6211 16.7914 13.7648 16.7466 13.9001C16.7018 14.0353 16.627 14.1587 16.5278 14.261L14.6861 16.0838C14.4769 16.2919 14.2286 16.4567 13.9555 16.5685C13.6824 16.6804 13.3899 16.7371 13.0947 16.7354V16.7354ZM3.70224 1.71877L1.81335 3.53682C1.66103 3.67875 1.54828 3.85788 1.48619 4.05659C1.42409 4.2553 1.41478 4.46676 1.45918 4.67016C2.05924 7.40533 3.4386 9.90819 5.43057 11.8763C7.47727 13.8414 10.0374 15.1886 12.8161 15.7627C13.0265 15.8066 13.2444 15.7977 13.4504 15.7367C13.6565 15.6757 13.8442 15.5646 13.9967 15.4132L15.8383 13.5904L12.3958 11.3663L10.8186 12.6224C10.7582 12.6702 10.6871 12.7025 10.6114 12.7165C10.5356 12.7305 10.4577 12.7257 10.3842 12.7027C9.07457 12.2201 7.88776 11.4542 6.90863 10.4596C5.89588 9.51384 5.10975 8.35161 4.6089 7.0596C4.58771 6.98138 4.58701 6.89903 4.60686 6.82046C4.62671 6.7419 4.66644 6.66976 4.72224 6.61099L5.99724 5.08571L3.70224 1.71877Z"
      fill="#498FE2"
    />
  </svg>
);

export default PhoneQuickSVG;
