import React from 'react';

const TargetSVG = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_749_2185)">
      <path
        d="M8.49996 15.5834C4.60413 15.5834 1.41663 12.3959 1.41663 8.50008C1.41663 4.60425 4.60413 1.41675 8.49996 1.41675C12.3958 1.41675 15.5833 4.60425 15.5833 8.50008C15.5833 12.3959 12.3958 15.5834 8.49996 15.5834ZM8.49996 2.12508C4.95829 2.12508 2.12496 4.95842 2.12496 8.50008C2.12496 12.0417 4.95829 14.8751 8.49996 14.8751C12.0416 14.8751 14.875 12.0417 14.875 8.50008C14.875 4.95842 12.0416 2.12508 8.49996 2.12508Z"
        fill="#414141"
      />
      <path
        d="M8.50001 3.54167C8.28751 3.54167 8.14584 3.4 8.14584 3.1875V0.354167C8.14584 0.141667 8.28751 0 8.50001 0C8.71251 0 8.85418 0.141667 8.85418 0.354167V3.1875C8.85418 3.4 8.71251 3.54167 8.50001 3.54167Z"
        fill="#414141"
      />
      <path
        d="M8.50001 3.54167C8.28751 3.54167 8.14584 3.4 8.14584 3.1875V0.354167C8.14584 0.141667 8.28751 0 8.50001 0C8.71251 0 8.85418 0.141667 8.85418 0.354167V3.1875C8.85418 3.4 8.71251 3.54167 8.50001 3.54167Z"
        fill="#414141"
      />
      <path
        d="M16.6459 8.85409H13.8125C13.6 8.85409 13.4584 8.71242 13.4584 8.49992C13.4584 8.28742 13.6 8.14575 13.8125 8.14575H16.6459C16.8584 8.14575 17 8.28742 17 8.49992C17 8.71242 16.8584 8.85409 16.6459 8.85409Z"
        fill="#414141"
      />
      <path
        d="M8.50001 16.9999C8.28751 16.9999 8.14584 16.8582 8.14584 16.6457V13.8124C8.14584 13.5999 8.28751 13.4583 8.50001 13.4583C8.71251 13.4583 8.85418 13.5999 8.85418 13.8124V16.6457C8.85418 16.8582 8.71251 16.9999 8.50001 16.9999Z"
        fill="#414141"
      />
      <path
        d="M3.1875 8.85409H0.354167C0.141667 8.85409 0 8.71242 0 8.49992C0 8.28742 0.141667 8.14575 0.354167 8.14575H3.1875C3.4 8.14575 3.54167 8.28742 3.54167 8.49992C3.54167 8.71242 3.4 8.85409 3.1875 8.85409Z"
        fill="#414141"
      />
      <path
        d="M8.5 10.625C7.29583 10.625 6.375 9.70417 6.375 8.5C6.375 7.29583 7.29583 6.375 8.5 6.375C9.70417 6.375 10.625 7.29583 10.625 8.5C10.625 9.70417 9.70417 10.625 8.5 10.625ZM8.5 7.08333C7.72083 7.08333 7.08333 7.72083 7.08333 8.5C7.08333 9.27917 7.72083 9.91667 8.5 9.91667C9.27917 9.91667 9.91667 9.27917 9.91667 8.5C9.91667 7.72083 9.27917 7.08333 8.5 7.08333Z"
        fill="#414141"
      />
    </g>
    <defs>
      <clipPath id="clip0_749_2185">
        <rect width="17" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TargetSVG;
