import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledParagraph = styled.p`
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  font-size: ${({ fontSize }) => fontSize};
  text-align: ${({ textAlign }) => textAlign};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const Paragraph = ({
  children,
  marginBottom,
  marginTop,
  fontSize,
  textAlign,
  fontWeight,
  ...rest
}) => (
  <StyledParagraph
    {...rest}
    marginTop={marginTop}
    marginBottom={marginBottom}
    fontSize={fontSize}
    textAlign={textAlign}
    fontWeight={fontWeight}
  >
    {children}
  </StyledParagraph>
);

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  marginBottom: PropTypes.string,
  fontSize: PropTypes.string,
  textAlign: PropTypes.string,
  marginTop: PropTypes.string,
  fontWeight: PropTypes.number,
};

Paragraph.defaultProps = {
  marginBottom: '1.5rem',
  marginTop: '1.2rem',
  fontSize: '12px',
  textAlign: 'left',
  fontWeight: 400,
};

export default Paragraph;
