import React from 'react';
import PropTypes from 'prop-types';

const MenuListSubmenu = ({ children }) => <ul>{children}</ul>;

MenuListSubmenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MenuListSubmenu;
