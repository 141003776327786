import { lazy } from 'react';
import { logowanie, przypomnijHaslo, changePassword } from 'helpers/AllRouteName';

const LoginPage = lazy(() => import('pages/LoginPage/LoginPage'));
const RemindPasswordPage = lazy(() => import('pages/RemindPasswordPage/RemindPasswordPage'));
const ChangePasswordPage = lazy(() => import('pages/ChangePasswordPage/ChangePasswordPage'));

const PublicRouteList = [
  {
    path: logowanie.path,
    Component: LoginPage,
    exact: true,
  },
  {
    path: przypomnijHaslo.path,
    Component: RemindPasswordPage,
    exact: true,
  },

  {
    path: changePassword.path,
    Component: ChangePasswordPage,
    exact: true,
  },
];

export default PublicRouteList;
