import * as Yup from 'yup';

const userAddSchemaValidate = Yup.object().shape({
  name: Yup.string().required('Pole jest wymagane').max(400, 'Przekroczona liczba znaków'),
  confirmActive: Yup.boolean().required('Pole jest wymagane'),
  number: Yup.number().required('Pole jest wymagane'),
  startDate: Yup.date().required('Pole jest wymagane'),
  endDate: Yup.date()
    .required('Pole jest wymagane')
    .when('startDate', (startDate, schema) => {
      if (startDate) {
        const dayAfter = new Date(startDate.getTime() + 1 * 60 * 1000);

        return schema.min(dayAfter, 'End date has to be after than start date');
      }

      return schema;
    }),
});

export default userAddSchemaValidate;
