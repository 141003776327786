import React from 'react';
import styled from 'styled-components';
import { Calendar } from 'react-big-calendar';

const StyledCalendar = styled(Calendar)`
  &.year,
  &.month,
  &.work_week,
  &.day {
    height: auto !important;
  }

  .rbc-time-view,
  .rbc-month-view,
  .rbc-year-view {
    overflow: auto;
    height: 100vh;
    max-height: ${(props) => (props.notification ? '100%' : 'calc(100vh - 320px)')};
    flex: auto;
  }

  .rbc-calendar {
    height: 100% !important;
    display: block;
  }

  .rbc-row-content {
    z-index: inherit;
  }

  .rbc-day-bg + .rbc-day-bg.rbc-today {
    border: 3px solid #4f8fe2;
  }

  .in-month + .today {
    &::before {
      content: '';
      width: 30px;
      height: 30px;
      position: absolute;
      border: 3px solid #4f8fe2;
      border-radius: 50%;
      bottom: 7px;
    }
  }

  .rbc-month-view,
  .rbc-header + .rbc-header,
  .rbc-day-bg + .rbc-day-bg,
  .rbc-month-row + .rbc-month-row,
  .rbc-header,
  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border: 0;
    color: ${({ theme }) => theme.textColor};
  }

  .rbc-row-content-scrollable .rbc-row-content-scroll-container {
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .rbc-month-row {
    display: inline-table !important;
    flex: 1 0 130px !important;
  }

  .rbc-date-cell {
    margin: 4px 5px 0 0;
    padding: 5px;
    font-size: 12px;
    color: ${({ theme }) => theme.textColor};
  }

  .rbc-day-bg {
    margin: 5px;
    padding: 5px;
    background-color: #fff;
    border-radius: 9px;
  }
  .rbc-header > span,
  .rbc-button-link > span {
    border: 1px solid ${({ theme }) => theme.accentColor};
    display: inline-block;
    padding: 5px 20px;
    border-radius: 6px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .rbc-event,
  .rbc-event.rbc-selected,
  .rbc-day-slot .rbc-selected.rbc-background-event,
  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    padding: 0;
    min-height: 20px;
  }

  .rbc-event.rbc-selected {
    position: relative;
    filter: brightness(90%) saturate(90%);
    background: black;
    outline: 2px solid #4f8fe2 !important;
  }

  .rbc-row-segment {
    width: 50px;
    padding: 0 5px;
  }

  .rbc-day-slot .rbc-event-label {
    display: none;
  }

  .rbc-event {
    border-radius: 4px !important;
    margin-bottom: 4px !important;
    margin-top: 1px !important;
    margin-right: 50px !important;
  }
  .rbc-show-more {
    margin: 0px 0 8px 15px;
    padding: 5px 10px;
    border-radius: 25px;
    color: #fff;
    background: ${({ theme }) => theme.accentColor};
    font-size: 12px;
    &:hover {
      background: ${({ theme }) => theme.accentColorHover};
      color: #fff;
    }
  }

  .rbc-time-content > * + * > * {
    border: 0;
  }
  .rbc-timeslot-group {
    background-color: #fff;
    border-radius: 9px;
    margin: 4px;
    margin-right: 7px;
    min-height: 100px !important;
  }
  .rbc-day-slot .rbc-time-slot {
    border: 0;
  }

  .rbc-day-slot .rbc-event {
    margin: 0px 4px;
    margin-right: 0;
    border-radius: 0;
    min-height: 28px !important;
  }
  .rbc-day-slot .rbc-events-container {
    margin-right: 11px;
  }

  .rbc-day-slot .rbc-event-content > div {
    margin: 0;
    border-radius: 3px 9px 9px 3px;
    width: 100%;
    height: 100%;
  }

  .rbc-time-content > .rbc-time-gutter .rbc-timeslot-group {
    background-color: transparent;
    border: 0;
    font-weight: 700;
    font-size: 12px;
  }
  .rbc-time-content,
  .rbc-time-header-content,
  .rbc-time-header.rbc-overflowing,
  .rbc-time-view {
    border: 0;
  }
  .rbc-time-content {
    overflow: visible;
  }

  .rbc-time-column {
    background-color: #f3f7ff;
  }

  .rbc-time-view .rbc-allday-cell {
    display: none;
  }

  .rbc-current-time-indicator {
    display: none;
  }
`;

const CustomCalendar = ({ notification, ...rest }) => (
  <StyledCalendar notification={notification} {...rest} />
);

export default CustomCalendar;
