import { projectServiceTask } from '../../../endpoints/endpoints';
import { getAuthorizationHeader } from '../getAuthorizationHeader';

import { apiSlice } from '../apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['ProjectServiceTask'] });

export const projectServiceApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProjectServiceTask: builder.query({
      query: () => ({
        url: projectServiceTask,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['ProjectServiceTask'],
    }),

    deleteProjectServiceTask: builder.mutation({
      query: (id) => ({
        url: `${projectServiceTask}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['ProjectServiceTask'],
    }),

    postProjectServiceTask: builder.mutation({
      query: (name) => ({
        url: projectServiceTask,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['ProjectServiceTask'],
    }),
  }),
});

export const {
  useGetProjectServiceTaskQuery,
  useLazyGetProjectServiceTaskQuery,
  useDeleteProjectServiceTaskMutation,
  usePostProjectServiceTaskMutation,
} = projectServiceApi;
