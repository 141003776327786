import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGetRegionQuery, useGetUsersQuery } from 'redux/services/usersApi';
import ContentBox from 'components/molecules/ContentBox/ContentBox';
import { useGetContractorsQuery } from 'redux/services/contractorApi';
import { Link } from 'react-router-dom';
import { useGetClientStatusQuery } from 'redux/services/clientStatusApi';
import { useGetProvinceQuery } from 'redux/services/provinceApi';
import { useGetIndustryQuery } from 'redux/services/industryApi';
import { useGetGroupQuery } from 'redux/services/groupApi';
import { useGetShoppingGroupQuery } from 'redux/services/shoppingGroupApi';
import Loader from 'components/atoms/Loader/Loader';
import Table from '../Table/Table';
import FilterWrapperContractors from '../FilterWrapperContractors/FilterWrapperContractors';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
`;

const FormContractors = ({ data, handlePopup }) => {
  const { setContractor, type, selectedItems } = data;

  const [searchParams, setSearchParams] = useState({ type: [type] });
  const [sort, setSort] = useState('name,asc');
  const size = 10;

  const [page, setPage] = useState(0);

  const { data: contractorsData, isLoading: contractorsLoading } = useGetContractorsQuery({
    page,
    size,
    sort,
    search: searchParams,
  });

  const tableData = contractorsData?.content.map((contractor) => ({
    name: contractor.name,
    nationality: contractor.country,
    city: contractor.city,
    street: contractor.street,
    lastActivity: contractor.lastActivity,
  }));

  const headerData = {
    name: 'Nazwa',
    country: 'Narodowość',
    city: 'Miasto',
    street: 'Ulica',
    lastActivity: 'Ostatnia aktywność',
  };

  const [informations, setInformations] = useState(null);

  const { data: clientStatusData, isLoading: clientLoading } = useGetClientStatusQuery();
  const { data: provinceData, isLoading: provinceLoading } = useGetProvinceQuery();
  const { data: regionData, isLoading: regionLoading } = useGetRegionQuery();
  const { data: usersData, isLoading: usersLoading } = useGetUsersQuery({});
  const { data: industryData, isLoading: industryLoading } = useGetIndustryQuery();
  const { data: groupData, isLoading: groupLoading } = useGetGroupQuery();
  const { data: shoppingGroupData, isLoading: shoppingLoading } = useGetShoppingGroupQuery();

  useEffect(() => {
    setInformations({
      clientStatusData,
      provinceData,
      regionData,
      usersData,
      industryData,
      groupData,
      shoppingGroupData,
      type,
    });
  }, [
    clientStatusData,
    provinceData,
    regionData,
    usersData,
    groupData,
    industryData,
    shoppingGroupData,
    type,
  ]);

  const [contractor, setContractorData] = useState(null);

  useEffect(() => {
    if (contractor) {
      setContractor(contractor);
      handlePopup();
    }
  }, [contractor]);

  const isLoading = !!(
    clientLoading +
    provinceLoading +
    regionLoading +
    usersLoading +
    industryLoading +
    groupLoading +
    shoppingLoading +
    contractorsLoading
  );

  const selectedContractorIds = selectedItems?.map((item) => item.id);

  return (
    <>
      {!isLoading ? (
        <>
          <FilterWrapperContractors setSearchParams={setSearchParams} data={informations || []} />
          <ContentBox>
            <Table
              className="max-h-96"
              paddingTh="15px"
              paddingTd="17px"
              data={tableData}
              headerData={headerData}
              offBorderLeft
              offBorderRight
              headerAlignText="left"
              activeSort
              setSort={setSort}
              information={contractorsData?.content}
              Component={StyledLink}
              // link={{ to: 'contractor' }}
              totalElements={contractorsData?.totalElements}
              setPage={setPage}
              onClickHandler={setContractorData}
              selectedId={selectedContractorIds}
            />
          </ContentBox>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default FormContractors;
