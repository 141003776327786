import React from 'react';

const SettingsQuickSVG = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.90625 1.0625V3.1875L5.84375 3.71875L4.25 2.125L2.125 4.25L3.71875 5.84375L3.1875 6.90625H1.0625V10.0938H3.1875L3.71875 11.1562L2.125 12.75L4.25 14.875L5.84375 13.2812L6.90625 13.8125V15.9375H10.0938V13.8125L11.1562 13.2812L12.75 14.875L14.875 12.75L13.2812 11.1562L13.8125 10.0938H15.9375V6.90625H13.8125L13.2812 5.84375L14.875 4.25L12.75 2.125L11.1562 3.71875L10.0938 3.1875V1.0625H6.90625Z"
      stroke="#498FE2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 10.625C9.67361 10.625 10.625 9.67361 10.625 8.5C10.625 7.32639 9.67361 6.375 8.5 6.375C7.32639 6.375 6.375 7.32639 6.375 8.5C6.375 9.67361 7.32639 10.625 8.5 10.625Z"
      stroke="#498FE2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="fill-colorWhite"
    />
  </svg>
);

export default SettingsQuickSVG;
