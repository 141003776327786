import { contractor, sapData } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';
import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ['Contractor', 'ContractorContact', 'SapData', 'Note', 'Task', 'putContractor'],
});

export const contractorApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getContractors: builder.query({
      query: ({ page, size, sort, search }) => {
        const params = new URLSearchParams({
          page,
          size,
          sort,
          search: `${
            search?.type?.length ? `${search.type?.map((item) => `type=${item}`).join('|')}&` : ''
          }${search?.startDate ? `lastActivity>${search.startDate}T00:00&` : ''}${
            search?.endDate ? `lastActivity<${search.endDate}T23:59` : ''
          }${
            search?.province?.length
              ? `${search.province.map((item) => `province=${item}`).join('|')}&`
              : ''
          }${search?.name ? `name=%${search?.name}%&` : ''}${
            search?.customerLevel?.length
              ? `${search.customerLevel.map((item) => `level=${item}`).join('|')}&`
              : ''
          }${
            search?.group?.length ? `${search.group.map((item) => `group=${item}`).join('|')}&` : ''
          }${
            search?.industry?.length
              ? `${search.industry.map((item) => `industry=${item}`).join('|')}&`
              : ''
          }${
            search?.promising?.length
              ? `${search.promising.map((item) => `promising=${item}`).join('|')}&`
              : ''
          }${
            search?.status?.length
              ? `${search.status.map((item) => `status=${item}`).join('|')}&`
              : ''
          }${
            search?.trader?.length
              ? `${search.trader.map((item) => `trader=${item}`).join('|')}`
              : ''
          }`,
        });

        const keysForDel = [];
        params.forEach((value, key) => {
          if (value === 'undefined' || value === '') {
            keysForDel.push(key);
          }
        });

        keysForDel.forEach((key) => {
          params.delete(key);
        });

        return {
          url: `${contractor}?${!!params.getAll('search') && params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['Contractor'],
    }),

    getContractor: builder.query({
      query: (id) => ({
        url: `${contractor}/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['Contractor'],
    }),

    getContractorWithType: builder.query({
      query: (type) => ({
        url: `${contractor}/type/${type}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['Contractor'],
    }),

    getContractorInvestment: builder.query({
      query: (id) => ({
        url: `${contractor}/investment/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['Contractor', 'Note', 'Task'],
    }),

    getInvestmentContractor: builder.query({
      query: (id) => ({
        url: `${contractor}/list/investment/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
    }),

    postContractor: builder.mutation({
      query: (data) => ({
        url: contractor,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['Contractor', 'putContractor'],
    }),

    putContractor: builder.mutation({
      query: (data) => ({
        url: contractor,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['Contractor'],
    }),

    getSapData: builder.query({
      query: (id) => ({
        url: `${sapData}/contractor/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['SapData'],
    }),

    putSapData: builder.mutation({
      query: (data) => ({
        url: sapData,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['Contractor', 'SapData'],
    }),

    getSearchContractor: builder.query({
      query: (name) => ({
        url: `${contractor}/list/name?name=${name || ''}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
    }),

    getContractorsByInvestmentAndType: builder.query({
      query: ({ type, investmentId }) => {
        const params = new URLSearchParams({
          ...(type && { type }),
        });

        return {
          url: `${contractor}/type/list/${investmentId}?${params.toString()}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['putContractor'],
    }),

    deleteContractor: builder.mutation({
      query: (id) => ({
        url: `${contractor}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Contractor'],
    }),
  }),
});

export const {
  useGetContractorsQuery,
  useGetContractorQuery,
  useGetContractorWithTypeQuery,
  useGetContractorInvestmentQuery,
  useGetSapDataQuery,
  useGetInvestmentContractorQuery,
  useLazyGetSearchContractorQuery,
  useGetSearchContractorQuery,
  useLazyGetInvestmentContractorQuery,
  usePutSapDataMutation,
  usePutContractorMutation,
  usePostContractorMutation,
  useGetContractorsByInvestmentAndTypeQuery,
  useDeleteContractorMutation,
} = contractorApi;
