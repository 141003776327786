import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'theme/GlobalStyles';
import { theme } from 'theme/mainTheme';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PublicRoute from 'router/PublicRoute';
import PrivateRoute from 'router/PrivateRoute';
import PublicRouteList from 'router/PublicRouteList';
import DashboardMainTemplate from 'components/templates/DashboardMainTemplate/DashboardMainTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetLoggedUserQuery } from 'redux/services/usersApi';
import { setUserData } from 'redux/reducers/userReducer';
import { getCookie } from 'helpers/getCookie';
import Loader from 'components/atoms/Loader/Loader';
import { logout } from 'actions/user/user.actions';
import { setPermissionData } from 'redux/reducers/permissionsReducer';

function Root() {
  const dispatch = useDispatch();

  const [getLoggedUser, { isLoading, isError }] = useLazyGetLoggedUserQuery();

  useSelector((state) => state);

  const token = getCookie('token');
  useEffect(async () => {
    if (token) {
      const { data } = await getLoggedUser();
      dispatch(setUserData(data));

      dispatch(
        setPermissionData(
          data.customAccessGroup?.permissionGroups.reduce(
            (accumulator, currentValue) => ({
              ...accumulator,
              [currentValue.groupName]: Object.fromEntries(
                currentValue.permissions?.map((item) => [item.name, item.active]),
              ),
            }),
            '',
          ),
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (isError) {
      dispatch(logout());
    }
  }, [isError]);

  return (
    <div className="App">
      <GlobalStyle />

      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {PublicRouteList.map(({ path, Component, exact }) => (
              <Route
                key={path}
                path={path}
                element={
                  <PublicRoute
                    isAuthenticated={isError ? !isError : !!token}
                    render={<Component />}
                  />
                }
                exact={exact}
              />
            ))}

            <Route
              path="/*"
              element={
                <PrivateRoute
                  isAuthenticated={!!token}
                  render={!isLoading ? <DashboardMainTemplate /> : <Loader />}
                />
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default Root;
