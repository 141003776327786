import React from 'react';

const DashboardSVG = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_749_2176)">
      <path
        d="M6.72917 5.66667H1.0625C0.476708 5.66667 0 5.18996 0 4.60417V1.0625C0 0.476708 0.476708 0 1.0625 0H6.72917C7.31496 0 7.79167 0.476708 7.79167 1.0625V4.60417C7.79167 5.18996 7.31496 5.66667 6.72917 5.66667ZM1.0625 0.708333C0.867708 0.708333 0.708333 0.867 0.708333 1.0625V4.60417C0.708333 4.79967 0.867708 4.95833 1.0625 4.95833H6.72917C6.92396 4.95833 7.08333 4.79967 7.08333 4.60417V1.0625C7.08333 0.867 6.92396 0.708333 6.72917 0.708333H1.0625Z"
        fill="#414141"
      />
      <path
        d="M6.72917 16.9999H1.0625C0.476708 16.9999 0 16.5232 0 15.9374V8.14575C0 7.55996 0.476708 7.08325 1.0625 7.08325H6.72917C7.31496 7.08325 7.79167 7.55996 7.79167 8.14575V15.9374C7.79167 16.5232 7.31496 16.9999 6.72917 16.9999ZM1.0625 7.79159C0.867708 7.79159 0.708333 7.95025 0.708333 8.14575V15.9374C0.708333 16.1329 0.867708 16.2916 1.0625 16.2916H6.72917C6.92396 16.2916 7.08333 16.1329 7.08333 15.9374V8.14575C7.08333 7.95025 6.92396 7.79159 6.72917 7.79159H1.0625Z"
        fill="#414141"
      />
      <path
        d="M15.9375 16.9999H10.2708C9.68502 16.9999 9.20831 16.5232 9.20831 15.9374V12.3958C9.20831 11.81 9.68502 11.3333 10.2708 11.3333H15.9375C16.5233 11.3333 17 11.81 17 12.3958V15.9374C17 16.5232 16.5233 16.9999 15.9375 16.9999ZM10.2708 12.0416C10.076 12.0416 9.91665 12.2003 9.91665 12.3958V15.9374C9.91665 16.1329 10.076 16.2916 10.2708 16.2916H15.9375C16.1323 16.2916 16.2916 16.1329 16.2916 15.9374V12.3958C16.2916 12.2003 16.1323 12.0416 15.9375 12.0416H10.2708Z"
        fill="#414141"
      />
      <path
        d="M15.9375 9.91667H10.2708C9.68502 9.91667 9.20831 9.43996 9.20831 8.85417V1.0625C9.20831 0.476708 9.68502 0 10.2708 0H15.9375C16.5233 0 17 0.476708 17 1.0625V8.85417C17 9.43996 16.5233 9.91667 15.9375 9.91667ZM10.2708 0.708333C10.076 0.708333 9.91665 0.867 9.91665 1.0625V8.85417C9.91665 9.04967 10.076 9.20833 10.2708 9.20833H15.9375C16.1323 9.20833 16.2916 9.04967 16.2916 8.85417V1.0625C16.2916 0.867 16.1323 0.708333 15.9375 0.708333H10.2708Z"
        fill="#414141"
      />
    </g>
    <defs>
      <clipPath id="clip0_749_2176">
        <rect width="17" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DashboardSVG;
