import React from 'react';

const SearchSVG = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_253_1363)">
<path d="M7.04606 0C3.16097 0 0 3.16097 0 7.04606C0 10.9314 3.16097 14.0921 7.04606 14.0921C10.9314 14.0921 14.0921 10.9314 14.0921 7.04606C14.0921 3.16097 10.9314 0 7.04606 0ZM7.04606 12.7913C3.87816 12.7913 1.30081 10.214 1.30081 7.04609C1.30081 3.87819 3.87816 1.30081 7.04606 1.30081C10.214 1.30081 12.7913 3.87816 12.7913 7.04606C12.7913 10.214 10.214 12.7913 7.04606 12.7913Z" fill="#414141"/>
<path d="M15.809 14.8898L12.08 11.1608C11.8259 10.9067 11.4144 10.9067 11.1603 11.1608C10.9062 11.4147 10.9062 11.8266 11.1603 12.0805L14.8893 15.8095C15.0163 15.9365 15.1826 16 15.3491 16C15.5154 16 15.6819 15.9365 15.809 15.8095C16.0631 15.5556 16.0631 15.1437 15.809 14.8898Z" fill="#414141"/>
</g>
<defs>
<clipPath id="clip0_253_1363">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>


);

export default SearchSVG;
