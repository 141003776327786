import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import MenuItemSubmenu from '../../atoms/MenuListSubmenu/MenuListSubmenu';
import MenuItem from '../MenuItem/MenuItem';

const StyledLi = styled.li`
  display: block;
  color: ${({ theme }) => theme.textColor};
  margin-bottom: 16px;
  position: relative;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  align-items: center;
  padding: 8px 24px;
  position: relative;

  &:hover {
    background: #f3f7ff;
  }

  svg {
    display: block;
    width: 19px;
  }

  &.active {
    background: #f3f7ff;
    color: ${({ theme }) => theme.accentColor};

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 7px;
      background: ${({ theme }) => theme.accentColor};
    }

    svg path {
      fill: ${({ theme }) => theme.accentColor};
    }
  }
`;

const StyledDisabledLink = styled.button`
  width: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  align-items: center;
  padding: 8px 24px;
  position: relative;
  background: #fff;
  border: 0;
  cursor: not-allowed;

  span {
    color: ${({ theme }) => theme.textColorLight};
  }

  svg {
    display: block;
    width: 19px;

    path {
      fill: ${({ theme }) => theme.textColorLight};
    }
  }

  &:hover:before {
    content: 'Brak dostępu';
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border: 1px solid ${({ theme }) => theme.textColorLight};
    color: ${({ theme }) => theme.textColorLight};
    padding: 3px 10px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
  }
`;

const StyledText = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

const MenuIconItem = ({ icon, children, to, submenu, disabled, ...rest }) => (
  <StyledLi {...rest}>
    {disabled ? (
      <StyledDisabledLink className="flex flex-col px-1 lg:flex-row lg:px-6">
        {icon} <StyledText className="text-xs lg:pl-3 lg:text-lg">{children}</StyledText>
      </StyledDisabledLink>
    ) : (
      <StyledLink className="flex flex-col px-1 lg:flex-row lg:px-6" to={to}>
        {icon} <StyledText className="text-xs lg:pl-3 lg:text-lg">{children}</StyledText>
      </StyledLink>
    )}

    {submenu?.[0]?.permission && submenu && (
      <MenuItemSubmenu>
        {submenu.map((item) => (
          <MenuItem key={item.name} to={item.to} submenu={item.submenu}>
            {item.name}
          </MenuItem>
        ))}
      </MenuItemSubmenu>
    )}
  </StyledLi>
);

MenuIconItem.propTypes = {
  icon: PropTypes.element.isRequired,
  children: PropTypes.string.isRequired,
  submenu: PropTypes.arrayOf(PropTypes.object),
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

MenuIconItem.defaultProps = {
  submenu: [],
  disabled: false,
};

export default MenuIconItem;
