import * as Yup from 'yup';

const AddNoteSchemaValidate = Yup.object().shape({
  title: Yup.string().required('Pole jest wymagane'),
  startDate: Yup.date().required('Pole jest wymagane'),
  endDate: Yup.date().when('startDate', (startDate, schema) => {
    if (startDate) {
      const dayAfter = new Date(startDate.getTime() + 1 * 60 * 1000);

      return schema.min(dayAfter, 'End date has to be after than start date');
    }

    return schema;
  }),
  employee: Yup.string().required('Pole jest wymagane'),
  // readRights: Yup.string().required('Pole jest wymagane'),
  // writeRights: Yup.string().required('Pole jest wymagane'),
  adding: Yup.string().required('Pole jest wymagane'),
  type: Yup.string().required('Pole jest wymagane'),
  accepting: Yup.string().required('Pole jest wymagane'),
});

export default AddNoteSchemaValidate;
