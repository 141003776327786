import { contact } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';
import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ['Contact'],
});

export const ContactApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query({
      query: ({ page, size, sort, contractorId }) => {
        const params = new URLSearchParams({
          ...(page && { page }),
          ...(size && { size }),
          ...(sort && { sort }),
        });

        return {
          url: `${contact}/contractor/${contractorId}?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['Contact'],
    }),

    getContact: builder.query({
      query: (id) => ({
        url: `${contact}/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['Contact'],
    }),

    postContact: builder.mutation({
      query: ({ contractorId, data }) => ({
        url: `${contact}/contractor/${contractorId}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['Contact'],
    }),

    putContact: builder.mutation({
      query: ({ data, contactId }) => ({
        url: `${contact}/${contactId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['Contact'],
    }),

    getContactContact: builder.query({
      query: (id) => ({
        url: `${contact}/contact-data/Contact/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['ContactContact'],
    }),

    deleteContact: builder.mutation({
      query: (id) => ({
        url: `${contact}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Contact'],
    }),
  }),
});

export const {
  useGetContactsQuery,
  useLazyGetContactsQuery,
  useGetContactQuery,
  useLazyGetContactContactQuery,
  usePutContactMutation,
  usePostContactMutation,
  useDeleteContactMutation,
} = ContactApi;
