import { calendar } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';

import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ['calendar', 'Task', 'Absence', 'investment'],
});

export const calendarApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCalendar: builder.query({
      query: (searchQuery) => {
        const params = new URLSearchParams({
          search: `${searchQuery?.startDate ? `startDate>${searchQuery.startDate}T00:00&` : ''}${
            searchQuery?.endDate ? `endDate<${searchQuery.endDate}T23:59&` : ''
          }${
            // eslint-disable-next-line no-nested-ternary
            searchQuery?.name?.length
              ? searchQuery?.calendarType === 'contractor'
                ? `contractorName=${searchQuery?.name}`
                : `investmentName=${searchQuery?.name}`
              : ''
          }${
            searchQuery?.trader?.length
              ? `${searchQuery.trader.map((item) => `trader=${item}`).join('|')}&`
              : ''
          }${
            searchQuery?.tasks?.length
              ? `${searchQuery.tasks.map((item) => `type=${item}`).join('|')}&`
              : ''
          }${
            searchQuery?.employee?.length
              ? `${searchQuery.employee.map((item) => `personName=${item}`).join('|')}&`
              : ''
          }${
            searchQuery?.absences?.length
              ? `${searchQuery.absences.map((item) => `type=${item}`).join('|')}`
              : ''
          }`,
        });

        const keysForDel = [];
        params.forEach((value, key) => {
          if (value === 'undefined' || value === '') {
            keysForDel.push(key);
          }
        });

        keysForDel.forEach((key) => {
          params.delete(key);
        });

        return {
          url: `${calendar}?${!!params.getAll('search') && params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['User', 'Absence', 'investment', 'Task'],
    }),
  }),
});

export const { useGetCalendarQuery } = calendarApi;
