import React from 'react';

const PlusSVG = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<line y1="8" x2="16" y2="8" stroke="#498FE2" strokeWidth="3"/>
<path d="M8 16L8 0" stroke="#498FE2" strokeWidth="3"/>
</svg>


);

export default PlusSVG;
