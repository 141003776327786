import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
  border: 0;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  cursor: pointer;
`;

const StyledSpan = styled.span`
  font-size: 10px;
  display: block;
  margin-top: 2px;
`;

const IconTextButton = ({ icon, text, ...rest }) => (
  <StyledButton type="button" {...rest}>
    {icon}
    <StyledSpan>{text}</StyledSpan>
  </StyledButton>
);

IconTextButton.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string,
};

IconTextButton.defaultProps = {
  text: '',
};

export default IconTextButton;
