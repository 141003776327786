import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import Button from 'components/atoms/Button/Button';
import FormColumn from 'components/atoms/FormColumn/FormColumn';
import CustomTextarea from 'components/molecules/CustomTextarea/CustomTextarea';
import SimilarTextInput from 'components/molecules/SimilarTextInput/SimilarTextInput';
import FormField from 'components/molecules/FormField/FormField';
import FormDoubleField from 'components/molecules/FormDoubleField/FormDoubleField';
import PopupFooter from 'components/molecules/PopupFooter/PopupFooter';
import { enums } from 'staticData/enums';
import { useSelector } from 'react-redux';
import {
  useDeleteInvestmentMutation,
  useGetNextNumberQuery,
  usePostInvestmentMutation,
  usePutContractorToInvestmentMutation,
  usePutInvestmentMutation,
} from 'redux/services/investmentApi';
import { toast } from 'react-toastify';
import { useGetRegionQuery, useGetUserPositionQuery } from 'redux/services/usersApi';
import ButtonWithPopup from 'components/molecules/ButtonWithPopup/ButtonWithPopup';
import WorldSVG from 'components/atoms/SVG/WorldSVG/WorldSVG';
import PlusSVG from 'components/atoms/SVG/PlusSVG/PlusSVG';
import SelectComponent from 'components/molecules/SelectComponent/SelectComponent';
import { handleNewField } from 'helpers/formsHelpers';
import MultiSelectComponent from 'components/molecules/MultiSelectComponent/MultiSelectComponent';
import Checkbox from 'components/molecules/Checkbox/Checkbox';
import { theme as mainTheme } from 'theme/mainTheme';
import LoaderBtn from 'components/atoms/LoaderBtn/LoaderBtn';
import DataPickerComponent from 'components/atoms/DataPicker/DataPicker';
import FormAddInvestmentSchemaValidate from './FormAddInvestmentSchemaValidate';
import PopupConfirm from '../PopupConfirm/PopupConfirm';
import PopupContentContractorAdd from '../PopupContentContractorAdd/PopupContentContractorAdd';
import PopupContractors from '../PopupContractors/PopupContractors';
import Popup from '../Popup/Popup';

const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  flex-wrap: wrap;
  cursor: auto;
`;

const StyledIconButton = styled(ButtonWithPopup)`
  > button {
    border: 0;
    border-radius: 0;
    background: transparent;
    padding: 0;

    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
    filter: ${({ disabled }) => (disabled ? 'grayscale(1)' : 'none')};

    > svg {
      width: 19px;
    }

    &:hover {
      background: none;
    }
  }
  cursor: pointer;
`;

const EditButton = styled.button`
  padding: 6px 16px;
  border-radius: 13px;
  border: 0;
  font-size: 13px;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  background: ${({ theme }) => theme.accentColor};
  color: #ffffff;
  font-weight: 500;
  &:hover {
    background: ${({ theme }) => theme.accentColorHover};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const FormAddInInvestment = ({ data, handlePopup, permission }) => {
  const [activeEdit, setActiveEdit] = useState(!data?.id);
  const user = useSelector((state) => state.userReducer.data);
  const { data: regionData } = useGetRegionQuery();
  const [postInvestment, { isSuccess: postSuccess, data: postData, isLoading: isLoadingPost }] =
    usePostInvestmentMutation();
  const [putInvestment, { isSuccess: putSuccess, isLoading: isLoadingPut }] =
    usePutInvestmentMutation();
  const [deleteInvestment] = useDeleteInvestmentMutation();
  const [putContractorToInvestment] = usePutContractorToInvestmentMutation();

  const { data: investmentCoordinator } = useGetUserPositionQuery(['INVESTMENT_COORDINATOR']);
  const { data: projectCoordinator } = useGetUserPositionQuery(['INVESTMENT_COORDINATOR']);

  const { data: nextNumber } = useGetNextNumberQuery();

  const [confirmActive, setConfirmActive] = useState(false);

  const handleSubmitInvestment = async (requestBody) => {
    const { error } = (await data.id) ? putInvestment(requestBody) : postInvestment(requestBody);

    if (error) {
      toast.error(error.data);
    }
  };

  return (
    <Formik
      initialValues={{
        name: data.name || '',
        number: data.number || nextNumber,
        startDate: data.calendar?.startDate || '',
        endDate: data.calendar?.endDate || '',
        quarter: data.quarter || '',
        template: data.template || '',
        coordinator: data.coordinator?.id || user.name,
        remindCoordinator: data.remindCoordinator,
        designCoordinator: data.designCoordinator?.id,
        remindDesignCoordinator: data.remindDesignCoordinator,
        status: data.id ? data.calendar.status : 'INACTIVE',
        isBlocked: data.isBlocked || '',
        serviceStatus: data.serviceStatus || '',
        description: data.description || '',
        city: data.city || '',
        department: data.department || '',
        region: data.regions?.map((item) => item.id) || [],
        systemType: data.systemType || '',
        systemSize: data.systemSize || '',
        objectType: data.objectType || '',
        objectKind: data.objectKind || '',
        projectType: data.projectType || '',
        designer: '',
        distributor: '',
        executor: '',
        sendMailCoordinator: data?.sendMailCoordinator || false,
        sendMailDesignCoordinator: data?.sendMailDesignCoordinator || false,
        confirmActive: false,
      }}
      onSubmit={async (values) => {
        const requestBody = {
          ...data,
          ...(data?.id && {
            id: data.id,
          }),
          name: values.name,
          number: values.number,
          calendar: {
            startDate: values.startDate,
            endDate: values.endDate,
            status: values.status,
          },
          ...(values.quarter && { quarter: values.quarter }),
          ...(values.template && { template: values.template }),
          ...(values.coordinator && {
            coordinator: {
              id: data.id ? values.coordinator : user.id,
            },
          }),
          ...(values.remindCoordinator && { remindCoordinator: values.remindCoordinator }),
          ...(values.designCoordinator && {
            designCoordinator: {
              id: values.designCoordinator,
            },
          }),
          ...(values.remindDesignCoordinator && {
            remindDesignCoordinator: values.remindDesignCoordinator,
          }),
          ...(values.isBlocked && { isBlocked: values.isBlocked }),
          ...(values.serviceStatus && { serviceStatus: values.serviceStatus }),
          ...(values.description && { description: values.description }),
          ...(values.city && { city: values.city }),
          ...(values.department && { department: values.department }),
          ...(values.region.length && {
            regions: values.region?.map((region) => ({
              id: region,
            })),
          }),
          ...(values.systemType && { systemType: values.systemType }),
          ...(values.systemSize && { systemSize: values.systemSize }),
          ...(values.objectType && { objectType: values.objectType }),
          ...(values.objectKind && { objectKind: values.objectKind }),
          ...(values.projectType && { projectType: values.projectType }),
          investmentType: data.subInvestment ? 'SUB_INVESTMENT' : 'MAIN_INVESTMENT',
          ...(data.mainInvestmentId && {
            mainInvestment: {
              id: data.mainInvestmentId,
            },
          }),
          sendMailCoordinator: values.sendMailCoordinator,
          sendMailDesignCoordinator: values.sendMailDesignCoordinator,
        };

        if (values?.confirmActive) {
          handleSubmitInvestment(requestBody);
        }

        if (!values.designer.value && !values.distributor.value && !values.executor.value) {
          setConfirmActive(true);
        } else {
          handleSubmitInvestment(requestBody);
        }
      }}
      validationSchema={FormAddInvestmentSchemaValidate}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors, setFieldValue, handleReset, submitForm }) => {
        useEffect(() => {
          if (postSuccess) {
            toast.success('Inwestycja dodana pomyślnie');

            if (values.designer.value) {
              putContractorToInvestment({
                investmentId: postData.id,
                contractorId: values.designer.value,
              });
            }
            if (values.distributor.value)
              putContractorToInvestment({
                investmentId: postData.id,
                contractorId: values.distributor.value,
              });

            if (values.executor.value) {
              putContractorToInvestment({
                investmentId: postData.id,
                contractorId: values.executor.value,
              });
            }

            handlePopup(false);
          } else if (putSuccess) {
            toast.success('Inwestycja edytowana pomyślnie');
            handlePopup(false);
          }
        }, [postSuccess, putSuccess]);

        return (
          <StyledForm>
            <FormColumn col={6} padding="0 15px 0 0">
              <Popup
                isActive={confirmActive}
                Content={PopupConfirm}
                handlePopup={() => setConfirmActive(false)}
                width="small"
                data={{
                  message: 'Czy na pewno chcesz zapisać inwestycję bez kontrahenta',
                  actionHandler: () => {
                    setFieldValue('confirmActive', true);
                    submitForm();
                  },
                }}
              />
              <FormField
                label="*Nazwa"
                render={
                  <div className="relative w-full">
                    <SimilarTextInput
                      name="name"
                      value={values.name}
                      error={errors.name}
                      disabled={!activeEdit}
                    />
                    {errors.name === 'Przekroczona liczba znaków' && (
                      <span className="absolute left-0 top-[22px] text-sm text-colorwarrning">
                        {errors.name}
                      </span>
                    )}
                  </div>
                }
              />
              <FormField
                label="*Numer"
                render={
                  <SimilarTextInput
                    name="number"
                    value={values.number}
                    error={errors.number}
                    disabled
                  />
                }
              />

              <FormDoubleField
                label="*Data"
                render={
                  <div className="flex w-full flex-col gap-2 lg:flex-row">
                    <DataPickerComponent
                      value={values.startDate}
                      placeholderText="Data od"
                      name="startDate"
                      minDate={new Date(values.startDate)}
                      clear={!values.startDate}
                      error={errors.startDate}
                      fullWidth="100"
                      marginRight="0"
                      fw400
                      disabled={!activeEdit}
                    />

                    <DataPickerComponent
                      value={values.endDate}
                      placeholderText="Data do"
                      name="endDate"
                      minDate={new Date(new Date(values.startDate).getTime() + 24 * 60 * 60 * 1000)}
                      clear={!values.startDate}
                      error={errors.endDate}
                      fullWidth="100"
                      marginRight="0"
                      fw400
                      disabled={!values.startDate || !activeEdit}
                    />
                  </div>
                }
              />

              <FormField
                label="Kwartał"
                render={
                  <SelectComponent
                    value={values.quarter}
                    data={handleNewField(enums.quarter)}
                    fullWidth
                    error={errors.quarter}
                    onChange={(e) => setFieldValue('quarter', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />
              <FormField
                label="Wzorzec"
                render={
                  <SelectComponent
                    value={values.template}
                    data={handleNewField(enums.template)}
                    fullWidth
                    error={errors.template}
                    onChange={(e) => setFieldValue('template', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />

              <FormField
                label="Koordynator"
                render={
                  <SelectComponent
                    value={values.coordinator}
                    data={handleNewField(investmentCoordinator)}
                    fullWidth
                    error={errors.coordinator}
                    onChange={(e) => setFieldValue('coordinator', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />
              <FormField
                label="Status"
                render={
                  <SelectComponent
                    value={values.status || 'INACTIVE'}
                    data={handleNewField(enums.investmentStatus)}
                    fullWidth
                    error={errors.status}
                    onChange={(e) => setFieldValue('status', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />
              {/* <FormField
                label="Blokada"
                render={
                  <SelectComponent
                    value={values.isBlocked}
                    data={handleNewField(enums.blockade)}
                    fullWidth
                    error={errors.isBlocked}
                    onChange={(e) => setFieldValue('isBlocked', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              /> */}
              <FormField
                label="Status serwisowy"
                render={
                  <SelectComponent
                    value={values.serviceStatus}
                    data={handleNewField(enums.serviceStatus)}
                    fullWidth
                    error={errors.serviceStatus}
                    onChange={(e) => setFieldValue('serviceStatus', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />

              <FormField
                label="Opis"
                render={
                  <CustomTextarea
                    name="description"
                    value={values.description}
                    error={errors.description}
                    disabled={!activeEdit}
                  />
                }
              />
            </FormColumn>

            <FormColumn col={6} padding="0 0 0 15px">
              {!data.id && (
                <>
                  <FormField
                    label="Biuro projektowe"
                    render={
                      <div className="flex w-full items-center gap-2">
                        <SimilarTextInput value={values.designer.label} disabled />

                        <StyledIconButton
                          type="button"
                          content={PopupContractors}
                          data={{
                            type: 'DESIGNER',
                            setContractor: (e) => setFieldValue('designer', e),
                          }}
                          Icon={WorldSVG}
                          overflowAuto
                          name="designer"
                        />

                        <StyledIconButton
                          type="button"
                          content={PopupContentContractorAdd}
                          data={{
                            type: 'DESIGNER',
                            setContractors: (e) => setFieldValue('designer', e),
                          }}
                          Icon={PlusSVG}
                          overflowAuto
                          name="productOffice"
                        />
                      </div>
                    }
                  />

                  <FormField
                    label="Dystrybutor"
                    render={
                      <div className="flex w-full items-center gap-2">
                        <SimilarTextInput value={values.distributor.label} disabled />

                        <StyledIconButton
                          type="button"
                          content={PopupContractors}
                          data={{
                            type: 'DISTRIBUTOR',
                            setContractor: (e) => setFieldValue('distributor', e),
                          }}
                          Icon={WorldSVG}
                          overflowAuto
                          name="distributor"
                        />
                        <StyledIconButton
                          type="button"
                          content={PopupContentContractorAdd}
                          data={{
                            type: 'DISTRIBUTOR',
                            setContractors: (e) => setFieldValue('distributor', e),
                          }}
                          Icon={PlusSVG}
                          overflowAuto
                          name="productOffice"
                        />
                      </div>
                    }
                  />

                  <FormField
                    className="pb-6"
                    label="Wykonawca generalny"
                    render={
                      <div className="flex w-full items-center gap-2 ">
                        <SimilarTextInput value={values.executor.label} disabled />

                        <StyledIconButton
                          type="button"
                          content={PopupContractors}
                          data={{
                            type: 'EXECUTOR',
                            setContractor: (e) => setFieldValue('executor', e),
                          }}
                          Icon={WorldSVG}
                          overflowAuto
                          name="executor"
                        />

                        <StyledIconButton
                          type="button"
                          content={PopupContentContractorAdd}
                          data={{
                            type: 'EXECUTOR',
                            setContractors: (e) => setFieldValue('executor', e),
                          }}
                          Icon={PlusSVG}
                          overflowAuto
                          name="productOffice"
                        />
                      </div>
                    }
                  />
                </>
              )}

              {data.id && (
                <>
                  {' '}
                  <FormField
                    label="Koordynator inwestycji"
                    render={
                      <SelectComponent
                        value={values.coordinator}
                        data={handleNewField(investmentCoordinator)}
                        fullWidth
                        error={errors.coordinator}
                        onChange={(e) => setFieldValue('coordinator', e.value)}
                        isClearable
                        disabled={!activeEdit}
                      />
                    }
                  />
                  <FormField
                    label="Przypomnij koordynatorowi"
                    render={
                      <SelectComponent
                        value={values.remindCoordinator}
                        data={handleNewField(enums.remindCoordinator)}
                        fullWidth
                        error={errors.remindCoordinator}
                        onChange={(e) => setFieldValue('remindCoordinator', e.value)}
                        isClearable
                        disabled={!activeEdit}
                      />
                    }
                  />
                  <FormField
                    label="Koordynator projektu"
                    render={
                      <SelectComponent
                        value={values.designCoordinator}
                        data={handleNewField(projectCoordinator)}
                        fullWidth
                        error={errors.designCoordinator}
                        onChange={(e) => setFieldValue('designCoordinator', e.value)}
                        isClearable
                        disabled={!activeEdit}
                      />
                    }
                  />
                  <FormField
                    label="Przypomnij koordynatorowi"
                    render={
                      <SelectComponent
                        value={values.remindDesignCoordinator}
                        data={handleNewField(enums.remindCoordinator)}
                        fullWidth
                        error={errors.remindDesignCoordinator}
                        onChange={(e) => setFieldValue('remindDesignCoordinator', e.value)}
                        isClearable
                        disabled={!activeEdit}
                      />
                    }
                  />
                </>
              )}
              <FormField
                label="Miasto"
                render={
                  <SimilarTextInput
                    name="city"
                    value={values.city}
                    error={errors.city}
                    disabled={!activeEdit}
                  />
                }
              />
              <FormField
                label="Dział"
                render={
                  <SelectComponent
                    value={values.department}
                    data={handleNewField(enums.department)}
                    fullWidth
                    error={errors.department}
                    onChange={(e) => setFieldValue('department', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />

              <FormField
                label="Region"
                render={
                  <MultiSelectComponent
                    value={values.region}
                    data={handleNewField(regionData)}
                    fullWidth
                    error={errors.region}
                    onChange={(e) => setFieldValue('region', e)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />
              <FormField
                label="Typ systemu"
                render={
                  <SelectComponent
                    value={values.systemType}
                    data={handleNewField(enums.systemType)}
                    fullWidth
                    error={errors.systemType}
                    onChange={(e) => setFieldValue('systemType', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />
              <FormField
                label="Wielkość systemu"
                render={
                  <SelectComponent
                    value={values.systemSize}
                    data={handleNewField(enums.systemSize)}
                    fullWidth
                    error={errors.systemSize}
                    onChange={(e) => setFieldValue('systemSize', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />
              <FormField
                label="Typ obiektu"
                render={
                  <SelectComponent
                    value={values.objectType}
                    data={handleNewField(enums.objectType)}
                    fullWidth
                    error={errors.objectType}
                    onChange={(e) => setFieldValue('objectType', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />
              <FormField
                label="Rodzaj obiektu"
                render={
                  <SelectComponent
                    value={values.objectKind}
                    data={handleNewField(enums.objectKind)}
                    fullWidth
                    error={errors.objectKind}
                    onChange={(e) => setFieldValue('objectKind', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />

              <FormField
                label="Typ projektu"
                render={
                  <SelectComponent
                    value={values.projectType}
                    data={handleNewField(enums.projectType)}
                    fullWidth
                    error={errors.projectType}
                    onChange={(e) => setFieldValue('projectType', e.value)}
                    isClearable
                    disabled={!activeEdit}
                  />
                }
              />
            </FormColumn>
            <PopupFooter spaceBetween={!!data?.id}>
              {permission?.[`edytowanie inwestycji`] && data.id && (
                <div className="absolute left-1/2 flex -translate-x-1/2 transform gap-4">
                  <Checkbox
                    name="sendMailCoordinator"
                    label="wyślij e-mail do koordynatora inwestycji"
                    checked={values.sendMailCoordinator}
                    accentColor={mainTheme.accentColorHover}
                    disabled={!activeEdit}
                  />
                  <Checkbox
                    name="sendMailDesignCoordinator"
                    label="wyślij e-mail do koordynatora projektu"
                    checked={values.sendMailDesignCoordinator}
                    accentColor={mainTheme.accentColorHover}
                    disabled={!activeEdit}
                  />
                </div>
              )}

              {permission?.[`usuwanie inwestycji`] && data?.id && (
                <ButtonWithPopup
                  width="small"
                  type="button"
                  content={PopupConfirm}
                  buttonText="Usuń"
                  secondary
                  zIndex={100}
                  data={{
                    elementName: data.name,
                    actionHandler: () => {
                      deleteInvestment(data.id);
                      handlePopup(false);
                    },
                  }}
                />
              )}
              <div className="flex w-full justify-end ">
                {permission?.[`edytowanie inwestycji`] && data.id && !activeEdit ? (
                  <EditButton type="button" onClick={() => setActiveEdit(!activeEdit)}>
                    Edytuj
                  </EditButton>
                ) : (
                  <ButtonsWrapper>
                    {permission?.[`edytowanie inwestycji`] && data.id && (
                      <>
                        <Button
                          type="button"
                          cancel
                          onClick={() => {
                            setActiveEdit(false);
                            handleReset();
                          }}
                        >
                          Anuluj
                        </Button>
                        <Button disabled={isLoadingPost || isLoadingPut} type="submit">
                          Zapisz {(isLoadingPost || isLoadingPut) && <LoaderBtn />}
                        </Button>
                      </>
                    )}
                  </ButtonsWrapper>
                )}
                {!data.id && (
                  <Button disabled={isLoadingPost || isLoadingPut} type="submit">
                    Zapisz {(isLoadingPost || isLoadingPut) && <LoaderBtn />}
                  </Button>
                )}
              </div>
            </PopupFooter>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default FormAddInInvestment;
