import React from 'react';

const BellSVG = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_774_2266)">
<path d="M8.49999 0C7.25308 0 6.23853 1.01455 6.23853 2.26146V2.94379H7.40824V2.26146C7.40824 1.65946 7.89815 1.16971 8.49999 1.16971C9.10199 1.16971 9.59194 1.65943 9.59194 2.26146V2.94379H10.7616V2.26146C10.7616 1.01455 9.74693 0 8.49999 0Z" fill="#414141"/>
<path d="M10.0987 14.251C10.0987 15.1218 9.39021 15.8303 8.51952 15.8303H8.48054C7.60989 15.8303 6.9014 15.1218 6.9014 14.251H5.73169C5.73169 15.7669 6.96479 17 8.48054 17H8.51952C10.0353 17 11.2684 15.7669 11.2684 14.251H10.0987Z" fill="#414141"/>
<path d="M16.0687 14.0325L14.4071 11.4476V8.12967C14.4071 4.8402 11.7571 2.16406 8.50004 2.16406C5.24275 2.16406 2.59294 4.8402 2.59294 8.12967V11.4476L0.931359 14.0323C0.815546 14.2122 0.807378 14.4411 0.90991 14.6288C1.01244 14.8166 1.20917 14.9336 1.42323 14.9336H15.5769C15.791 14.9336 15.9876 14.8166 16.09 14.629C16.1927 14.4413 16.1845 14.2124 16.0687 14.0325ZM2.49446 13.7638L3.66965 11.9356C3.73028 11.8414 3.76266 11.7314 3.76266 11.6193V8.12967C3.76266 5.4853 5.88785 3.33381 8.50004 3.33381C11.1124 3.33381 13.2374 5.48534 13.2374 8.12967V11.6193C13.2374 11.7314 13.2696 11.8412 13.3302 11.9356L14.5054 13.7638H2.49446Z" fill="#414141"/>
</g>
<defs>
<clipPath id="clip0_774_2266">
<rect width="17" height="17" fill="white"/>
</clipPath>
</defs>
</svg>

);

export default BellSVG;
