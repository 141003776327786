import { logisticTask } from '../../../endpoints/endpoints';
import { getAuthorizationHeader } from '../getAuthorizationHeader';

import { apiSlice } from '../apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['logistic'] });

export const logisticApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getLogisticTask: builder.query({
      query: () => ({
        url: logisticTask,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['logistic'],
    }),

    deleteLogisticTask: builder.mutation({
      query: (id) => ({
        url: `${logisticTask}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['logistic'],
    }),

    postLogisticTask: builder.mutation({
      query: (name) => ({
        url: logisticTask,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['logistic'],
    }),
  }),
});

export const {
  useGetLogisticTaskQuery,
  useLazyGetLogisticTaskQuery,
  useDeleteLogisticTaskMutation,
  usePostLogisticTaskMutation,
} = logisticApi;
