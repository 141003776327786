export const api = 'https://api-awex.ultimate.systems/api/';
// export const api = 'http://localhost:8080/api/';

// User authentication

export const loginApi = `${api}login`;

export const registration = `${api}manage/register`;

export const province = `${api}manage/province`;

export const provinceAdmin = `${api}province`;

export const permission = `${api}manage/permission/access-group`;

export const permissionDefault = `${api}manage/permission/default`;

export const region = `${api}manage/region`;

export const contract = `${api}manage/contract`;

export const contactStatus = `${api}secured/contact-status`;

export const contactOptions = `${api}secured/additional-contact-options`;

export const configuration = `${api}configuration`;

export const user = `${api}manage/user`;

export const nationality = `${api}manage/nationality`;

export const notification = `${api}secured/notification`;

export const calendar = `${api}secured/calendar`;

export const clientStatus = `${api}manage/client-status`;

export const clientOrigin = `${api}manage/client-origin`;

export const contractor = `${api}secured/contractor`;

export const contact = `${api}secured/contact-data`;

export const sapData = `${api}secured/sap-data`;

export const industry = `${api}manage/industry`;

export const group = `${api}manage/client-group`;

export const shoppingGroup = `${api}manage/shopping-group`;

export const investment = `${api}secured/investment`;

export const emailTask = `${api}manage/email-task-kind`;

export const logisticTask = `${api}manage/logistic-task-kind`;

export const meetingTask = `${api}manage/meeting-task-kind`;

export const offerTask = `${api}manage/offer-task-kind`;

export const phoneTask = `${api}manage/phone-task-kind`;

export const projectAppTask = `${api}manage/project-app-task-kind`;

export const projectServiceTask = `${api}manage/project-service-task-kind`;

export const promising = `${api}manage/promising`;

export const serviceTask = `${api}manage/service-app-task-kind`;

export const task = `${api}secured/task`;

export const taskType = `${api}manage/task-type`;

export const taskKind = `${api}manage/task-kind`;

export const obtainedBy = `${api}secured/obtained-by-position`;

export const note = `${api}secured/note`;

export const stage = `${api}secured/stage`;

export const statistic = `${api}secured/statistic-data`;

export const salesData = `${api}secured/sales-data`;

export const competition = `${api}secured/competition`;

export const warranty = `${api}secured/warranty-data`;

export const location = `${api}secured/location-data`;

export const logs = `${api}secured/file/crm-logs`;

export const changeHistory = `${api}secured/change-history`;

export const absence = `${api}secured/absence`;

export const calculator = `${api}secured/calculator`;

export const file = `${api}secured/file`;

export const target = `${api}secured/target`;

export const resetPassword = `${api}reset-password`;

export const offer = `${api}secured/offer`;

export const nextNumber = `${api}secured/next-number`;

export const userDivision = `${api}manage/user-division`;
