import React from 'react';
import format from 'date-fns/format';
import pl from 'date-fns/locale/pl';
import styled from 'styled-components';
import TitleInDashboard from 'components/atoms/TitleInDashboard/TitleInDashboard';
import ArrowSVG from 'components/atoms/SVG/ArrowSVG/ArrowSVG';

const StyledCustomToolbarDropdown = styled.div`
  padding: 10px 16px;
  background: #fff;
  border: 0.5px solid #498fe2;
  border-left: 0;
  border-right: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StyledDateViewBox = styled.span`
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
  line-height: 26px;
  color: ${({ theme }) => theme.textColor};
  min-width: 150px;
  text-align: center;
  display: block;
`;

const CustomToolbarDropdown = (props) => {
  const toolbar = props;

  const goToBack = () => {
    switch (toolbar.view) {
      case 'day':
        toolbar.date.setDate(toolbar.date.getDate() - 1);
        break;
      default:
        toolbar.date.setDate(toolbar.date.getDate() - 1);
    }

    toolbar.onNavigate('prev');
  };

  const goToNext = () => {
    switch (toolbar.view) {
      case 'day':
        toolbar.date.setDate(toolbar.date.getDate() + 1);
        break;
      default:
        toolbar.date.setDate(toolbar.date.getDate() + 1);
    }
    toolbar.onNavigate('next');
  };

  function getBeginOfWeek(date = new Date(), startOfWeek = 1) {
    const result = new Date(date);
    while (result.getDay() !== startOfWeek) {
      result.setDate(result.getDate() - 1);
    }
    return result;
  }

  function getFridayOfCurrentWeek(date = new Date()) {
    const today = date;
    const first = today.getDate() - today.getDay() + 1;
    const fifth = first + 4;

    const friday = new Date(today.setDate(fifth));

    return friday;
  }

  const displayFormatDate = () => {
    switch (toolbar.view) {
      case 'month':
        return format(toolbar.date, 'LLLL, yyyy', {
          locale: pl,
        });
      case 'day':
        return format(toolbar.date, 'dd.MM', {
          locale: pl,
        });
      case 'work_week':
        return `${format(getBeginOfWeek(toolbar.date), 'dd.MM', {
          locale: pl,
        })} - ${format(getFridayOfCurrentWeek(toolbar.date), 'dd.MM', {
          locale: pl,
        })}`;
      default:
        return format(toolbar.date, 'yyyy', {
          locale: pl,
        });
    }
  };

  return (
    <StyledCustomToolbarDropdown>
      <div className="flex justify-center w-full">
        <button type="button" onClick={goToBack} className="p-2 ">
          <ArrowSVG />
        </button>
        <StyledDateViewBox>{displayFormatDate()}</StyledDateViewBox>
        <button type="button" onClick={goToNext} className="p-2 rotate-180">
          <ArrowSVG />
        </button>
      </div>
    </StyledCustomToolbarDropdown>
  );
};

export default CustomToolbarDropdown;
