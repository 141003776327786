import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import HeaderDashboard from 'components/molecules/HeaderDashboard/HeaderDashboard';

const StyledH1 = styled.h1`
  margin: 0;
  font-size: 18px;
  color: ${({ theme }) => theme.accentColor};
  margin-right: 60px;
  padding: 3px;
`;

const StyledBoxTabContent = styled.div`
  ${({ active }) =>
    active
      ? css`
          display: block;
        `
      : css`
          display: none;
          width: 100%;
        `};
`;

const FormTabs = ({ children, TabContent, contentTab, headerPadding, values, errors, data }) => (
  <div>
    {children && (
      <HeaderDashboard contentTab={contentTab} headerPadding={headerPadding}>
        <StyledH1>{children}</StyledH1>
      </HeaderDashboard>
    )}

    <StyledBoxTabContent active>
      <TabContent values={values} errors={errors} data={data} />
    </StyledBoxTabContent>
  </div>
);
FormTabs.propTypes = {
  children: PropTypes.string,
  contentTab: PropTypes.bool,
  headerPadding: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.any.isRequired,
};

FormTabs.defaultProps = {
  children: '',
  contentTab: false,
  headerPadding: '10px 15px',
};

export default FormTabs;
