import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import userReducer from './reducers/userReducer';
import permissionsReducer from './reducers/permissionsReducer';
import teleconferenceUtilsReducer from './reducers/teleconferenceUtilsReducer';
import repositoryUtilsReducer from './reducers/repositoryUtilsReducer';
import investmentLocalizationReducer from './reducers/investmentLocalizationReducer';
import filtersUtilsReducer from './reducers/filtersUtilsReducer';
import templateOptionsReducer from './reducers/templateOptionsReducer';

import { apiSlice } from './services/apiSlice';

export const store = configureStore({
  reducer: {
    userReducer,
    permissionsReducer,
    investmentLocalizationReducer,
    teleconferenceUtilsReducer,
    repositoryUtilsReducer,
    filtersUtilsReducer,
    templateOptionsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

export const AppState = store.getState;
export const AppDispatch = store.dispatch;
setupListeners(store.dispatch);
