import { absence } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';

import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['Absence'] });

export const absenceApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getAbsences: builder.query({
      query: ({ page, size, sort, search }) => {
        const params = new URLSearchParams({
          page,
          size,
          sort,
          search: `${search?.title ? `title=%${search?.title}%&` : ''}${
            search?.type.length ? `${search.type.map((item) => `type=${item}`).join('|')}&` : ''
          }
          ${
            search?.employee.length
              ? `${search.employee.map((item) => `employee=${item}`).join('|')}&`
              : ''
          }${
            search?.endDate.length
              ? `${search.endDate.map((item) => `endDate=${item}`).join('|')}&`
              : ''
          }${
            search?.status.length
              ? `${search.status.map((item) => `status=${item}`).join('|')}`
              : ''
          }`,
        });

        const keysForDel = [];
        params.forEach((value, key) => {
          if (value === 'undefined' || value === '') {
            keysForDel.push(key);
          }
        });

        keysForDel.forEach((key) => {
          params.delete(key);
        });

        return {
          url: `${absence}?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['Absence'],
    }),

    getAbsencesWithStatus: builder.query({
      query: ({ page, size, sort, search }) => {
        const searchParams = new URLSearchParams({
          ...(search?.status && { status: search?.status }),
        });

        const params = new URLSearchParams({
          ...(page && { page }),
          ...(size && { size }),
          ...(sort && { sort }),
          search: `${searchParams.toString()}&${search?.title ? `title=%${search?.title}%&` : ''}${
            search?.type.length ? `${search.type.map((item) => `type=${item}`).join('|')}&` : ''
          }${search?.startDate ? `startDate>${search.startDate}T00:00&` : ''}${
            search?.endDate ? `endDate<${search.endDate}T23:59` : ''
          }`,
        });

        return {
          url: `${absence}?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['Absence'],
    }),

    getAbsence: builder.query({
      query: (id) => ({
        url: `${absence}/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['Absence'],
    }),

    deleteAbsence: builder.mutation({
      query: (id) => ({
        url: `${absence}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Absence'],
    }),
    postAbsence: builder.mutation({
      query: (data) => ({
        url: absence,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['Absence'],
    }),

    putAbsence: builder.mutation({
      query: (data) => ({
        url: absence,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['Absence'],
    }),

    putAbsenceStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `${absence}/${id}/${status}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Absence'],
    }),
  }),
});

export const {
  useGetAbsencesQuery,
  useGetAbsencesWithStatusQuery,
  useLazyGetAbsencesWithStatusQuery,
  useLazyGetAbsenceQuery,
  useDeleteAbsenceMutation,
  usePostAbsenceMutation,
  usePutAbsenceMutation,
  usePutAbsenceStatusMutation,
} = absenceApi;
