import React from 'react';

const RegistrationProjectSVG = () => (
  <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6383 4.40582C13.1586 3.92604 12.3779 3.92607 11.898 4.40593C11.7504 4.55366 10.808 5.49683 10.6641 5.64093V2.39055C10.6641 2.06188 10.5361 1.7529 10.3036 1.5205L9.14359 0.360391C8.91119 0.127969 8.60218 0 8.27351 0H1.23047C0.551988 0 0 0.551988 0 1.23047V12.7695C0 13.448 0.551988 14 1.23047 14H9.43359C10.1121 14 10.6641 13.448 10.6641 12.7695V9.12272L13.6383 6.14597C14.1192 5.66511 14.1193 4.88674 13.6383 4.40582ZM8.20315 0.820312C8.28064 0.820312 8.42997 0.806887 8.56354 0.940434L9.72363 2.10055C9.85378 2.23073 9.84378 2.37338 9.84378 2.46094H8.20315V0.820312ZM9.84375 12.7695C9.84375 12.9957 9.65978 13.1797 9.43359 13.1797H1.23047C1.00431 13.1797 0.820312 12.9957 0.820312 12.7695V1.23047C0.820312 1.00431 1.00431 0.820312 1.23047 0.820312H7.38281V2.87109C7.38281 3.09761 7.56645 3.28125 7.79297 3.28125H9.84375V6.46196C9.84375 6.46196 8.63368 7.67307 8.63362 7.67309L8.05385 8.25289C8.00885 8.2979 7.97489 8.35281 7.95476 8.41321L7.37469 10.1534C7.32555 10.3007 7.36392 10.4633 7.47378 10.5731C7.58379 10.6831 7.74635 10.7213 7.89351 10.6722L9.63367 10.0921C9.69407 10.072 9.74898 10.0381 9.79398 9.99305L9.84375 9.94328V12.7695ZM8.92391 8.54293L9.50395 9.12297L9.28238 9.34454L8.41233 9.63454L8.70234 8.76446L8.92391 8.54293ZM10.0839 8.54279L9.50384 7.96275C9.81271 7.65363 11.1845 6.28064 11.4755 5.98946L12.0555 6.5695L10.0839 8.54279ZM13.0581 5.56604L12.6353 5.98921L12.0553 5.40917L12.4782 4.98586C12.6383 4.82593 12.8984 4.82595 13.0583 4.98586C13.2182 5.14582 13.219 5.40512 13.0581 5.56604Z"
      fill="#498FE2"
    />
    <path
      d="M7.79297 4.10156H2.05078C1.82427 4.10156 1.64062 4.2852 1.64062 4.51172C1.64062 4.73823 1.82427 4.92188 2.05078 4.92188H7.79297C8.01948 4.92188 8.20312 4.73823 8.20312 4.51172C8.20312 4.2852 8.01948 4.10156 7.79297 4.10156Z"
      fill="#498FE2"
    />
    <path
      d="M6.15234 5.74219H2.05078C1.82427 5.74219 1.64062 5.92583 1.64062 6.15234C1.64062 6.37886 1.82427 6.5625 2.05078 6.5625H6.15234C6.37886 6.5625 6.5625 6.37886 6.5625 6.15234C6.5625 5.92583 6.37886 5.74219 6.15234 5.74219Z"
      fill="#498FE2"
    />
    <path
      d="M6.15234 7.38281H2.05078C1.82427 7.38281 1.64062 7.56645 1.64062 7.79297C1.64062 8.01948 1.82427 8.20312 2.05078 8.20312H6.15234C6.37886 8.20312 6.5625 8.01948 6.5625 7.79297C6.5625 7.56645 6.37886 7.38281 6.15234 7.38281Z"
      fill="#498FE2"
    />
    <path
      d="M6.15234 9.02344H2.05078C1.82427 9.02344 1.64062 9.20708 1.64062 9.43359C1.64062 9.66011 1.82427 9.84375 2.05078 9.84375H6.15234C6.37886 9.84375 6.5625 9.66011 6.5625 9.43359C6.5625 9.20708 6.37886 9.02344 6.15234 9.02344Z"
      fill="#498FE2"
    />
    <path
      d="M7.79297 11.5391H5.33203C5.10552 11.5391 4.92188 11.7227 4.92188 11.9492C4.92188 12.1757 5.10552 12.3594 5.33203 12.3594H7.79297C8.01949 12.3594 8.20313 12.1757 8.20313 11.9492C8.20313 11.7227 8.01949 11.5391 7.79297 11.5391Z"
      fill="#498FE2"
    />
  </svg>
);

export default RegistrationProjectSVG;
