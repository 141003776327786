import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
    ${normalize}    
    
    *, *::before, *::after{
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html{
        /* font-size: 62.5%; */
    }

    body{
        /* font-size: 1.6rem; // happy rems */
        font-family: 'Lato', sans-serif;
        background-color: #F3F7FF;;
        color: #414141;
    }

    * {
    scrollbar-width: thin;
    scrollbar-color: #9E9E9E #E5E5E5;
    }

    *::-webkit-scrollbar {
    width: 8px;
    }

    *::-webkit-scrollbar-track {
    background: #E5E5E5;
    }

    *::-webkit-scrollbar-thumb {
    background-color: #9E9E9E;
    border-radius: 5px;
    }
`;

export default GlobalStyle;
