import React, { useEffect } from 'react';
import PopupHeader from 'components/molecules/PopupHeader/PopupHeader';
import FormAddInvestment from 'components/organisms/FormAddInvestment/FormAddInvestment';
import { useLazyGetInvestmentQuery } from 'redux/services/investmentApi';
import Loader from 'components/atoms/Loader/Loader';

const PopupContentInvestmentsAdd = ({ data, handlePopup }) => {
  const [trigger, { data: investmentData }] = useLazyGetInvestmentQuery();
  useEffect(() => {
    if (data?.objectId || data?.data?.objectId) {
      trigger(data.objectId || data?.data?.objectId);
    }
  }, []);

  const investment = {
    ...data,
    ...investmentData,
  };

  return (
    <div>
      <PopupHeader>
        {data?.id || data?.data?.objectId ? 'Dane podstawowe o inwestycji' : 'Dodawanie inwestycji'}
      </PopupHeader>

      {data?.objectId || data?.data?.objectId || data?.objectId ? (
        <>
          {investmentData ? (
            <FormAddInvestment
              data={investment}
              permission={data.permission}
              handlePopup={handlePopup}
            />
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <FormAddInvestment data={data} permission={data.permission} handlePopup={handlePopup} />
      )}
    </div>
  );
};

export default PopupContentInvestmentsAdd;
