/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import PopupHeader from 'components/molecules/PopupHeader/PopupHeader';
import { useLazyGetEmailTaskQuery } from 'redux/services/TaskKind/emailApi';
import { useLazyGetMeetingTaskQuery } from 'redux/services/TaskKind/meetingApi';
import { useLazyGetPhoneTaskQuery } from 'redux/services/TaskKind/phoneApi';
import { useLazyGetProjectAppTaskQuery } from 'redux/services/TaskKind/projectAppApi';
import { useLazyGetOfferTaskQuery } from 'redux/services/TaskKind/offerApi';
import { useLazyGetServiceTaskQuery } from 'redux/services/TaskKind/serviceTaskApi';
import { useLazyGetLogisticTaskQuery } from 'redux/services/TaskKind/logisticApi';
import { useLazyGetProjectServiceTaskQuery } from 'redux/services/TaskKind/projectServiceApi';
import FormAddQuickTask from '../FormAddQuickTask/FormAddQuickTask';

const PopupContentQuickTask = ({ data, handlePopup }) => {
  const { task } = data.informations || [];
  const { permission } = data;

  const [type, setType] = useState(data.informations?.type || task?.taskType);
  const [typeKind, setTypeKind] = useState([]);

  const [getEmailTask] = useLazyGetEmailTaskQuery();
  const [getMeetingTask] = useLazyGetMeetingTaskQuery();
  const [getPhoneTask] = useLazyGetPhoneTaskQuery();
  const [getProjectTask] = useLazyGetProjectAppTaskQuery();
  const [getOfferTask] = useLazyGetOfferTaskQuery();
  const [getServiceTask] = useLazyGetServiceTaskQuery();
  const [getLogisticTask] = useLazyGetLogisticTaskQuery();
  const [getProjectServiceTask] = useLazyGetProjectServiceTaskQuery();

  useEffect(async () => {
    switch (type) {
      case 'EMAIL':
        const { data: userData } = await getEmailTask();
        return setTypeKind(userData);
      case 'MEETING':
        const { data: meetingData } = await getMeetingTask();
        return setTypeKind(meetingData);
      case 'PHONE':
        const { data: phoneData } = await getPhoneTask();
        return setTypeKind(phoneData);
      case 'PROJECT_APPLICATION':
        const { data: projectData } = await getProjectTask();
        return setTypeKind(projectData);
      case 'OFFER':
        const { data: offerData } = await getOfferTask();
        return setTypeKind(offerData);

      case 'SERVICE_APPLICATION':
        const { data: serviceData } = await getServiceTask();
        return setTypeKind(serviceData);

      case 'LOGISTICS':
        const { data: logisticData } = await getLogisticTask();
        return setTypeKind(logisticData);

      case 'PROJECT_SERVICE':
        const { data: projectServiceData } = await getProjectServiceTask();
        return setTypeKind(projectServiceData);

      default:
        return [];
    }
  }, [type]);

  return (
    <div>
      <PopupHeader>Dodaj zadanie</PopupHeader>
      <FormAddQuickTask
        data={data}
        handlePopup={handlePopup}
        typeKind={typeKind}
        setType={setType}
        permission={permission}
      />
    </div>
  );
};

export default PopupContentQuickTask;
