import React from 'react';
import PopupHeader from 'components/molecules/PopupHeader/PopupHeader';
import Button from 'components/atoms/Button/Button';
import styled from 'styled-components';

const MessageWrapper = styled.div`
  padding: 16px 0 0 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.textColor};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  > * {
    min-width: 100px;
  }

  > :last-child {
    margin-left: 8px;
  }
`;

const PopupConfirm = ({ data, handlePopup }) => (
  <>
    <PopupHeader>Potwierdzenie</PopupHeader>
    <MessageWrapper>
      {!data.message ? (
        <p>Czy na pewno chesz usunąć: {data.elementName}</p>
      ) : (
        <p>
          {data.message}
          {data.elementName && `:${data.elementName}`}
        </p>
      )}
    </MessageWrapper>
    <ButtonWrapper>
      <Button cancel onClick={() => handlePopup()}>
        Anuluj
      </Button>
      <Button
        onClick={() => {
          data.actionHandler();
          handlePopup();
        }}
      >
        Potwierdź
      </Button>
    </ButtonWrapper>
  </>
);

export default PopupConfirm;
