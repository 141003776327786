import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import Loader from 'components/atoms/Loader/Loader';
import { home, investments, zadania } from 'helpers/AllRouteName';

function PublicRoute({ render, isAuthenticated }) {
  const [searchParams] = useSearchParams();

  const paramType = searchParams.get('type');
  const paramId = searchParams.get('id');

  const handleNavigate = (type, id) => {
    switch (type) {
      case 'TASK':
        return `${zadania.path}?open=true&id=${id}`;
      case 'INVESTMENT':
        return `${investments.path}/inwestycja/?id=${id}`;
      default:
        return home.path;
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <div>{isAuthenticated ? <Navigate to={handleNavigate(paramType, paramId)} /> : render}</div>
    </Suspense>
  );
}

PublicRoute.propTypes = {
  render: PropTypes.element.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default PublicRoute;
