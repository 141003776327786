import React from 'react';

const ContractorsSVG = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0374 3.99327L11.2856 1.5818C11.3496 1.45823 11.3445 1.31019 11.2724 1.19148C11.2 1.07252 11.0709 1 10.9317 1H5.70971C5.57066 1 5.44159 1.07252 5.36919 1.19148C5.29705 1.31019 5.2918 1.45823 5.35596 1.5818L6.6042 3.9934L5.02106 12.9473C4.95116 13.3418 5.05713 13.7473 5.31115 14.0573L7.2574 16.4338C7.51841 16.7526 7.90873 16.9375 8.32078 16.9375C8.73269 16.9375 9.12289 16.7526 9.38415 16.4338L11.3304 14.0573C11.5844 13.7473 11.6904 13.3418 11.6205 12.9473L10.0374 3.99327ZM10.2771 1.79688L9.37928 3.55002H7.2624L6.36466 1.79688H10.2771ZM10.714 13.5524L8.76777 15.9292C8.65805 16.063 8.49403 16.1406 8.3209 16.1406C8.1479 16.1406 7.98375 16.063 7.87428 15.9292L5.92778 13.5524C5.82131 13.4257 5.77662 13.2582 5.80608 13.0953L7.35427 4.3469H9.28729L10.8357 13.0953C10.8652 13.2581 10.8206 13.4257 10.714 13.5524Z"
      fill="#414141"
    />
  </svg>
);

export default ContractorsSVG;
