import React, { useEffect, useState } from 'react';
import ProfilInformation from 'components/molecules/ProfilInformation/ProfilInformation';
import styled from 'styled-components';
import Notifications from 'components/molecules/Notifications/Notifications';
import CalendarHeaderNav from 'components/atoms/CalendarHeaderNav/CalendarHeaderNav';
import {
  useGetNewNotificationsQuery,
  useGetNotificationsHistoryQuery,
} from 'redux/services/notificationApi';
import { useSelector } from 'react-redux';

const StyledHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 7px 16px;
  position: fixed;
  top: 0;
  left: 0;
  background: #f3f7ff;
  z-index: 22;
`;

const HeaderNav = () => {
  const [sort, setSort] = useState('notificationDate,desc');

  const { data: historyData } = useGetNotificationsHistoryQuery(
    { size: 10, sort },
    { pollingInterval: 30000 },
  );

  const { data: notifications } = useGetNewNotificationsQuery(
    { size: 10, sort },
    { pollingInterval: 30000 },
  );

  const user = useSelector((state) => state.userReducer.data);

  const [isActiveCalendar, setActiveCalendar] = useState(false);
  const [isActiveNotifications, setActiveNotifications] = useState(false);

  useEffect(() => {
    if (isActiveCalendar) {
      setActiveNotifications(false);
    }
  }, [isActiveCalendar]);

  useEffect(() => {
    if (isActiveNotifications) {
      setActiveCalendar(false);
    }
  }, [isActiveNotifications]);

  return (
    <StyledHeader>
      <CalendarHeaderNav isActive={isActiveCalendar} setActive={setActiveCalendar} />
      <Notifications
        notifications={historyData?.content}
        newNotifications={notifications?.length}
        isActive={isActiveNotifications}
        setActive={setActiveNotifications}
      />
      <ProfilInformation />
    </StyledHeader>
  );
};

export default HeaderNav;
