import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function getWidthString(col) {
  const width = (col / 12) * 100;
  return `width: ${width}%`;
}

const StyledFormColumn = styled.div`
  ${({ col }) => getWidthString(col)};
  padding: ${({ padding }) => padding};
`;

const FormColumn = ({ children, col, padding }) => (
  <StyledFormColumn col={col} padding={padding}>
    {' '}
    {children}{' '}
  </StyledFormColumn>
);

FormColumn.propTypes = {
  children: PropTypes.node.isRequired,
  col: PropTypes.number,
  padding: PropTypes.string,
};

FormColumn.defaultProps = {
  col: 12,
  padding: '0 0 0 0',
};

export default FormColumn;
