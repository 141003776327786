import React from 'react';

const TaskQuickSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.75 12.6125L6.50625 10.3688L5.625 11.25L8.75 14.375L14.375 8.75005L13.4938 7.86255L8.75 12.6125Z"
      fill="#498FE2"
    />
    <path
      d="M15.625 3.125H13.75V2.5C13.75 2.16848 13.6183 1.85054 13.3839 1.61612C13.1495 1.3817 12.8315 1.25 12.5 1.25H7.5C7.16848 1.25 6.85054 1.3817 6.61612 1.61612C6.3817 1.85054 6.25 2.16848 6.25 2.5V3.125H4.375C4.04348 3.125 3.72554 3.2567 3.49112 3.49112C3.2567 3.72554 3.125 4.04348 3.125 4.375V17.5C3.125 17.8315 3.2567 18.1495 3.49112 18.3839C3.72554 18.6183 4.04348 18.75 4.375 18.75H15.625C15.9565 18.75 16.2745 18.6183 16.5089 18.3839C16.7433 18.1495 16.875 17.8315 16.875 17.5V4.375C16.875 4.04348 16.7433 3.72554 16.5089 3.49112C16.2745 3.2567 15.9565 3.125 15.625 3.125V3.125ZM7.5 2.5H12.5V5H7.5V2.5ZM15.625 17.5H4.375V4.375H6.25V6.25H13.75V4.375H15.625V17.5Z"
      fill="#498FE2"
    />
  </svg>
);

export default TaskQuickSVG;
