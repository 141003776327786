/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */
import SelectIconSVG from 'components/atoms/SVG/SelectIconSVG/SelectIconSVG';
import { debounce } from 'debounce';
import { handleNewField } from 'helpers/formsHelpers';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';

const StyledSelect = styled(Select)`
  width: 100%;
  margin-right: ${(props) => (props.fullWidth ? '0' : '15px')};
  font-size: 14px;

  .select__control {
    border-color: #dddddd;
    min-height: 26px !important;
    cursor: pointer;
    &:hover {
      border-color: #dddddd;

      ${({ error }) =>
        error
          ? css`
              border-color: ${({ theme }) => theme.warrningColor};
            `
          : null}
    }

    &:focus {
      border-color: #dddddd;

      ${({ error }) =>
        error
          ? css`
              border-color: ${({ theme }) => theme.warrningColor};
            `
          : null}
    }

    ${({ error }) =>
      error
        ? css`
            border-color: ${({ theme }) => theme.warrningColor};
          `
        : null}
  }

  .select__value-container {
    display: flex !important;
    align-items: center;
    height: ${(props) => (props.isMulti ? null : '26px')};
  }

  .select__indicator-separator {
    display: none;
  }

  .select__indicators {
    margin-right: 6px;
  }

  .select__option {
    &:active {
      background-color: #dddddd;
    }
  }

  .select__menu {
    z-index: 11;
  }
`;

const DropdownIndicator = () => <SelectIconSVG />;

const colourStyles = {
  control: (styles, { base, isMulti }) => ({
    ...styles,
    ...base,
    boxShadow: 'none',
    minHeight: 20,
    ...(!isMulti && { height: 20 }),
  }),
  input: (styles, { base }) => ({
    ...styles,
    paddingTop: 0,
    fontWeight: 400,
    color: 'black',
  }),

  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? '#dddddd' : 'inherit',
    '&:hover': { backgroundColor: isSelected ? '#dddddd' : '#fafafa' },
    color: '#000',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),

  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 4,
    paddingBottom: 4,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    paddingTop: 4,
    paddingBottom: 4,
  }),
};

const AsyncSelectComponent = ({
  fullWidth,
  error,
  disabled,
  value,
  onChange,
  isClearable,
  placeholder,
  className,
  dataFunction,
  isMulti,
  currData,
}) => {
  function isValue(item) {
    return item.value === value || item.label === value;
  }
  const [searchText, setSearchText] = useState('');
  const [shouldSkip, setShouldSkip] = useState(false);
  const [selectData, setSelectData] = useState([]);

  const { data, isFetching } = dataFunction(searchText, { skip: shouldSkip });

  useEffect(() => {
    if (data && currData && !data.content.map(JSON.stringify).includes(JSON.stringify(currData))) {
      setSelectData([...data.content, currData]);
    } else if (data) {
      setSelectData(data.content);
    }
  }, [data]);

  useEffect(() => {
    setShouldSkip(data?.size > data?.totalElements);
  }, []);

  const [optionSelected, setOptionSelected] = useState(
    (value && selectData && handleNewField(selectData)?.find(isValue)) || null,
  );

  useEffect(() => {
    if (value && selectData) {
      setOptionSelected(handleNewField(selectData)?.find(isValue));
    }
  }, [value, data, selectData]);

  function handleChange(event) {
    if (!event) {
      event = '';
    }

    onChange(isMulti ? event.map((item) => item.value) : event);
    setOptionSelected(event);
  }

  const handleSearchDebounced = useRef(
    // eslint-disable-next-line no-shadow
    debounce((searchText) => setSearchText(searchText), 300),
  ).current;

  const handleInputChange = (inputText, meta) => {
    if (!inputText) {
      setSearchText('');
    }
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      handleSearchDebounced(inputText);
    }
  };

  return (
    <StyledSelect
      options={handleNewField(selectData)}
      isDisabled={disabled}
      {...(!shouldSkip && { filterOption: null })}
      onInputChange={handleInputChange}
      onChange={handleChange}
      fullWidth={fullWidth}
      value={optionSelected}
      error={error}
      styles={colourStyles}
      classNamePrefix="select"
      noOptionsMessage={() => 'Brak danych'}
      placeholder={placeholder || 'Wybierz'}
      isClearable={isClearable}
      className={className}
      menuPlacement="auto"
      minMenuHeight={300}
      isLoading={isFetching}
      isMulti={isMulti}
      components={{ DropdownIndicator }}
      closeMenuOnSelect={!isMulti}
    />
  );
};
export default AsyncSelectComponent;
