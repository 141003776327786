import React, { useEffect, useState } from 'react';
import { dateFnsLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/pl';
import CalendarEvent from 'components/molecules/CalendarEvent/CalendarEvent';
import CustomCalendar from 'components/organisms/CustomCalendar/CustomCalendar';
import { useGetCalendarQuery } from 'redux/services/calendarApi';
import { enums } from 'staticData/enums';
import Loader from 'components/atoms/Loader/Loader';
import CustomToolbarDropdown from 'components/molecules/CalendarDropdown/CustomToolbarDropdown';
import moment from 'moment';
import { pl } from 'date-fns/locale';

const locales = {
  pl: enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CalendarTemplateNotification = ({ workHours }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const calendarFilters = {
    startDate,
    endDate,
  };
  const { data: calendar } = useGetCalendarQuery(calendarFilters, {
    skip: !calendarFilters.startDate || !calendarFilters.endDate,
  });

  const events = calendar?.map((event) => ({
    id: event.id,
    title: `${enums.calendarTypeEnum[event.type.split('_')[0]]}: ${event.name}`,
    status: event.status,
    contractorName: event.personName,
    data: event,
    type: event.type.split('_')[0],
    start: new Date(event.startDate),
    end: new Date(event.endDate),
    calendarType: 'investment',
  }));

  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(Views.day);
  useEffect(() => {
    setDate(new Date());
    setView(Views.DAY);
  }, []);

  useEffect(() => {
    setStartDate(
      format(new Date(moment(date).startOf('day')), 'yyyy-MM-dd', {
        locale: pl,
      }),
    );
    setEndDate(
      format(new Date(moment(date).endOf('day')), 'yyyy-MM-dd', {
        locale: pl,
      }),
    );
  }, [date]);

  return (
    <>
      {events ? (
        <CustomCalendar
          events={events || []}
          defaultDate={date}
          notification
          onNavigate={(event) => {
            setDate(new Date(event));
          }}
          showMultiDayTimes
          views={{
            day: true,
            week: true,
            month: true,
            work_week: true,
          }}
          min={
            workHours.startWork
              ? new Date(
                  2017,
                  10,
                  0,
                  workHours.startWork.slice(0, 2),
                  workHours.startWork.slice(3, 5),
                  0,
                )
              : new Date(2017, 10, 0, 7, 0, 0)
          }
          max={
            workHours.endWork
              ? new Date(
                  2017,
                  10,
                  0,
                  workHours.endWork.slice(0, 2),
                  workHours.endWork.slice(3, 5),
                  0,
                )
              : new Date(2017, 10, 0, 15, 0, 0)
          }
          view={view}
          onView={view}
          localizer={localizer}
          culture="pl"
          style={{ overflow: 'hidden' }}
          step={30}
          popup
          dayLayoutAlgorithm="no-overlap"
          components={{
            event: CalendarEvent,
            toolbar: CustomToolbarDropdown,
          }}
          eventPropGetter={(myEventsList) => {
            const handleBackground = (status) => {
              switch (status) {
                case 'ACCEPTED':
                  return '#D4E6FF';
                case 'ACTIVE':
                  return '#DEFFE1';
                case 'CLOSED_NEGATIVE':
                  return '#FFCBCB';
                case 'CLOSED_POSITIVE':
                  return '#A4F0AB';
                case 'EXPIRED':
                  return '#FFFFFF';
                case 'FOR_ACCEPTANCE':
                  return '#FFF2D1';
                case 'INACTIVE':
                  return '#EDEDED';
                case 'PAUSED':
                  return '#FFF3F4';
                case 'REJECTED':
                  return '#FFD3B6';

                default:
                  return '#FFF0EC';
              }
            };

            const handleBorderLeft = (color) => {
              switch (color) {
                case 'ACCEPTED':
                  return '5px solid #4F6C96';
                case 'ACTIVE':
                  return '5px solid #13AA22';
                case 'CLOSED_NEGATIVE':
                  return '5px solid #E20707';
                case 'CLOSED_POSITIVE':
                  return '5px solid #158E21';
                case 'EXPIRED':
                  return '5px solid #747474';
                case 'FOR_ACCEPTANCE':
                  return '5px solid #FFB800';
                case 'INACTIVE':
                  return '5px solid #404040';
                case 'PAUSED':
                  return '5px solid #FF8078';
                case 'REJECTED':
                  return '5px solid #E57878';
                default:
                  return '5px solid #81148B';
              }
            };

            const handleBorder = (color) => {
              switch (color) {
                case 'ACCEPTED':
                  return ' #4F6C96';
                case 'ACTIVE':
                  return ' #13AA22';
                case 'CLOSED_NEGATIVE':
                  return ' #E20707';
                case 'CLOSED_POSITIVE':
                  return ' #158E21';
                case 'EXPIRED':
                  return ' #747474';
                case 'FOR_ACCEPTANCE':
                  return ' #FFB800';
                case 'INACTIVE':
                  return ' #404040';
                case 'PAUSED':
                  return ' #FF8078';
                case 'REJECTED':
                  return ' #E57878';
                default:
                  return ' #81148B';
              }
            };

            return {
              style: {
                backgroundColor: handleBackground(myEventsList.status),
                borderLeft: handleBorderLeft(myEventsList.status),
                boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                outline: `1px solid ${handleBorder(myEventsList.status)}`,
              },
            };
          }}
          messages={{
            showMore: (total) => `${total} więcej`,
            allDay: 'Cały dzień',
          }}
          className={`${view}`}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CalendarTemplateNotification;
