import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.span`
  width: ${(props) => (props.width ? null : '120px')};
  flex-wrap: nowrap;
  padding-right: 15px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.textColorMedium};
`;

const FormLabel = ({ children, width }) => <StyledLabel width={width}>{children}</StyledLabel>;

export default FormLabel;
