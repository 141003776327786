import React from 'react';
import styled from 'styled-components';
import UserQuickSVG from 'components/atoms/SVG/UserQuickSVG/UserQuickSVG';
import MeetingQuickSVG from 'components/atoms/SVG/MeetingQuickSVG/MeetingQuickSVG';
import SettingsQuickSVG from 'components/atoms/SVG/SettingsQuickSVG/SettingsQuickSVG';
import PhoneQuickSVG from 'components/atoms/SVG/PhoneQuickSVG/PhoneQuickSVG';
import TaskQuickSVG from 'components/atoms/SVG/TaskQuickSVG/TaskQuickSVG';
import PopupContentQuickTask from 'components/organisms/PopupContentQuickTask/PopupContentQuickTask';
import IconButtonPopup from '../IconButtonPopup/IconButtonPopup';
import IconTextButtonPopup from '../IconTextButtonPopup/IconTextButtonPopup';

const StyledWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 26px;
  width: 100%;
  justify-content: space-between;
  /* padding: 0 10px; */
`;

const SidebarMenuQuickAction = () => (
  <StyledWrapper className="w-32 px-1 lg:w-60 lg:px-3 ">
    <IconButtonPopup
      buttonIcon={<UserQuickSVG />}
      content={PopupContentQuickTask}
      btnTooltip="Spotkanie"
      data={{ informations: { type: 'MEETING', newFastTask: true } }}
    />

    <IconButtonPopup
      buttonIcon={<TaskQuickSVG />}
      content={PopupContentQuickTask}
      btnTooltip="Zgłoszenie projektowe"
      data={{ informations: { type: 'PROJECT_APPLICATION', newFastTask: true } }}
    />
    <IconButtonPopup
      buttonIcon={<PhoneQuickSVG />}
      content={PopupContentQuickTask}
      btnTooltip="Telefon"
      data={{ informations: { type: 'PHONE', newFastTask: true } }}
    />
    <IconButtonPopup
      buttonIcon={<SettingsQuickSVG />}
      content={PopupContentQuickTask}
      btnTooltip="Zgłoszenie serwisowe"
      data={{ informations: { type: 'SERVICE_APPLICATION', newFastTask: true } }}
    />
    <IconButtonPopup
      buttonIcon={<MeetingQuickSVG />}
      content={PopupContentQuickTask}
      btnTooltip="Logistyka"
      data={{ informations: { type: 'LOGISTICS', newFastTask: true } }}
    />
  </StyledWrapper>
);

export default SidebarMenuQuickAction;
