import { projectAppTask } from '../../../endpoints/endpoints';
import { getAuthorizationHeader } from '../getAuthorizationHeader';

import { apiSlice } from '../apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['ProjectAppTask'] });

export const projectAppApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProjectAppTask: builder.query({
      query: () => ({
        url: projectAppTask,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['ProjectAppTask'],
    }),

    deleteProjectAppTask: builder.mutation({
      query: (id) => ({
        url: `${projectAppTask}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['ProjectAppTask'],
    }),

    postProjectAppTask: builder.mutation({
      query: (name) => ({
        url: projectAppTask,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['ProjectAppTask'],
    }),
  }),
});

export const {
  useGetProjectAppTaskQuery,
  useLazyGetProjectAppTaskQuery,
  useDeleteProjectAppTaskMutation,
  usePostProjectAppTaskMutation,
} = projectAppApi;
