import * as Yup from 'yup';

const userAddSchemaValidate = Yup.object().shape(
  {
    name: Yup.string().required('Pole jest wymagane'),
    central: Yup.string(),
    type: Yup.string().required('Pole jest wymagane'),
    status: Yup.string().required('Pole jest wymagane'),
    www: Yup.string(),
    mail: Yup.string().email('Invalid email'),
    street: Yup.string(),
    postCode: Yup.string(),
    city: Yup.string(),
    province: Yup.string(),
    country: Yup.string(),
    industry: Yup.string(),
    group: Yup.string(),
    promising: Yup.string(),
    level: Yup.string(),
    phone2: Yup.number(),

    phone1: Yup.number().when('phone2', {
      is: (phone2) => !phone2,
      then: Yup.number().required('Field is required'),
      otherwise: Yup.number(),
    }),

    nameSurname: Yup.string().when(['phone', 'email', 'position'], {
      is: (phone, email, position) => phone || email || position,
      then: Yup.string().required('Pole jest wymagane'),
    }),

    phone: Yup.number().when('nameSurname', {
      is: (nameSurname) => nameSurname,
      then: Yup.number().required('Pole jest wymagane'),
    }),
  },
  [['nameSurname', 'phone']],
);

export default userAddSchemaValidate;
