export const theme = {
  accentColor: '#498FE2',
  accentColorHover: '#307cd7',
  warrningColor: '#CA3131',
  greenColor: '#13AA22',
  textColor: '#414141',
  textColorLight: '#9E9E9E',
  textColorMedium: '#666666',
  lightBackground: '#E5E5E5',
  lightBlueBackground: '#f3f7ff',
};

export const test = {
  test: 'test',
};
