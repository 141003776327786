import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SearchSVG from 'components/atoms/SVG/SearchSVG/SearchSVG';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { usePopup } from 'hooks/usePopup';
import { useDebounce } from 'hooks/useDebounce';
import { v4 as uuidv4 } from 'uuid';
import LoaderSrc from 'assets/btnLoaderBlue.svg';

const StyledTable = styled.div`
  display: ${({ isactive }) => (isactive ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  transform: translateY(10px);
  z-index: 2;
  position: absolute;
  width: 100%;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  max-height: 250px;
  overflow-y: auto;
`;

const StyledInput = styled.input`
  border: 0;
  background: ${({ theme }) => theme.lightBlueBackground};
  padding: 4px 28px 4px 14px;
  border-radius: 16px;
  font-size: 14px;
  width: 100%;
  outline: none;
`;

const StyledWrapper = styled.label`
  position: relative;
  width: 100%;
  display: block;
  z-index: 50;

  svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
`;

const StyledLabel = styled.label`
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;
  &:hover {
    background: ${({ theme }) => theme.lightBackground};
  }
`;

const SearchInput = ({ name, placeholder, autoComplete, value, className }) => {
  const [searchInput, setSearchInput] = useState(value || '');
  const [isActive, setActive] = useState(false);
  const { setFieldValue } = useFormikContext();

  const debouncedSearchValue = useDebounce(searchInput, 200);

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.value.length > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    setFieldValue(name, debouncedSearchValue);
  }, [debouncedSearchValue]);

  const popupRef = useRef(null);
  usePopup(popupRef, setActive);

  return (
    <StyledWrapper htmlFor="searchInput" ref={popupRef} className={className}>
      <StyledInput
        name={name}
        id="searchInput"
        type="text"
        onChange={handleChange}
        value={searchInput}
        autoComplete="off"
        placeholder={placeholder}
      />
      <SearchSVG />
      {autoComplete?.length > 0 && (
        <StyledTable isactive={isActive}>
          {autoComplete.map((item) => (
            <StyledLabel
              key={uuidv4()}
              htmlFor={`${item}`}
              onClick={() => {
                setActive(false);
                setSearchInput(item);
                setFieldValue(name, item);
              }}
            >
              {item}
            </StyledLabel>
          ))}
        </StyledTable>
      )}
    </StyledWrapper>
  );
};

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SearchInput;
