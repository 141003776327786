import React from 'react';

const InvestmentSVG = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.72206 9.27779C8.69061 9.27098 8.65834 9.26975 8.62648 9.27452C8.30719 9.23735 8.06633 8.96721 8.06578 8.64588C8.06578 8.49189 7.94093 8.36703 7.78693 8.36703C7.63294 8.36703 7.50809 8.49189 7.50809 8.64588C7.5089 9.18248 7.86781 9.65249 8.38521 9.7945V10.0734C8.38521 10.2273 8.51006 10.3522 8.66406 10.3522C8.81805 10.3522 8.94291 10.2273 8.94291 10.0734V9.81139C9.54009 9.68639 9.94597 9.13019 9.8832 8.52334C9.8203 7.91649 9.30903 7.45533 8.69891 7.45519C8.34926 7.45519 8.06578 7.17171 8.06578 6.82206C8.06578 6.47241 8.34926 6.18893 8.69891 6.18893C9.04856 6.18893 9.33204 6.47241 9.33204 6.82206C9.33204 6.97606 9.4569 7.10091 9.61089 7.10091C9.76488 7.10091 9.88974 6.97606 9.88974 6.82206C9.88892 6.25865 9.4942 5.7727 8.94291 5.65642V5.40263C8.94291 5.24864 8.81805 5.12378 8.66406 5.12378C8.51006 5.12378 8.38521 5.24864 8.38521 5.40263V5.67344C7.80981 5.83057 7.43946 6.38922 7.51871 6.98041C7.59795 7.57161 8.10241 8.01289 8.69891 8.01275C9.04393 8.01275 9.32537 8.28902 9.33163 8.6339C9.33803 8.97879 9.06681 9.26526 8.72206 9.27779Z"
      fill="#414141"
    />
    <path
      d="M4.65521 7.7555C4.65521 9.98874 6.46555 11.7991 8.69879 11.7991C10.932 11.7991 12.7424 9.98874 12.7424 7.7555C12.7424 5.52225 10.932 3.71191 8.69879 3.71191C6.46664 3.71436 4.6578 5.52334 4.65521 7.7555ZM8.69879 4.26961C10.624 4.26961 12.1847 5.83024 12.1847 7.7555C12.1847 9.68075 10.624 11.2414 8.69879 11.2414C6.77354 11.2414 5.21291 9.68075 5.21291 7.7555C5.21509 5.83119 6.77449 4.27179 8.69879 4.26961Z"
      fill="#414141"
    />
    <path
      d="M14.1171 12.4865L11.9328 13.5186C11.6312 12.9576 11.0527 12.6006 10.416 12.5826L8.39951 12.5272C8.08022 12.5181 7.76692 12.4372 7.48304 12.2906L7.27785 12.1842C6.22726 11.6366 4.97503 11.638 3.92553 12.1878L3.93833 11.7229C3.94255 11.5689 3.82123 11.4407 3.66724 11.4365L1.45606 11.3756C1.30206 11.3714 1.17394 11.4927 1.16972 11.6467L1.03615 16.499C1.03193 16.653 1.15324 16.7813 1.30724 16.7855L3.51842 16.8464H3.52605C3.67718 16.8464 3.80081 16.7261 3.8049 16.5752L3.8113 16.343L4.38574 16.0354C4.61122 15.9141 4.87482 15.8852 5.12112 15.955L8.55146 16.9177C8.55745 16.9195 8.56344 16.9209 8.56957 16.9222C8.81751 16.9741 9.07021 17.0001 9.32347 17C9.85992 17.0002 10.3901 16.8846 10.8777 16.6608C10.8897 16.6553 10.9012 16.6489 10.9123 16.6417L15.886 13.4246C16.0099 13.3444 16.0499 13.1817 15.9773 13.0532C15.6081 12.398 14.7891 12.1484 14.1171 12.4865ZM1.60133 16.2357L1.71952 11.9408L3.37314 11.9863L3.25496 16.2813L1.60133 16.2357ZM10.6265 16.1627C10.0207 16.4361 9.34416 16.5116 8.69306 16.3785L5.27199 15.4182C4.88693 15.3094 4.47479 15.3545 4.12255 15.5439L3.829 15.7012L3.90756 12.8455C4.83778 12.2268 6.03079 12.1633 7.02146 12.6794L7.22665 12.7858C7.58529 12.9709 7.98082 13.0731 8.38426 13.0848L10.4009 13.1402C10.9673 13.1565 11.4483 13.5598 11.5632 14.1148L8.55949 14.0319C8.4055 14.0277 8.27737 14.1491 8.27302 14.303C8.2688 14.457 8.39025 14.5852 8.5441 14.5894L11.859 14.6807H11.8667C12.0177 14.6805 12.1412 14.5603 12.1453 14.4094C12.1487 14.2883 12.1397 14.1669 12.1184 14.0477L14.3588 12.9891C14.361 12.9881 14.3632 12.987 14.3654 12.9859C14.6831 12.8251 15.0674 12.8789 15.3288 13.1209L10.6265 16.1627Z"
      fill="#414141"
    />
    <path
      d="M8.97762 2.92791V0.278849C8.97762 0.124856 8.85277 0 8.69877 0C8.54478 0 8.41992 0.124856 8.41992 0.278849V2.92791C8.41992 3.0819 8.54478 3.20676 8.69877 3.20676C8.85277 3.20676 8.97762 3.0819 8.97762 2.92791Z"
      fill="#414141"
    />
    <path
      d="M11.0689 2.92796V1.67314C11.0689 1.51914 10.944 1.39429 10.79 1.39429C10.6361 1.39429 10.5112 1.51914 10.5112 1.67314V2.92796C10.5112 3.08195 10.6361 3.20681 10.79 3.20681C10.944 3.20681 11.0689 3.08195 11.0689 2.92796Z"
      fill="#414141"
    />
    <path
      d="M6.88631 2.92796V1.67314C6.88631 1.51914 6.76146 1.39429 6.60746 1.39429C6.45347 1.39429 6.32861 1.51914 6.32861 1.67314V2.92796C6.32861 3.08195 6.45347 3.20681 6.60746 3.20681C6.76146 3.20681 6.88631 3.08195 6.88631 2.92796Z"
      fill="#414141"
    />
  </svg>
);

export default InvestmentSVG;
