/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import PopupHeader from 'components/molecules/PopupHeader/PopupHeader';
import { useLazyGetTaskQuery } from 'redux/services/taskApi';
import Loader from 'components/atoms/Loader/Loader';
import { useLazyGetEmailTaskQuery } from 'redux/services/TaskKind/emailApi';
import { useLazyGetMeetingTaskQuery } from 'redux/services/TaskKind/meetingApi';
import { useLazyGetPhoneTaskQuery } from 'redux/services/TaskKind/phoneApi';
import { useLazyGetProjectAppTaskQuery } from 'redux/services/TaskKind/projectAppApi';
import { useLazyGetOfferTaskQuery } from 'redux/services/TaskKind/offerApi';
import { useLazyGetServiceTaskQuery } from 'redux/services/TaskKind/serviceTaskApi';
import { useLazyGetLogisticTaskQuery } from 'redux/services/TaskKind/logisticApi';
import { useLazyGetProjectServiceTaskQuery } from 'redux/services/TaskKind/projectServiceApi';
import FormCalendarTask from './FormCalendarTask';

const PopupCalendarTask = ({ data, handlePopup }) => {
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [activeEdit, setActiveEdit] = useState(false);

  const [trigger, { data: taskData }] = useLazyGetTaskQuery();
  useEffect(() => {
    if (data?.informations?.id || data?.objectId || data?.data?.objectId) {
      trigger(data?.informations?.id || data.objectId || data?.data?.objectId);
    } else if (data?.id) {
      trigger(data.id);
    }
  }, []);

  const task = {
    ...data,
    ...taskData,
    isDuplicated,
    setIsDuplicated,
    activeEdit,
    setActiveEdit,
  };

  const [type, setType] = useState(data.taskType);
  const [typeKind, setTypeKind] = useState([]);

  const [getEmailTask] = useLazyGetEmailTaskQuery();
  const [getMeetingTask] = useLazyGetMeetingTaskQuery();
  const [getPhoneTask] = useLazyGetPhoneTaskQuery();
  const [getProjectTask] = useLazyGetProjectAppTaskQuery();
  const [getOfferTask] = useLazyGetOfferTaskQuery();
  const [getServiceTask] = useLazyGetServiceTaskQuery();
  const [getLogisticTask] = useLazyGetLogisticTaskQuery();
  const [getProjectServiceTask] = useLazyGetProjectServiceTaskQuery();

  useEffect(async () => {
    switch (type) {
      case 'EMAIL':
        const { data: userData } = await getEmailTask();
        return setTypeKind(userData);
      case 'MEETING':
        const { data: meetingData } = await getMeetingTask();
        return setTypeKind(meetingData);
      case 'PHONE':
        const { data: phoneData } = await getPhoneTask();
        return setTypeKind(phoneData);
      case 'PROJECT_APPLICATION':
        const { data: projectData } = await getProjectTask();
        return setTypeKind(projectData);
      case 'OFFER':
        const { data: offerData } = await getOfferTask();
        return setTypeKind(offerData);

      case 'SERVICE_APPLICATION':
        const { data: serviceData } = await getServiceTask();
        return setTypeKind(serviceData);

      case 'LOGISTICS':
        const { data: logisticData } = await getLogisticTask();
        return setTypeKind(logisticData);

      case 'PROJECT_SERVICE':
        const { data: projectServiceData } = await getProjectServiceTask();
        return setTypeKind(projectServiceData);

      default:
        return [];
    }
  }, [type]);

  return (
    <div>
      <PopupHeader>
        {activeEdit && !isDuplicated
          ? 'Edytuj zadanie'
          : isDuplicated
          ? 'Dodaj zadanie'
          : 'Dane podstawowe o zadaniu'}
      </PopupHeader>

      {data?.informations?.id || data?.objectId || data?.id || data?.data?.objectId ? (
        <>
          {taskData ? (
            <FormCalendarTask
              data={task}
              handlePopup={handlePopup}
              typeKind={typeKind}
              setType={setType}
              type={type}
              permission={data.permission}
            />
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <FormCalendarTask data={[]} handlePopup={handlePopup} />
      )}
    </div>
  );
};

export default PopupCalendarTask;
