import React from 'react';
import styled from 'styled-components';
import SimilarTextInput from 'components/molecules/SimilarTextInput/SimilarTextInput';
import CustomSelect from 'components/molecules/CustomSelect/CustomSelect';
import { enums } from 'staticData/enums';
import MultiSelect from 'components/molecules/MultiSelect/MultiSelect';
import { useFormikContext } from 'formik';
import { useGetClientStatusQuery } from 'redux/services/clientStatusApi';
import { useGetIndustryQuery } from 'redux/services/industryApi';
import { useGetGroupQuery } from 'redux/services/groupApi';
import { useGetShoppingGroupQuery } from 'redux/services/shoppingGroupApi';
import { useGetRegionQuery, useGetUserPositionQuery } from 'redux/services/usersApi';
import { useGetProvinceQuery } from 'redux/services/provinceApi';
import SelectComponent from 'components/molecules/SelectComponent/SelectComponent';
import { handleNewField } from 'helpers/formsHelpers';
import MultiSelectComponent from 'components/molecules/MultiSelectComponent/MultiSelectComponent';

const StyledFormColumn = styled.div`
  width: calc(50% - 30px);
`;

const StyledFieldForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
`;

const StyledCustomSelectWrapper = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(100% - 195px)')};
`;

const StyledLabel = styled.span`
  width: 195px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColorMedium};
`;

const StyledSimilarInput = styled(SimilarTextInput)``;
const StyledWrapper = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TabContentBasicData = ({ values, errors, data }) => {
  const { data: provinceData } = useGetProvinceQuery();
  const { data: clientStatusData } = useGetClientStatusQuery();
  const { data: regionData } = useGetRegionQuery();
  const { data: userPosition } = useGetUserPositionQuery(['TRADER']);
  const { data: industryData } = useGetIndustryQuery();
  const { data: groupData } = useGetGroupQuery();
  const { data: shoppingGroupData } = useGetShoppingGroupQuery();

  const formikProps = useFormikContext();
  const { setFieldValue } = formikProps;

  return (
    <StyledWrapper>
      <StyledFormColumn>
        <StyledFieldForm>
          <StyledLabel>Dane podstawowe</StyledLabel>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>*Nazwa</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="name" value={values.name} error={errors.name} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>*Typ</StyledLabel>
          <StyledCustomSelectWrapper>
            <SelectComponent
              value={values.type}
              data={handleNewField(enums.types)}
              fullWidth
              error={errors.type}
              onChange={(e) => {
                setFieldValue('type', e.value);
              }}
              isClearable
            />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Centrala</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="central" value={values.central} error={errors.central} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>*Status</StyledLabel>
          <StyledCustomSelectWrapper>
            <SelectComponent
              value={values.status}
              data={handleNewField(clientStatusData)}
              fullWidth
              error={errors.status}
              onChange={(e) => {
                setFieldValue('status', e.value);
              }}
              isClearable
            />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Obsługujący</StyledLabel>
          <StyledCustomSelectWrapper>
            <MultiSelectComponent
              value={values.traders}
              data={handleNewField(userPosition)}
              fullWidth
              error={errors.traders}
              onChange={(e) => setFieldValue('traders', e)}
              isClearable
            />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>*Telefon 1</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="phone1" value={values.phone1} error={errors.phone1} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Telefon 2</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="phone2" value={values.phone2} error={errors.phone2} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>WWW</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="www" value={values.www} error={errors.www} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>E-mail</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="mail" value={values.mail} error={errors.mail} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
      </StyledFormColumn>

      <StyledFormColumn>
        <StyledFieldForm>
          <StyledLabel>Dane adresowe</StyledLabel>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Ulica</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="street" value={values.street} error={errors.street} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Kod pocztowy</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="postCode" value={values.postCode} error={errors.postCode} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Miasto</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="city" value={values.city} error={errors.city} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Wojewódźtwo</StyledLabel>
          <StyledCustomSelectWrapper>
            <SelectComponent
              value={values.province}
              data={handleNewField(provinceData)}
              fullWidth
              error={errors.province}
              onChange={(e) => {
                setFieldValue('province', e.value);
              }}
              isClearable
            />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Państwo</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="country" value={values.country} error={errors.country} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel className="mt-5">Dane kontaktowe</StyledLabel>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Imię i nazwisko</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput
              name="nameSurname"
              value={values.nameSurname}
              error={errors.nameSurname}
            />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>

        <StyledFieldForm>
          <StyledLabel>Telefon</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="phone" value={values.phone} error={errors.phone} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>E-mail</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="email" value={values.email} error={errors.email} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Stanowisko</StyledLabel>
          <StyledCustomSelectWrapper>
            <StyledSimilarInput name="position" value={values.position} error={errors.position} />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
      </StyledFormColumn>
      <StyledFormColumn>
        <StyledFieldForm>
          <StyledLabel>Dane dodatkowe</StyledLabel>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Region</StyledLabel>
          <StyledCustomSelectWrapper>
            <MultiSelectComponent
              value={values.region}
              data={handleNewField(regionData)}
              fullWidth
              error={errors.region}
              onChange={(e) => setFieldValue('region', e)}
              isClearable
            />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Branża</StyledLabel>
          <StyledCustomSelectWrapper>
            <SelectComponent
              value={values.industry}
              data={handleNewField(industryData)}
              fullWidth
              error={errors.industry}
              onChange={(e) => {
                setFieldValue('industry', e.value);
              }}
              isClearable
            />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Poziom</StyledLabel>
          <StyledCustomSelectWrapper>
            <SelectComponent
              value={values.level}
              data={handleNewField(enums.level)}
              fullWidth
              error={errors.level}
              onChange={(e) => {
                setFieldValue('level', e.value);
              }}
              isClearable
            />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Grupa</StyledLabel>
          <StyledCustomSelectWrapper>
            <SelectComponent
              value={values.group}
              data={handleNewField(groupData)}
              fullWidth
              error={errors.group}
              onChange={(e) => {
                setFieldValue('group', e.value);
              }}
              isClearable
            />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Rokujący</StyledLabel>
          <StyledCustomSelectWrapper>
            <SelectComponent
              value={values.promising}
              data={handleNewField(enums.promising)}
              fullWidth
              error={errors.promising}
              onChange={(e) => {
                setFieldValue('promising', e.value);
              }}
              isClearable
            />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
        <StyledFieldForm>
          <StyledLabel>Sieć/Grupa zakupowa</StyledLabel>
          <StyledCustomSelectWrapper>
            <MultiSelectComponent
              value={values.shoppingGroup}
              data={handleNewField(shoppingGroupData)}
              fullWidth
              error={errors.shoppingGroup}
              onChange={(e) => setFieldValue('shoppingGroup', e)}
              isClearable
            />
          </StyledCustomSelectWrapper>
        </StyledFieldForm>
      </StyledFormColumn>
    </StyledWrapper>
  );
};

export default TabContentBasicData;
