import { createSlice } from '@reduxjs/toolkit';

export const investmentLocalizationSlice = createSlice({
  name: 'investmentLocalizationSlice',
  initialState: {
    data: [],
    isReady: false,
  },
  reducers: {
    setInvestmentLocalizationData: (state, action) => ({
      ...state,
      data: action.payload,
    }),
    setLocalization: (state, action) => ({
      ...state,
      isReady: action.payload,
    }),
  },
});

export const { setInvestmentLocalizationData, setLocalization } =
  investmentLocalizationSlice.actions;

export default investmentLocalizationSlice.reducer;
