import { createSlice } from '@reduxjs/toolkit';

export const teleconferenceUtils = createSlice({
  name: 'teleconferenceUtils',
  initialState: {
    data: [],
  },
  reducers: {
    setPagination: (state, action) => ({
      ...state,
      data: action.payload,
    }),
  },
});

export const { setPagination } = teleconferenceUtils.actions;

export default teleconferenceUtils.reducer;
