import { createSlice } from '@reduxjs/toolkit';

export const permissionSlice = createSlice({
  name: 'permissionSlice',
  initialState: {
    data: {},
  },
  reducers: {
    setPermissionData: (state, action) => ({
      ...state,
      data: action.payload,
    }),
  },
});

export const { setPermissionData } = permissionSlice.actions;

export default permissionSlice.reducer;
