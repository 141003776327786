import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  width: 23px;
  height: 23px;
  border: 1px solid ${({ theme }) => theme.accentColor};
  border-radius: 4px;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ tooltip }) =>
    tooltip.length > 0
      ? css`
          &:hover:after {
            content: '${tooltip}';
            position: absolute;
            top: -25px;
            left: 0;
            background: #fff;
            border: 1px solid ${({ theme }) => theme.textColorLight};
            color: ${({ theme }) => theme.textColorLight};
            padding: 3px 10px;
            font-size: 12px;
            border-radius: 4px;
            white-space: nowrap;
          }
        `
      : null};

  ${({ withoutBackground }) =>
    css`
      background: inherit;
      border: none;
    `}
`;

const IconButton = ({ icon, tooltip, withoutBackground, ...rest }) => (
  <StyledButton type="button" withoutBackground={withoutBackground} tooltip={tooltip} {...rest}>
    {icon}
  </StyledButton>
);

IconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  tooltip: PropTypes.string,
};

IconButton.defaultProps = {
  tooltip: '',
};

export default IconButton;
