import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import CheckIcon from 'components/atoms/CheckIcon/CheckIcon';

const StyledField = styled(Field)`
  opacity: 0;
  cursor: pointer;
  position: absolute;

  & + span {
    display: block;
    width: 18px;
    height: 18px;
    border: 1px solid ${({ accentcolor, disabled }) => (disabled ? '#9E9E9E' : accentcolor)};

    border-radius: 3px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      opacity: 0;
      path {
        fill: ${({ accentcolor, disabled }) => (disabled ? '#9E9E9E' : accentcolor)};
      }
    }
  }

  &:checked + span {
    svg {
      opacity: 1;
    }
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 'inherit'};
  margin-top: ${({ marginTop }) => marginTop || 'inherit'};
  ${({ error }) =>
    error
      ? css`
          color: ${({ theme }) => theme.warrningColor};
          & input + span {
            border: 1px solid ${({ theme }) => theme.warrningColor};
          }
        `
      : null}
`;

const Checkbox = ({
  marginBottom,
  name,
  label,
  valid,
  accentColor,
  marginTop,
  checked,
  disabled,
  ...rest
}) => {
  const { errors, touched } = valid;
  return (
    <StyledLabel
      htmlFor={name}
      marginBottom={marginBottom}
      marginTop={marginTop}
      error={touched && touched[name] && errors[name]}
      className={disabled ? 'cursor-auto' : 'cursor-pointer'}
    >
      <StyledField
        accentcolor={accentColor}
        disabled={disabled}
        checked={checked}
        type="checkbox"
        name={name}
        {...rest}
        id={name}
      />
      <span>
        <CheckIcon />
      </span>
      <span>{label}</span>
    </StyledLabel>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
  valid: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.string),
    touched: PropTypes.objectOf(PropTypes.bool),
  }),
  accentColor: PropTypes.string,
};

Checkbox.defaultProps = {
  marginBottom: '0rem',
  marginTop: '0rem',
  valid: {},
  accentColor: '#414141',
};

export default Checkbox;
