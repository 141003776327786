import React, { useState } from 'react';
import styled from 'styled-components';
import CalendarDropdown from 'components/molecules/CalendarDropdown/CalendarDropdown';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import CalendarSVG from '../SVG/CalendarSVG/CalendarSVG';

const StyledWrapper = styled.div`
  margin-right: 26px;
  position: relative;
  cursor: pointer;
`;

const CalendarHeaderNav = ({ isActive, setActive }) => {
  const { data: currUser } = useSelector((state) => state.userReducer);

  const workHours = {
    startWork: currUser?.userData?.startWork,
    endWork: currUser?.userData?.endWork,
  };

  return (
    <StyledWrapper>
      <button type="button" onClick={() => setActive(!isActive)}>
        <CalendarSVG />
      </button>
      <OutsideClickHandler
        onOutsideClick={(e) => {
          if (
            e.target.firstChild?.id === 'modal' ||
            e.srcElement?.offsetParent?.id === 'modal' ||
            e.srcElement?.parentElement?.id === 'modal' ||
            e.toElement?.parentElement.offsetParent?.id === 'modal' ||
            e.toElement?.offsetParent?.offsetParent?.id === 'modal' ||
            e.toElement?.offsetParent?.offsetParent?.offsetParent?.id === 'modal' ||
            e.toElement?.offsetParent?.offsetParent?.offsetParent?.offsetParent?.id === 'modal' ||
            e.toElement?.offsetParent?.offsetParent?.offsetParent?.offsetParent?.offsetParent
              ?.id === 'modal' ||
            e.toElement.offsetParent?.offsetParent?.offsetParent?.offsetParent?.offsetParent
              ?.offsetParent?.id === 'modal' ||
            e.toElement.nodeName === 'svg'
          ) {
            setActive((prev) => prev);
          } else {
            setActive(false);
          }
        }}
      >
        <CalendarDropdown isActive={isActive} setActive={setActive} workHours={workHours} />
      </OutsideClickHandler>
    </StyledWrapper>
  );
};

export default CalendarHeaderNav;
