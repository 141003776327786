import { phoneTask } from '../../../endpoints/endpoints';
import { getAuthorizationHeader } from '../getAuthorizationHeader';

import { apiSlice } from '../apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['PhoneTask'] });

export const offerApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getPhoneTask: builder.query({
      query: () => ({
        url: phoneTask,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['PhoneTask'],
    }),

    deletePhoneTask: builder.mutation({
      query: (id) => ({
        url: `${phoneTask}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['PhoneTask'],
    }),

    postPhoneTask: builder.mutation({
      query: (name) => ({
        url: phoneTask,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['PhoneTask'],
    }),
  }),
});

export const {
  useGetPhoneTaskQuery,
  useLazyGetPhoneTaskQuery,
  useDeletePhoneTaskMutation,
  usePostPhoneTaskMutation,
} = offerApi;
