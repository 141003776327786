export const enums = {
  activeData: [
    {
      id: 'true',
      name: 'tak',
    },
    {
      id: 'false',
      name: 'nie',
    },
  ],

  position: [
    {
      id: 'ADMINISTRATOR',
      name: 'Administrator',
    },
    {
      id: 'DIRECTOR_DZPS',
      name: 'Dyrektor pionu DZPS',
    },
    {
      id: 'DIRECTOR_DSD_DSS',
      name: 'Dyrektor działu sprzedaży (DSD/DSS)',
    },
    {
      id: 'MACROREGION_MANAGER',
      name: 'Kierownik Makroregionu DM',
    },
    {
      id: 'REGIONAL_MANAGER',
      name: 'Kierownik Regionu RSM',
    },
    {
      id: 'SELLER_KAM',
      name: 'Sprzedawca KAM',
    },
    {
      id: 'LOGISTICS_MANAGER',
      name: 'Kierownik logistyki',
    },
    {
      id: 'LOGISTICS',
      name: 'Logistyk SL',
    },
    {
      id: 'BIDDER',
      name: 'Oferent',
    },
    {
      id: 'MANAGER_KDSIR',
      name: 'Kierownik działu serwisu i reklamacji KDSiR',
    },
    {
      id: 'INVESTMENT_COORDINATOR',
      name: 'Koordynator inwestycji',
    },
    {
      id: 'OFFER_OFFICE_COORDINATOR',
      name: 'Koordynator Biura ofertowego',
    },
    {
      id: 'MOBILE_SERVICE_COORDINATOR',
      name: 'Koordynator serwisu mobilnego',
    },
    {
      id: 'TECHNICAL_SUPPORT_COORDINATOR',
      name: 'Koordynator biura wsparcia technicznego',
    },
    {
      id: 'PROJECT_COORDINATOR',
      name: 'Koordynator projektu',
    },
    {
      id: 'HEAD_OF_PROJECTS_DEPARTMENT',
      name: 'Kierownik działu projektów',
    },
    {
      id: 'DESIGNER',
      name: 'Projektant',
    },
    {
      id: 'TRADER',
      name: 'Handlowiec',
    },
  ],

  section: [
    {
      id: 'DSD',
      name: 'DSD - Dział Sprzedaży Dystrybucyjnej',
    },
    {
      id: 'DSS',
      name: 'DSS - Dział Sprzedaży Systemów',
    },
    {
      id: 'DP',
      name: 'Dział Projektów',
    },
    {
      id: 'DSIR',
      name: 'Dział Serwisu i Reklamacji',
    },
  ],

  department: [
    {
      id: 'DSS',
      name: 'DSS',
    },
    {
      id: 'DSD',
      name: 'DSD',
    },
    {
      id: 'DSIR',
      name: 'DSIR',
    },
    {
      id: 'DP',
      name: 'DP',
    },
    {
      id: 'CUSTOMER_SERVICE_OFFICE_DSD',
      name: 'Biuro obsługi klienta DSD',
    },
    {
      id: 'OFFER_TEAM_DSD',
      name: 'Zespół ofertowy DSD',
    },
    {
      id: 'LOGISTICS_OFFICE_DSS',
      name: 'Biuro logistyki DSS',
    },
    {
      id: 'OFFER_OFFICE_DP',
      name: 'Biuro ofert DP',
    },
    {
      id: 'MOBILE_SERVICE_OFFICE_DSIR',
      name: 'Biuro serwisu mobilnego DSiR',
    },
    {
      id: 'COMPLAINTS_OFFICE_DSIR',
      name: 'Biuro reklamacji DSiR',
    },
    {
      id: 'TECHNICAL_SUPPORT_OFFICE_DSIR',
      name: 'Biuro wsparcia technicznego DSiR',
    },
    {
      id: 'LOGISTICS_OFFICE_DSIR',
      name: 'Biuro logistyki DSiR',
    },
    {
      id: 'SALES_OFFICE_DSIR',
      name: 'Biuro sprzedaży DSiR',
    },
  ],

  promising: [
    {
      id: 'SINGLE',
      name: 'Jednorazowy - Single',
    },
    {
      id: 'LOW',
      name: 'Niski - Low',
    },
    {
      id: 'MEDIUM',
      name: 'Średni - Medium',
    },
    {
      id: 'HIGH',
      name: 'Wysoki - High',
    },
  ],

  province: [
    {
      id: 1,
      name: 'Dolnośląskie',
    },
    {
      id: 2,
      name: 'Kujawsko-pomorskie',
    },
    {
      id: 3,
      name: 'Lubelskie',
    },
    {
      id: 4,
      name: 'Lubuskie',
    },
    {
      id: 5,
      name: 'Łódzkie',
    },
    {
      id: 6,
      name: 'Małopolskie',
    },
    {
      id: 7,
      name: 'Mazowieckie',
    },
    {
      id: 8,
      name: 'Opolskie',
    },
    {
      id: 9,
      name: 'Podkarpackie',
    },
    {
      id: 10,
      name: 'Podlaskie',
    },
    {
      id: 11,
      name: 'Pomorskie',
    },
    {
      id: 12,
      name: 'Śląskie',
    },
    {
      id: 13,
      name: 'Świętokrzyskie',
    },
    {
      id: 14,
      name: 'Warmińsko-mazurskie',
    },
    {
      id: 15,
      name: 'Wielkopolskie',
    },
    {
      id: 16,
      name: 'Zachodniopomorskie',
    },
  ],

  levels: [
    {
      id: 'GOLD',
      name: 'Gold',
    },
    {
      id: 'SILVER',
      name: 'Silver',
    },
    {
      id: 'WHITE',
      name: 'White',
    },
  ],

  types: [
    {
      id: 'DESIGNER',
      name: 'Projektant',
    },
    {
      id: 'EXECUTOR',
      name: 'Wykonawca',
    },
    {
      id: 'DISTRIBUTOR',
      name: 'Dystrybutor',
    },
  ],

  typesEnums: {
    DESIGNER: 'Projektant',
    EXECUTOR: 'Wykonawca',
    DISTRIBUTOR: 'Dystrybutor',
  },

  investmentType: [
    {
      id: 'MAIN_INVESTMENT',
      name: 'Inwestycja główna',
    },
    {
      id: 'SUB_INVESTMENT',
      name: 'Inwestycja podrzędna',
    },
  ],

  template: [
    {
      id: 'DSD',
      name: 'DSD',
    },
    {
      id: 'DSS',
      name: 'DSS',
    },
    {
      id: 'DSE',
      name: 'DSE',
    },
  ],

  remindCoordinator: [
    {
      id: 'DOES_NOT_REMIND',
      name: 'Nie przypominaj',
    },
    {
      id: 'START_DAY',
      name: 'Dzień rozpoczęcia',
    },
    {
      id: 'DAY_BEFORE',
      name: 'Dzień przed datą rozpoczęcia',
    },
    {
      id: 'TWO_DAYS_BEFORE',
      name: '2 dni przed datą rozpoczęcia',
    },
    {
      id: 'THREE_DAYS_BEFORE',
      name: '3 dni przed datą rozpoczęcia',
    },
    {
      id: 'WEEK_BEFORE',
      name: 'Tydzień przed datą rozpoczęcia',
    },
    {
      id: 'AFTER_CLOSING',
      name: 'Po zamknięciu',
    },
    {
      id: 'DAY_BEFORE_END',
      name: 'Dzień przed datą zakończenia',
    },
    {
      id: 'TWO_DAYS_BEFORE_END',
      name: '2 dni przed datą zakończenia',
    },
    {
      id: 'THREE_DAYS_BEFORE_END',
      name: '3 dni przed datą zakończenia',
    },
    {
      id: 'WEEK_BEFORE_END',
      name: 'Tydzień przed datą zakończenia',
    },
  ],

  status: [
    {
      id: 'ACTIVE',
      name: 'Aktywna',
    },
    {
      id: 'INACTIVE',
      name: 'Nieaktywna',
    },
    {
      id: 'CLOSED_POSITIVE',
      name: 'Zamknięta pozytywnie',
    },
    {
      id: 'CLOSED_NEGATIVE',
      name: 'Zamknięta negatywnie',
    },
    {
      id: 'PAUSED_PROJECT',
      name: 'Wstrzymana',
    },
    {
      id: 'SALE',
      name: 'Sprzedaż',
    },
  ],

  statusEnum: {
    ACTIVE: 'Aktywna',
    INACTIVE: 'Nieaktywna',
    CLOSED_POSITIVE: 'Zamknięta pozytywnie',
    CLOSED_NEGATIVE: 'Zamknięta negatywnie',
    PAUSED: 'Wstrzymana',
    SALE: 'Sprzedaż',
  },

  investmentStatus: [
    {
      id: 'ACTIVE',
      name: 'Aktywna',
    },
    {
      id: 'INACTIVE',
      name: 'Nieaktywna',
    },
    {
      id: 'CLOSED_POSITIVE',
      name: 'Zamknięta pozytywnie',
    },
    {
      id: 'CLOSED_NEGATIVE',
      name: 'Zamknięta negatywnie',
    },
    {
      id: 'PAUSED',
      name: 'Wstrzymana',
    },
  ],

  serviceStatus: [
    {
      id: 'GUARANTEE',
      name: 'Obsługiwana serwisowo gwarancyjnie',
    },
    {
      id: 'WARRANTY',
      name: 'Obsługiwana serwisowo pogwarancyjnie',
    },
    {
      id: 'WARRANTY_CLOSED',
      name: 'Gwarancja zamknięta',
    },
  ],

  serviceStatusEnum: {
    GUARANTEE: 'Obsługiwana serwisowo gwarancyjnie',
    WARRANTY: 'Obsługiwana serwisowo pogwarancyjnie',
    WARRANTY_CLOSED: 'Gwarancja zamknięta',
  },

  systemType: [
    { name: 'Autonomiczne Premium', id: 'AUTOTEST' },
    { name: 'CBA (nieaktywne)', id: 'CBA' },
    { name: 'CBG (nieaktywne)', id: 'CBG' },
    { name: 'CBN (nieaktywne)', id: 'CBN' },
    { name: 'Centralna Bateria (CBS)', id: 'CBS_AWEX' },
    { name: 'CBSI (nieaktywne)', id: 'CBSI' },
    { name: 'Autonomiczne Eco', id: 'ECO' },
    { name: 'FAS AWEX', id: 'FAS_AWEX' },
    { name: 'FAS MINI AWEX', id: 'FAS_MINI_AWEX' },
    { name: 'FZLV (nieaktywne)', id: 'FZLV' },
    { name: 'FZLV MAX (nieaktywne)', id: 'FZLV_MAX' },
    { name: 'Centralna Bateria (LPS)', id: 'LPS' },
    { name: 'Moduł (nieaktywne)', id: 'MODULE' },
    { name: 'Rubic mini (nieaktywne)', id: 'RUBIC_MINI' },
    { name: 'Rubic SD (nieaktywne)', id: 'RUBIC_SD' },
    { name: 'Rubic TP-4000 (nieaktywne)', id: 'RUBIC_TP_4000' },
    { name: 'Rubic TP-650 (nieaktywne)', id: 'RUBIC_TP_650' },
    { name: 'Rubic Standard', id: 'RUBIC_UNA' },
    { name: 'Rubic UNA MINI (nieaktywne)', id: 'RUBIC_UNA_MINI' },
    { name: 'Autonomiczne Standard', id: 'STANDARD_DSK' },
    { name: 'SVS PREMIUM', id: 'SVS_PREMIUM' },
    { name: 'SVS STANDARD', id: 'SVS_STANDARD' },
    { name: 'Mieszany (CBS_FZLV) (nieaktywne)', id: 'MIXED_CBS_FZLV' },
    { name: 'Mieszany (CBS_RU) (nieaktywne)', id: 'MIXED_CBS_RU' },
    { name: 'Mieszany (FZLV_RU) (nieaktywne)', id: 'MIXED_FZLV_RU' },
    { name: 'Mieszany (UNA_CBS) (nieaktywne)', id: 'MIXED_UNA_CBS' },
    { name: 'Mieszany (UNA_FLZV) (nieaktywne)', id: 'MIXED_UNA_FLZV' },
    { name: 'Mieszany (OA_FAS) (nieaktywne)', id: 'MIXED_FAS' },
    { name: 'Mieszany (CBS_FAS) (nieaktywne)', id: 'MIXED_CBS_FAS' },
    { name: 'Mieszany (FZLV_FAS) (nieaktywne)', id: 'MIXED_FZLV_FAS' },
    { name: 'Mieszany (UNA_FAS) (nieaktywne)', id: 'MIXED_UNA_FAS' },
    { name: 'Oświetlenie podstawowe', id: 'OP' },
  ],

  systemSize: [
    {
      id: 'LESS_HUNDRED',
      name: 'do 100 napraw',
    },
    {
      id: 'LESS_FIVE_HUNDRED',
      name: '101 - 500 napraw',
    },
    {
      id: 'MORE_FIVE_HUNDRED',
      name: '501 - 1000 napraw',
    },
  ],

  objectType: [
    {
      id: 'OFFICE',
      name: 'Budynek biurowy',
    },
    {
      id: 'RESIDENTIAL',
      name: 'Budynek mieszkalny deweloperka',
    },
    {
      id: 'COMMUNICATION',
      name: 'Centra komunikacyjne Dworce',
    },
    {
      id: 'COMMERCIAL',
      name: 'Galeria handlowa Centrum handlowe',
    },
    {
      id: 'HOTEL',
      name: 'Hotel Pensjonat',
    },
    {
      id: 'WAREHOUSE',
      name: 'Magazyn Hala magazynowa',
    },
    {
      id: 'MARKET',
      name: 'Market Sklep',
    },
    {
      id: 'SPORTY',
      name: 'Obiekt sportowy',
    },
    {
      id: 'MILITARY',
      name: 'Obiekt wojskowy',
    },
    {
      id: 'CULTURAL',
      name: 'Obiekty kulturalne',
    },
    {
      id: 'RESTAURANTS',
      name: 'Punkty gastronomiczne Restauracje',
    },
    {
      id: 'HEALTH',
      name: 'Służba zdrowia',
    },
    {
      id: 'EDUCATION',
      name: 'Szkolnictwo Edukacja',
    },
    {
      id: 'INDUSTRIAL',
      name: 'Zakład przemysłowy',
    },
  ],

  objectKind: [
    {
      id: 'NEW_OBJECT',
      name: 'Nowy obiekt',
    },
    {
      id: 'ARRANGEMENT',
      name: 'Nowy obiekt - aranżacja',
    },
    {
      id: 'REVITALIZATION',
      name: 'Rewitalizacja',
    },
    {
      id: 'RE_REVITALIZATION',
      name: 'Rewitalizacja/sprzedaż powtórna AWEX(DSS)',
    },
  ],

  projectType: [
    {
      id: 'BUILDING',
      name: 'Projekt budowlany urzędowy',
    },
    {
      id: 'INVESTOR',
      name: 'Projekt wykonawczy inwestor',
    },
    {
      id: 'TO_REALIZATION',
      name: 'Projekt wykonawczy do realizacji',
    },
    {
      id: 'OTHER',
      name: 'Inne',
    },
  ],

  businessLines: [
    {
      id: 'FAS_DSS',
      name: 'FAS DSS (0',
    },
    {
      id: 'STANDARD_DSD',
      name: 'STANDARD DSD (0',
    },
    {
      id: 'STANDARD_DSS',
      name: 'STANDARD DSS (0',
    },
    {
      id: 'TEMPLATE_DSZ',
      name: 'WZORZEC DSZ (0',
    },
  ],

  salesOpportunity: [
    {
      id: 'MORE_ZERO',
      name: '0-25%',
    },
    {
      id: 'LESS_HALF',
      name: '25-50%',
    },
    {
      id: 'MORE_HALF',
      name: '50-75%',
    },
    {
      id: 'MORE_THREE_QUARTERS',
      name: '75-90%',
    },
    {
      id: 'ALMOST_HUNDRED',
      name: '90-100%',
    },
  ],

  salesOpportunityBarEnums: {
    MORE_ZERO: 25,
    LESS_HALF: 40,
    MORE_HALF: 60,
    MORE_THREE_QUARTERS: 80,
    ALMOST_HUNDRED: 100,
  },

  quarter: [
    {
      id: 'I',
      name: 'I',
    },
    {
      id: 'II',
      name: 'II',
    },
    {
      id: 'III',
      name: 'III',
    },
    {
      id: 'IV',
      name: 'IV',
    },
  ],

  year: [
    {
      id: '2022',
      name: '2022',
    },
    {
      id: '2023',
      name: '2023',
    },
    {
      id: '2024',
      name: '2024',
    },
    {
      id: '2025',
      name: '2025',
    },
    {
      id: '2026',
      name: '2026',
    },
    {
      id: '2027',
      name: '2027',
    },
    {
      id: '2028',
      name: '2028',
    },
    {
      id: '2029',
      name: '2029',
    },
    {
      id: '2030',
      name: '2030',
    },
    {
      id: '2031',
      name: '2031',
    },
    {
      id: '2032',
      name: '2032',
    },
  ],

  blockade: [
    {
      id: false,
      name: 'Nieaktywna',
    },
    {
      id: true,
      name: 'aktywna',
    },
  ],

  taskKind: [
    {
      id: 'MEETING',
      name: 'Spotkanie',
    },
    {
      id: 'PHONE',
      name: 'Telefon',
    },
    {
      id: 'PROJECT_APPLICATION',
      name: 'Zgłoszenie projektowe',
    },
    {
      id: 'OFFER',
      name: 'Oferta',
    },
    {
      id: 'EMAIL',
      name: 'Email',
    },
    {
      id: 'SERVICE_APPLICATION',
      name: 'Zgłoszenie serwisowe',
    },
    {
      id: 'LOGISTICS',
      name: 'Logistyka',
    },
    {
      id: 'PROJECT_SERVICE',
      name: 'Projekt serwis',
    },
  ],

  taskKindEnum: {
    MEETING: 'Spotkanie',
    PHONE: 'Telefon',
    PROJECT_APPLICATION: 'Zgłoszenie projektowe',
    OFFER: 'Oferta',
    EMAIL: 'Email',
    SERVICE_APPLICATION: 'Zgłoszenie serwisowe',
    LOGISTICS: 'Logistyka',
    PROJECT_SERVICE: 'Projekt serwis',
  },

  series: [
    {
      id: 'DAY',
      name: 'Cykl dzienny',
    },
    {
      id: 'WEEK',
      name: 'Cykl tygodniowy',
    },
    {
      id: 'TWO_WEEKS',
      name: 'Cykl 2 tygodniowy',
    },
    {
      id: 'THREE_WEEKS',
      name: 'Cykl 3 tygodniowy',
    },
    {
      id: 'FOUR_WEEKS',
      name: 'Cykl 4 tygodniowy',
    },
    {
      id: 'MONTH',
      name: 'Cykl miesięczny',
    },
    {
      id: 'QUARTER',
      name: 'Cykl kwartalny',
    },
    {
      id: 'HALF_YEAR',
      name: 'Cykl półroczny',
    },
    {
      id: 'YEAR',
      name: 'Cykl roczny',
    },
    {
      id: 'MULTI_USER',
      name: 'Cykl wieloużytkownikowy',
    },
  ],

  priority: [
    {
      id: 'STANDARD',
      name: 'Standardowy',
    },
    {
      id: 'IMPORTANT',
      name: 'Ważny',
    },
    {
      id: 'VERY',
      name: 'Bardzo ważny',
    },
  ],

  remindProducer: [
    {
      id: 'DOES_NOT_REMIND',
      name: 'Nie przypominaj',
    },
    {
      id: 'FIFTEEN',
      name: '15 minut przed datą',
    },
    {
      id: 'THIRTY',
      name: '30 minut przed datą',
    },
    {
      id: 'HOUR',
      name: 'Godzinę przed datą',
    },
    {
      id: 'TWO_HOURS',
      name: '2 godziny przed datą',
    },
    {
      id: 'FOUR_HOURS',
      name: '4 godziny przed datą',
    },
    {
      id: 'DAY',
      name: '1 dzień przed datą',
    },
    {
      id: 'TWO_DAYS',
      name: '2 dni przed datą',
    },
    {
      id: 'TREE_DAYS',
      name: '3 dni przed datą',
    },
    {
      id: 'WEEK',
      name: 'Tydzień przed datą',
    },
  ],

  remindDelegator: [
    {
      id: 'DONT_TELL',
      name: 'Nie powiadamaj',
    },
    {
      id: 'TASK_COMPLETION',
      name: 'Zakończenie zadania',
    },
    {
      id: 'END_DELAY',
      name: 'Zakończenie/Opóżnienie zadania',
    },
    {
      id: 'COMPLETION_RECEIPT',
      name: 'Zakończenie/Odbiór zadania',
    },
  ],

  taskStatus: [
    {
      id: 'ACCEPTED',
      name: 'Zaakceptowany',
    },
    {
      id: 'REJECTED',
      name: 'Odrzucony',
    },
    {
      id: 'CLOSED_POSITIVE',
      name: 'Zamknięty pozytywnie',
    },
    {
      id: 'CLOSED_NEGATIVE',
      name: 'Zamknięty negatywnie',
    },
  ],

  taskStatusEnums: {
    REJECTED: 'Odrzucony',
    ACCEPTED: 'Zaakceptowany',
    CLOSED_NEGATIVE: 'Zamknięty negatywnie',
    CLOSED_POSITIVE: 'Zamknięty pozytywnie',
  },

  rights: [
    {
      id: 'ALL',
      name: 'Wszyscy',
    },
    {
      id: 'ENTITLED_PERSON',
      name: 'Tylko osoba uprawniona',
    },
    {
      id: 'DIRECTOR_DP',
      name: 'Dyrektor DP',
    },
    {
      id: 'DIRECTOR_DSI',
      name: 'Dyrektor DSI',
    },
  ],

  rightsEnums: {
    ALL: 'Wszyscy',
    ENTITLED_PERSON: 'Tylko osoba uprawniona',
    DIRECTOR_DP: 'Dyrektor DP',
    DIRECTOR_DSI: 'Dyrektor DSI',
  },

  stageStatus: [
    {
      id: 'ACTIVE',
      name: 'Aktywny',
    },
    {
      id: 'INACTIVE',
      name: 'Nieaktywny',
    },
    {
      id: 'CLOSED_POSITIVE',
      name: 'Zamknięty pozytywnie',
    },
    {
      id: 'CLOSED_NEGATIVE',
      name: 'Zamknięty niegatywnie',
    },
  ],

  stageStatusEnum: {
    ACTIVE: 'Aktywna',
    INACTIVE: 'Nieaktywna',
    CLOSED_POSITIVE: 'Zamknięta pozytywnie',
    CLOSED_NEGATIVE: 'Zamknięta negatywnie',
  },

  warranty: [
    {
      id: 'STANDARD',
      name: 'Standard',
    },
    {
      id: 'EXTENDED_THIRTY',
      name: 'Rozszerzona 36 msc',
    },
    {
      id: 'EXTENDED_FORTY',
      name: 'Rozszerzona 48 msc',
    },
    {
      id: 'INDIVIDUAL',
      name: 'Indywidualna',
    },
  ],

  componentWarranty: [
    {
      id: 'NO',
      name: 'Nie',
    },
    {
      id: 'YES_TWELVE',
      name: 'Tak - Gwarancja 12 msc',
    },
    {
      id: 'YES_TWENTY',
      name: 'Tak - Gwarancja 24 msc',
    },
    {
      id: 'YES_THIRTY',
      name: 'Tak - Gwarancja 36 msc',
    },
    {
      id: 'YES_FORTY',
      name: 'Tak - Gwarancja 48 msc',
    },
    {
      id: 'YES_SIXTY',
      name: 'Tak - Gwarancja 60 msc',
    },
  ],

  warrantyStart: [
    {
      id: 'DELIVERY_DATES_FIRST',
      name: 'Od daty dostawy pierwszego elementu',
    },
    {
      id: 'MONTH',
      name: 'Niestandardowo - 1 msc od daty dostawy',
    },
    {
      id: 'TWO_MONTHS',
      name: 'Niestandardowo - 2 msc od daty dostawy',
    },
    {
      id: 'THREE_MONTHS',
      name: 'Niestandardowo - 3 msc od daty dostawy',
    },
    {
      id: 'FOUR_MONTHS',
      name: 'Niestandardowo - 4 msc od daty dostawy',
    },
    {
      id: 'FIVE_MONTHS',
      name: 'Niestandardowo - 5 msc od daty dostawy',
    },
  ],

  inspection: [
    {
      id: 'YES',
      name: 'Tak',
    },
    {
      id: 'NO',
      name: 'Nie',
    },
    {
      id: 'INDIVIDUAL',
      name: 'Indywidualna',
    },
  ],

  warrantyRemind: [
    {
      id: 'DAY',
      name: 'dzień przed',
    },
    {
      id: 'WEEK',
      name: 'tydzień przed',
    },
    {
      id: 'TWO_WEEKS',
      name: 'dwa tygodnie przed',
    },
    {
      id: 'MONTH',
      name: 'miesiąc przed',
    },
  ],

  absenceType: [
    {
      id: 'VACATION',
      name: 'URLOP',
    },
    {
      id: 'DELEGATION',
      name: 'DELEGACJA',
    },
    {
      id: 'SICK_LEAVE',
      name: 'ZWOLNIENIE LEKARSKIE',
    },
    {
      id: 'OTHER',
      name: 'INNE',
    },
  ],

  absenceTypeEnum: {
    VACATION: 'URLOP',
    DELEGATION: 'DELEGACJA',
    SICK_LEAVE: 'ZWOLNIENIE LEKARSKIE',
    OTHER: 'INNE',
  },

  calendarEventStatus: [
    {
      id: 'PLANNED',
      name: 'ZAPLANOWANA',
    },
    {
      id: 'IN_PROGRESS',
      name: 'TRWAJĄCA',
    },
    {
      id: 'FOR_ACCEPTANCE',
      name: 'DO AKCEPTACJI',
    },
    {
      id: 'ENDED',
      name: 'ZAKOŃCZONA',
    },
    {
      id: 'REJECTED',
      name: 'ODRZUCONE',
    },
  ],

  calendarEventStatusEnum: {
    PLANNED: 'ZAPLANOWANA',
    IN_PROGRESS: 'TRWAJĄCA',
    FOR_ACCEPTANCE: 'DO AKCEPTACJI',
    ENDED: 'ZAKOŃCZONA',
    REJECTED: 'ODRZUCONE',
  },

  calendarTypeEnum: {
    TASK: 'Zadanie',
    INVESTMENT: 'Inwestycja',
    ABSENCE: 'Nieobecność',
  },

  calendarTaskType: [
    {
      id: 'TASK_EMAIL',
      name: 'Email',
    },
    {
      id: 'TASK_PHONE',
      name: 'Telefon',
    },
    {
      id: 'TASK_MEETING',
      name: 'Spotkanie',
    },
    {
      id: 'TASK_OFFER',
      name: 'Oferta',
    },
    {
      id: 'TASK_PROJECT_APPLICATION',
      name: 'Zgłoszenie projektowe',
    },
    {
      id: 'TASK_SERVICE_APPLICATION',
      name: 'Zgłoszenie serwisowe',
    },
  ],

  contractorType: [
    {
      id: 'DESIGNER',
      name: 'Projektant',
    },
    {
      id: 'EXECUTOR',
      name: 'Wykonawca',
    },
    {
      id: 'DISTRIBUTOR',
      name: 'Dystrybutor',
    },
  ],

  calendarInvestmentType: [
    {
      id: 'INVESTMENT_MAIN',
      name: 'Inwestycja główna',
    },
    {
      id: 'INVESTMENT_SUB',
      name: 'Inwestycja podrzędna',
    },
  ],

  calendarAbsenceType: [
    {
      id: 'ABSENCE_VACATION',
      name: 'Urlop',
    },
    {
      id: 'ABSENCE_DELEGATION',
      name: 'Delegacja',
    },
    {
      id: 'ABSENCE_SICK_LEAVE',
      name: 'Zwolnienie lekarskie',
    },
  ],

  genderType: [
    {
      id: 'MALE',
      name: 'Mężczyzna',
    },
    {
      id: 'FEMALE',
      name: 'Kobieta',
    },
  ],

  ageRangeType: [
    {
      id: 'TO19',
      name: '..-19',
    },
    {
      id: 'A20to24',
      name: '20-24',
    },
    {
      id: 'A25to29',
      name: '25-29',
    },
    {
      id: 'A30to34',
      name: '30-34',
    },
    {
      id: 'A35to39',
      name: '35-39',
    },
    {
      id: 'A40to44',
      name: '40-44',
    },
    {
      id: 'A45to49',
      name: '45-49',
    },
    {
      id: 'A50to54',
      name: '50-54',
    },
    {
      id: 'A55to59',
      name: '55-59',
    },
    {
      id: 'A60PLUS',
      name: '60-..',
    },
  ],

  responsibleFor: [
    { id: 'BUSINESS', name: 'Business' },

    { id: 'FINANCE', name: 'Finanse' },

    { id: 'MARKETING', name: 'Marketing' },

    { id: 'OFFERS', name: 'Oferty' },

    { id: 'PROJECTS', name: 'Projekty' },
    { id: 'TECHNICAL', name: 'Sprawy techniczne' },

    { id: 'OWNER', name: 'Właściciel' },

    { id: 'ORDERS', name: 'Zamówienia' },
  ],

  CommunicationChannelType: [
    { id: 'PHONE', name: 'Telefon' },
    { id: 'MAIL', name: 'e-mail' },
    { id: 'FAX', name: 'Faks' },
    { id: 'SKYPE', name: 'Skype' },
    { id: 'MS_TEAMS', name: 'Microsoft Teams' },
    { id: 'MEETING', name: 'Spotkanie' },
  ],

  vipType: [
    { id: 'P', name: 'P' },
    { id: 'IP', name: 'IP' },
    { id: 'VIP', name: 'VIP' },
  ],

  monthsEnum: {
    1: 'styczeń',
    2: 'luty',
    3: 'marzec',
    4: 'kwiecień',
    5: 'maj',
    6: 'czerwiec',
    7: 'lipiec',
    8: 'sierpień',
    9: 'wrzesień',
    10: 'październik',
    11: 'listopad',
    12: 'grudzień',
  },

  quarterEnum: {
    1: 'I kwartał',
    2: 'II kwartał',
    3: 'III kwartał',
    4: 'IV kwartał',
  },

  yearEnum: [
    { id: '2021', name: '2021' },
    { id: '2022', name: '2022' },
    { id: '2023', name: '2023' },
  ],

  top10investments: [
    { id: 'investment', name: 'Inwestycje' },
    { id: 'DSD&DSS', name: 'Top 10 handlowców DSD i DSS' },
    { id: 'DSS', name: 'Top 10 inwestycje DSS' },
    { id: 'DSD', name: 'Top 10 inwestycje DSD' },
  ],

  top10traders: [
    { id: 'traders', name: 'Handlowycy' },
    { id: 'DSD&DSS', name: 'Top 10 handlowców DSD i DSS' },
    { id: 'DSS', name: 'Top 10 handlowców DSS' },
    { id: 'DSD', name: 'Top 10 handlowców DSD' },
  ],

  fileTypesEnums: {
    EMPLOYEE_CONTRACT: 'employee_contract',
    AVATAR: 'avatar',
    TASK: 'task',
    NOTE: 'note',
    OFFER: 'offer',
    CONTRACT_TEMPLATES: 'contract_templates',
    ADVERTISING_MATERIALS: 'advertising_materials',
    PREPARATION_FILES: 'preparation_files',
    SERVICE_FILES: 'service_files',
    CALCULATOR: 'calculator',
  },

  fileTypes: [
    { id: 'CONTRACT_TEMPLATES', name: 'Wzory umów' },
    { id: 'EMPLOYEE_CONTRACT', name: 'Umowy pracownicze' },
    { id: 'OFFER', name: 'Oferty' },
    { id: 'ADVERTISING_MATERIALS', name: 'Materiały reklamowe' },
    { id: 'PREPARATION_FILES', name: 'Pliki w przygotowaniu' },
    { id: 'SERVICE_FILES', name: 'Pliki dla serwisu' },
    { id: 'PROJECT_TEMPLATES', name: 'Szablony plików projektowych' },
  ],

  stageNames: [
    { id: 'Akwizycja/Rozmowy wstępne', name: 'Akwizycja/rozmowy wstępne' },
    { id: 'Projektant -> Projekt/Aktualizacja', name: 'Projektant -> Projekt/Aktualizacja' },
    { id: 'Negocjacje', name: 'Negocjacje' },
    { id: 'Akceptacja kart materiałowych', name: 'Akceptacja kart materiałowych' },
    { id: 'Zamknięcie sprzadaży', name: 'Zamknięcie sprzadaży' },
    { id: 'Logistyka', name: 'Logistyka' },
    { id: 'Uruchomienie (serwis)', name: 'Uruchomienie (serwis)' },
  ],
};
