import { lazy } from 'react';
import {
  home,
  prowizjeTargety,
  kalendarz,
  kontrahenci,
  kontrahent,
  zadania,
  zamowienia,
  serwis,
  repozytorium,
  uzytkownicy,
  email,
  investments,
  investment,
  setting,
  kalendarzNieobecnosciWszystkie,
  prowizjeTargetyRegiony,
  investmentsTelekonferencja,
  permission,
  notifications,
  nieobecnosciDoAkceptacji,
  nieobecnosciTrwajace,
  nieobecnosciZaplanowane,
  nieobecnosci,
} from 'helpers/AllRouteName';

const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const InvestmentsPage = lazy(() => import('pages/InvestmentsPage/InvestmentsPage'));
const InvestmentPage = lazy(() => import('pages/InvestmentPage/InvestmentPage'));
const ContractorsPage = lazy(() => import('pages/ContractorsPage/ContractorsPage'));
const ContractorPage = lazy(() => import('pages/ContractorPage/ContractorPage'));
const CalendarPage = lazy(() => import('pages/CalendarPage/CalendarPage'));
const CalendarAbsentPlannedPage = lazy(() =>
  import('pages/CalendarAbsentPlannedPage/CalendarAbsentPlannedPage'),
);
const CalendarAbsentToAcceptPage = lazy(() =>
  import('pages/CalendarAbsentToAcceptPage/CalendarAbsentToAcceptPage'),
);
const CalendarAbsentCurrentPage = lazy(() =>
  import('pages/CalendarAbsentCurrentPage/CalendarAbsentCurrentPage'),
);
const TasksPage = lazy(() => import('pages/TasksPage/TasksPage'));
const UsersPage = lazy(() => import('pages/UsersPage/UsersPage'));
const SettingsPage = lazy(() => import('pages/SettingsPage/SettingsPage'));
const CommissionTargetPage = lazy(() => import('pages/CommissionTargetPage/CommissionTargetPage'));
const OrdersPage = lazy(() => import('pages/OrdersPage/OrdersPage'));
const RedirectToCalendarTask = lazy(() =>
  import('pages/RedirectToCalendarTask/RedirectToCalendarTask'),
);
const CommissionTargetTabRegionPage = lazy(() =>
  import('pages/CommissionTargetTabRegionPage/CommissionTargetTabRegionPage'),
);
const RepositoryPage = lazy(() => import('pages/RepositoryPage/RepositoryPage'));
const TeleconferencePage = lazy(() => import('pages/TeleconferencePage/TeleconferencePage'));
const PermissionPage = lazy(() => import('pages/PermissionPage/PermissionPage'));
const NotificationsPage = lazy(() => import('pages/NotificationsPage/NotificationsPage'));

const PrivateRouteList = [
  {
    path: home.path,
    Component: HomePage,
    exact: true,
    // permission: 'Dashboard',
  },
  {
    path: prowizjeTargety.path,
    Component: CommissionTargetPage,
    exact: false,
    permission: 'Prowizje i targety',
  },
  {
    path: prowizjeTargetyRegiony.path,
    Component: CommissionTargetTabRegionPage,
    exact: false,
    permission: 'Prowizje i targety',
  },
  {
    path: kalendarz.path,
    Component: CalendarPage,
    exact: false,
    permission: 'Kalendarz',
  },
  {
    path: kontrahenci.path,
    Component: ContractorsPage,
    exact: false,
    permission: 'Kontrahenci',
  },
  {
    path: kontrahent.path,
    Component: ContractorPage,
    exact: false,
    permission: 'Kontrahenci',
  },
  {
    path: zadania.path,
    Component: TasksPage,
    exact: false,
    permission: 'Zadania',
  },
  {
    path: zamowienia.path,
    Component: OrdersPage,
    exact: false,
    permission: 'Zamówienia',
  },
  {
    path: serwis.path,
    Component: HomePage,
    exact: false,
    permission: 'Serwis',
  },
  {
    path: repozytorium.path,
    Component: RepositoryPage,
    exact: false,
    permission: 'Pliki',
  },
  {
    path: uzytkownicy.path,
    Component: UsersPage,
    exact: false,
    // permission: 'Użytkownicy',
  },
  {
    path: permission.path,
    Component: PermissionPage,
    exact: false,
    // permission: 'Użytkownicy',
  },
  {
    path: investments.path,
    Component: InvestmentsPage,
    exact: false,
    permission: 'Inwestycje',
  },
  {
    path: investmentsTelekonferencja.path,
    Component: TeleconferencePage,
    exact: false,
    permission: 'Inwestycje',
  },
  {
    path: investment.path,
    Component: InvestmentPage,
    exact: false,
    permission: 'Inwestycje',
  },
  {
    path: nieobecnosci.path,
    Component: CalendarAbsentPlannedPage,
    exact: false,
    permission: 'Nieobecności',
  },
  {
    path: kalendarz.path,
    Component: CalendarPage,
    exact: false,
    permission: 'Kalendarz',
  },
  {
    path: nieobecnosciZaplanowane.path,
    Component: CalendarAbsentPlannedPage,
    exact: false,
    permission: 'Nieobecności',
  },
  {
    path: nieobecnosciDoAkceptacji.path,
    Component: CalendarAbsentToAcceptPage,
    exact: false,
    permission: 'Nieobecności',
  },
  {
    path: nieobecnosciTrwajace.path,
    Component: CalendarAbsentCurrentPage,
    exact: false,
    permission: 'Nieobecności',
  },
  {
    path: kalendarzNieobecnosciWszystkie.path,
    Component: CalendarPage,
    exact: false,
    permission: 'Nieobecności',
  },
  {
    path: setting.path,
    Component: SettingsPage,
    exact: false,
  },
  {
    path: notifications.path,
    Component: NotificationsPage,
    exact: false,
  },
];

export default PrivateRouteList;
