// public routes
export const logowanie = {
  path: '/logowanie',
};

export const przypomnijHaslo = {
  path: '/przypomnij-haslo',
};

// private routes

export const home = {
  path: '/',
};

export const prowizjeTargety = {
  path: '/prowizje-i-targety',
};
export const prowizjeTargetyRegiony = {
  path: '/prowizje-i-targety/regiony',
};

export const kalendarz = {
  path: '/kalendarz',
};

export const KalendarzNieobecnosci = {
  path: '/kalendarz',
};

export const nieobecnosci = {
  path: '/nieobecnosci',
};

export const kalendarzZadania = {
  path: '/kalendarz/zadania',
};

export const nieobecnosciZaplanowane = {
  path: '/nieobecnosci/zaplanowane',
};

export const nieobecnosciDoAkceptacji = {
  path: '/nieobecnosci/do-akceptacji',
};

export const nieobecnosciTrwajace = {
  path: '/nieobecnosci/trwajace',
};

export const kalendarzNieobecnosciWszystkie = {
  path: '/kalendarz',
};

export const kontrahenci = {
  path: '/kontrahenci',
};
export const kontrahent = {
  path: '/kontrahenci/:id/*',
};

export const investments = {
  path: '/inwestycje',
};

export const investmentsTelekonferencja = {
  path: '/inwestycje/telekonferencja',
};

export const investment = {
  path: '/inwestycje/:id/*',
};

export const zadania = {
  path: '/zadania',
};

export const zamowienia = {
  path: '/zamowienia',
};

export const serwis = {
  path: '/serwis',
};

export const repozytorium = {
  path: '/repozytorium',
};

export const uzytkownicy = {
  path: '/uzytkownicy',
};

export const email = {
  path: '/email',
};

export const setting = {
  path: '/ustawienia-systemu',
};

export const permission = {
  path: '/uprawnienia',
};

export const notifications = {
  path: `/powiadomienia`,
};

export const changePassword = {
  path: `/reset`,
};
