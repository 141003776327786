import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Collapse } from 'react-collapse';
import ArrowDownSVG from '../../atoms/SVG/ArrowDownSVG/ArrowDownSVG';

const MenuItem = ({ children, to, submenu, ...rest }) => {
  const [isActive, setActive] = useState(false);
  const menuRef = useRef(null);

  const listener = (event) => {
    if (!menuRef.current || menuRef.current.contains(event.target)) {
      return;
    }

    setActive(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  });

  return (
    <StyledLi {...rest} ref={menuRef}>
      <StyledLink
        className="ml-1 text-xs lg:ml-10 lg:text-sm"
        isactive={isActive.toString()}
        to={to}
        onClick={(e) => {
          if (submenu.length > 0) {
            e.preventDefault();
          }
          setActive(!isActive);
        }}
      >
        {children} {submenu.length > 0 && <ArrowDownSVG />}
      </StyledLink>
      {submenu.length > 0 && (
        <Collapse isOpened={isActive} theme={{ collapse: 'foo', content: 'bar' }}>
          <StyledSubmenuAbsolute className="w-full px-1 lg:px-5">
            {submenu.map(
              (item) =>
                item.permission && (
                  <StyledLink
                    className="ml-1 text-xs lg:ml-10 lg:text-sm"
                    key={item.name}
                    to={item.to}
                  >
                    {item.name}
                  </StyledLink>
                ),
            )}
          </StyledSubmenuAbsolute>
        </Collapse>
      )}
    </StyledLi>
  );
};

const StyledLi = styled.li`
  list-style: none;
  & .foo {
    transition: 0.3s;
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  align-items: center;
  padding: 8px 20px;
  position: relative;
  font-weight: 600;

  svg {
    margin-left: 10px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ theme }) => theme.accentColor};
  }

  ${({ isactive }) =>
    isactive === 'true' &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}

  &.active {
    color: ${({ theme }) => theme.accentColor};
    svg {
      path {
        stroke: ${({ theme }) => theme.accentColor};
      }
    }
  }
`;
const StyledSubmenuAbsolute = styled.ul`
  /* position: absolute; */
  /* bottom: calc(-100%); */
  background: #f5f5f5;
  /* display: ${({ isActive }) => (isActive ? 'flex' : 'none')}; */
  flex-direction: column;
  z-index: 1;
  border: 2px solid #e5e5e5;
  padding: 5px 20px;
`;

MenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  submenu: PropTypes.arrayOf(PropTypes.object),
};

MenuItem.defaultProps = {
  submenu: [],
};

export default MenuItem;
