import { userConstants } from 'actions/user/user.constants';
import { setPermissionData } from 'redux/reducers/permissionsReducer';
import { setUserData } from 'redux/reducers/userReducer';
import { apiSlice } from 'redux/services/apiSlice';

function deleteCookie(cname, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=;${expires};path=/`;
}

export const logout = () => (dispatch) => {
  deleteCookie('token', 14);
  dispatch({ type: userConstants.LOGOUT });
  dispatch(setUserData({}));
  dispatch(setPermissionData({}));
  dispatch(apiSlice.util.resetApiState());
};
