import React, { useState, useRef, useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useDropdown } from 'hooks/useDropdown';
import SelectIconSVG from 'components/atoms/SVG/SelectIconSVG/SelectIconSVG';
import Close2SVG from 'components/atoms/SVG/Close2SVG/Close2SVG';

const StyledSelectDropdown = styled.div`
  flex-direction: column;
  position: relative;
  border: 1px solid ${({ theme }) => theme.lightBackground};
  border-radius: 5px;
  display: ${({ isactive }) => (isactive ? 'flex' : 'none')};
  position: absolute;
  background: #fff;
  transform: translateY(10px);
  min-width: 140px;
  width: 100%;
  max-height: 250px;
  color: ${({ theme }) => theme.textColor};
  overflow: auto;
  ${({ isactive }) =>
    isactive &&
    css`
      z-index: 5;
    `}
  input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
  }
`;

const StyledLabel = styled.label`
  cursor: pointer;
  padding: 10px;

  font-size: 14px;
  &:hover {
    background: ${({ theme }) => theme.lightBackground};
  }
`;

const StyledSelectWrapper = styled.div`
  position: relative;
  max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '140px')};
  width: 100%;
`;

const StyledSelectedButton = styled.button`
  background: #fff;
  font-size: 14px;
  padding: 4px 7px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 35px;
  position: relative;
  ${({ error }) =>
    error
      ? css`
          border: 1px solid ${({ theme }) => theme.warrningColor};
        `
      : null}

  ${({ disabled }) =>
    disabled
      ? css`
          color: ${({ theme }) => theme.textColorLight};
        `
      : null}

  svg {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StyledCloseButton = styled.div`
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
  cursor: pointer;
`;

const CustomSelect = ({
  name,
  error,
  value,
  data,
  fullWidth,
  placeholder,
  disabled,
  filterPanel,
  clear,
  byName,
  ...rest
}) => {
  const [isActive, setActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dropdownRef = useRef(null);
  const { setFieldValue } = useFormikContext();

  useDropdown(dropdownRef, setActive);

  useEffect(() => {
    if (value && !byName) {
      setSelectedItem(data?.[data?.map((item) => item.id).indexOf(value)]?.name);
    } else if (data?.lenght) {
      setSelectedItem(data?.[data?.map((item) => item.name).indexOf(value)]?.name);
    }
  }, [data, value]);

  useEffect(() => {
    if (clear) {
      setSelectedItem(null);
    }
  }, [clear]);

  return (
    <StyledSelectWrapper
      fullwidth={fullWidth}
      role="group"
      aria-labelledby="my-radio-group"
      ref={dropdownRef}
      {...rest}
    >
      <StyledSelectedButton
        error={error}
        disabled={disabled}
        type="button"
        onClick={() => setActive(!isActive)}
      >
        {selectedItem || placeholder}
        <SelectIconSVG />
      </StyledSelectedButton>
      {selectedItem && !disabled && (
        <StyledCloseButton
          onClick={() => {
            setSelectedItem(null);
            setActive(false);
            setFieldValue(name, '');
          }}
        >
          <Close2SVG />
        </StyledCloseButton>
      )}
      {!!data?.length && (
        <StyledSelectDropdown isactive={!disabled && isActive}>
          {data.map((field) => (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <StyledLabel
              key={`${field?.id ? field.id : field}`}
              htmlFor={`${name}-${field?.id ? field.id : field}`}
              onClick={() => {
                setActive(false);
                setSelectedItem(field.name);
                setFieldValue(name, filterPanel ? field.name : field.id);
              }}
            >
              {field.name}
            </StyledLabel>
          ))}
        </StyledSelectDropdown>
      )}
    </StyledSelectWrapper>
  );
};

CustomSelect.propTypes = {
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  filterPanel: PropTypes.bool,
};

CustomSelect.defaultProps = {
  fullWidth: false,
  placeholder: 'Wybierz',
  disabled: false,
  filterPanel: false,
};

export default CustomSelect;
