import { clientStatus } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';

import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['clientStatus'] });

export const clientStatusApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getClientStatus: builder.query({
      query: () => ({
        url: clientStatus,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['clientStatus'],
    }),
    deleteClientStatus: builder.mutation({
      query: (id) => ({
        url: `${clientStatus}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['clientStatus'],
    }),
    postClientStatus: builder.mutation({
      query: (name) => ({
        url: clientStatus,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['clientStatus'],
    }),
  }),
});

export const {
  useGetClientStatusQuery,
  useDeleteClientStatusMutation,
  usePostClientStatusMutation,
} = clientStatusApi;
