import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledButton = styled.button.attrs(({ ref }) => ({
  [ref]: ref,
}))`
  padding: 6px 16px;
  border-radius: 13px;
  border: 0;
  font-size: 13px;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  background: ${({ active }) => active && '#2a6ab8'} !important;

  ${({ secondary }) =>
    secondary
      ? css`
          /* secondary */
          background: #fff;
          color: ${({ theme }) => theme.accentColor};
          font-weight: 500;
          border: 2px solid ${({ theme }) => theme.accentColor};
        `
      : css`
          /* primary */
          background: ${({ theme }) => theme.accentColor};
          color: #ffffff;
          font-weight: 500;
          &:hover {
            background: ${({ theme }) => theme.accentColorHover};
          }
        `};

  ${({ cancel }) =>
    cancel &&
    css`
      /* cancel */
      background: ${({ theme }) => theme.warrningColor};
      color: #ffffff;
      font-weight: 500;
      &:hover {
        background: #b82d2d;
      }
    `};
`;

const Button = ({ secondary, children, type, refx, ...rest }) => (
  <StyledButton secondary={secondary} type={type} ref={refx} {...rest}>
    {children}
  </StyledButton>
);
Button.propTypes = {
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
};
Button.defaultProps = {
  secondary: false,
};

export default Button;
