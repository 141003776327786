import React from 'react';

const SortUpSVG = ({ className }) => (
  <svg
    className={className}
    width="7"
    height="6"
    viewBox="0 0 7 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.5 -3.0598e-07L6.53109 5.25L0.468912 5.25L3.5 -3.0598e-07Z" />
  </svg>
);

export default SortUpSVG;
