import * as Yup from 'yup';

const taskFormSchemaValidate = Yup.object().shape({
  type: Yup.string().required('Pole jest wymagane'),
  participants: Yup.array().min(1, 'at least 1').required('Pole jest wymagane'),
  producer: Yup.string().required('Pole jest wymagane'),
  delegator: Yup.string().required('Pole jest wymagane'),
  startDate: Yup.date().required('Pole jest wymagane'),
  endDate: Yup.date().when('startDate', (startDate, schema) => {
    if (startDate) {
      const dayAfter = new Date(startDate.getTime() + 1 * 60 * 1000);

      return schema.min(dayAfter, 'End date has to be after than start date');
    }

    return schema;
  }),
  investment: Yup.string().required('Pole jest wymagane'),
  status: Yup.string().required('Pole jest wymagane'),

  contractor: Yup.string().when('type', {
    is: (type) => type === 'PROJECT_APPLICATION',
    then: Yup.string().required('Pole jest wymagane'),
  }),

  contact: Yup.string().when('type', {
    is: (type) => type === 'PROJECT_APPLICATION',
    then: Yup.string().required('Pole jest wymagane'),
  }),
});

export default taskFormSchemaValidate;
