import { offerTask } from '../../../endpoints/endpoints';
import { getAuthorizationHeader } from '../getAuthorizationHeader';

import { apiSlice } from '../apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['OfferTask'] });

export const offerApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getOfferTask: builder.query({
      query: () => ({
        url: offerTask,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['OfferTask'],
    }),

    deleteOfferTask: builder.mutation({
      query: (id) => ({
        url: `${offerTask}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['OfferTask'],
    }),

    postOfferTask: builder.mutation({
      query: (name) => ({
        url: offerTask,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['OfferTask'],
    }),
  }),
});

export const {
  useGetOfferTaskQuery,
  useLazyGetOfferTaskQuery,
  useDeleteOfferTaskMutation,
  usePostOfferTaskMutation,
} = offerApi;
