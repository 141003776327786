import React, { useState, useRef } from 'react';
import Popup from 'components/organisms/Popup/Popup';
import IconTextButton from 'components/atoms/IconTextButton/IconTextButton';
import { usePopup } from 'hooks/usePopup';

const IconTextButtonPopup = ({ buttonIcon, data, text, content, overflowAuto, ...rest }) => {
  const [isActive, setActive] = useState(false);
  const popupRef = useRef(null);

  usePopup(popupRef, setActive);
  return (
    <div {...rest}>
      <IconTextButton onClick={() => setActive(true)} icon={buttonIcon} text={text} />
      <Popup
        isActive={isActive}
        Content={content}
        overflowAuto={overflowAuto}
        handlePopup={setActive}
        data={data}
      />
    </div>
  );
};

export default IconTextButtonPopup;
