import { createSlice } from '@reduxjs/toolkit';

export const repositoryUtils = createSlice({
  name: 'repositoryUtils',
  initialState: {
    data: [],
  },
  reducers: {
    setPagination: (state, action) => ({
      ...state,
      data: action.payload,
    }),
  },
});

export const { setPagination } = repositoryUtils.actions;

export default repositoryUtils.reducer;
