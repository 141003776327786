import { stage } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';

import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['Stage'] });

export const stageApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getStage: builder.query({
      query: ({ id, page, size }) => {
        const params = new URLSearchParams({
          ...(page && { page }),
          ...(size && { size }),
        });

        return {
          url: `${stage}/investment/${id}?${params.toString()}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['Stage'],
    }),

    getLazyStage: builder.query({
      query: ({ id }) => ({
        url: `${stage}/investment/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['Stage'],
    }),

    deleteStage: builder.mutation({
      query: (id) => ({
        url: `${stage}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Stage'],
    }),

    postStage: builder.mutation({
      query: (data) => ({
        url: stage,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['Stage'],
    }),

    putStage: builder.mutation({
      query: (data) => ({
        url: stage,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['Stage'],
    }),
  }),
});

export const {
  useGetStageQuery,
  useLazyGetStageQuery,
  useDeleteStageMutation,
  usePostStageMutation,
  usePutStageMutation,
} = stageApi;
