import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, province, provinceAdmin } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';

import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['Province'] });

export const provinceApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProvince: builder.query({
      query: () => ({
        url: province,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['Province'],
    }),
    deleteProvince: builder.mutation({
      query: (id) => ({
        url: `${province}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Province'],
    }),
    postProvince: builder.mutation({
      query: (name) => ({
        url: province,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['Province'],
    }),
  }),
});

export const { useGetProvinceQuery, useDeleteProvinceMutation, usePostProvinceMutation } =
  provinceApi;
