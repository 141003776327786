import React, { useState, useRef } from 'react';
import Popup from 'components/organisms/Popup/Popup';
import Button from 'components/atoms/Button/Button';
import { usePopup } from 'hooks/usePopup';

const ButtonWithPopup = ({
  buttonText,
  secondary,
  data,
  content,
  width,
  overflowAuto,
  type,
  Icon,
  name,
  disabled,
  zIndex,
  ...rest
}) => {
  const [isActive, setActive] = useState(false);
  const popupRef = useRef(null);

  usePopup(popupRef, setActive);
  return (
    <div {...rest}>
      <Button
        secondary={secondary}
        type={type}
        onClick={() => setActive(true)}
        disabled={disabled}
        className={disabled ? 'cursor-auto grayscale' : 'cursor-pointer'}
      >
        {Icon ? <Icon /> : null}
        {buttonText}
      </Button>

      <Popup
        isActive={isActive}
        width={width}
        Content={content}
        handlePopup={setActive}
        data={data}
        overflowAuto={overflowAuto}
        name={name}
        zIndex={zIndex}
      />
    </div>
  );
};

export default ButtonWithPopup;
