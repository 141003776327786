import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    data: [],
    logoutTime: '',
  },
  reducers: {
    setUserData: (state, action) => ({
      ...state,
      data: action.payload,
    }),
    setLogoutTime: (state, action) => ({
      ...state,
      logoutTime: action.payload,
    }),
  },
});

export const { setUserData, setLogoutTime } = userSlice.actions;

export default userSlice.reducer;
