import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledTitle = styled.h1`
  text-align: ${({ textAlign }) => textAlign || 'left'};

  margin: 0;
  font-size: 18px;
  color: ${({ theme }) => theme.accentColor};
  margin-right: 30px;
  padding: 3px;
`;

const TitleInDashboard = ({ children, textAlign, ...rest }) => (
  <StyledTitle {...rest} textAlign={textAlign}>
    {children}
  </StyledTitle>
);

TitleInDashboard.propTypes = {
  children: PropTypes.node.isRequired,
  textAlign: PropTypes.string,
};

TitleInDashboard.defaultProps = {
  textAlign: 'left',
};

export default TitleInDashboard;
