import { region, user } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';

import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['User'] });

export const informationApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getRegion: builder.query({
      query: () => ({
        url: region,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
    }),

    getUsers: builder.query({
      query: ({ page, size, sort, search }) => {
        const params = new URLSearchParams({
          page,
          size,
          sort,
          search: `${search?.name ? `name=%${search?.name}%&` : ''}${
            search?.permission.length
              ? `${search.permission.map((item) => `permission=${item}`).join('|')}&`
              : ''
          }${
            search?.active.length
              ? `${search.active.map((item) => `isActive=${item}`).join('|')}&`
              : ''
          }${
            search?.province.length
              ? `${search.province.map((item) => `province=${item}`).join('|')}&`
              : ''
          }${search?.region.length ? search.region.map((item) => `region=${item}`).join('|') : ''}`,
        });

        const keysForDel = [];
        params.forEach((value, key) => {
          if (value === 'undefined' || value === '') {
            keysForDel.push(key);
          }
        });

        keysForDel.forEach((key) => {
          params.delete(key);
        });

        return {
          url: `${user}?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['User'],
    }),

    getUserById: builder.query({
      query: (id) => ({
        url: `${user}/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['User'],
    }),

    deleteUser: builder.mutation({
      query: (id) => ({
        url: `${user}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['User'],
    }),

    putUser: builder.mutation({
      query: (data) => ({
        url: user,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['User'],
    }),

    putWorkTime: builder.mutation({
      query: (data) => ({
        url: `${user}/work-time`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['User'],
    }),

    getLoggedUser: builder.query({
      query: () => ({
        url: `${user}/logged`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
    }),

    getUserPosition: builder.query({
      query: (positions) => {
        const params = new URLSearchParams({
          search: `${positions ? positions.map((item) => `position=${item}`).join('|') : ''}`,
        });

        return {
          url: `${user}/position/?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['User'],
    }),

    getSearchUser: builder.query({
      query: (name) => ({
        url: `${user}/name?name=${name || ''}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
    }),
  }),
});

export const {
  useGetRegionQuery,
  useGetUserByIdQuery,
  useGetUsersQuery,
  useDeleteUserMutation,
  usePutUserMutation,
  useLazyGetLoggedUserQuery,
  useGetUserPositionQuery,
  useLazyGetSearchUserQuery,
  useGetSearchUserQuery,
  usePutWorkTimeMutation,
} = informationApi;
