import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPopupFooter = styled.div`
  padding-top: 15px;
  border-top: 1px solid #dddddd;
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'flex-end')};
  position: relative;
`;

const PopupFooter = ({ spaceBetween, children, ...rest }) => (
  <StyledPopupFooter spaceBetween={spaceBetween} {...rest}>
    {children}
  </StyledPopupFooter>
);

PopupFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PopupFooter;
