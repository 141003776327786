import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledHeaderDashboard = styled.header`
  padding: ${({ headerPadding }) => headerPadding};
  border-top: 1px solid ${({ theme }) => theme.accentColor};
  border-bottom: 1px solid ${({ theme }) => theme.accentColor};
  background: #fff;
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 5px;
  margin-top: 10px;
  width: 100%;

  ${({ contentTab }) =>
    contentTab &&
    css`
      border-top: 0;
      border-bottom: 0;
    `}
`;

const HeaderDashboard = ({ contentTab, headerPadding, children, ...rest }) => (
  <StyledHeaderDashboard contentTab={contentTab} headerPadding={headerPadding} {...rest}>
    {children}
  </StyledHeaderDashboard>
);

HeaderDashboard.propTypes = {
  children: PropTypes.node.isRequired,
  contentTab: PropTypes.bool,
  headerPadding: PropTypes.string,
};

HeaderDashboard.defaultProps = {
  contentTab: false,
  headerPadding: '10px 15px',
};
export default HeaderDashboard;
