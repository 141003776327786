/* eslint-disable no-unsafe-optional-chaining */
import React, { useRef } from 'react';
import styled from 'styled-components';
import CalendarSVG from 'components/atoms/SVG/CalendarSVG/CalendarSVG';
import { ReactComponent as ACCEPTED } from 'assets/svg/statusIcons/ACCEPTED.svg';
import { ReactComponent as ACTIVE } from 'assets/svg/statusIcons/ACTIVE.svg';
import { ReactComponent as CLOSED_NEGATIVE } from 'assets/svg/statusIcons/CLOSED_NEGATIVE.svg';
import { ReactComponent as CLOSED_POSITIVE } from 'assets/svg/statusIcons/CLOSED_POSITIVE.svg';
import { ReactComponent as EXPIRED } from 'assets/svg/statusIcons/EXPIRED.svg';
import { ReactComponent as FOR_ACCEPTANCE } from 'assets/svg/statusIcons/FOR_ACCEPTANCE.svg';
import { ReactComponent as REJECTED } from 'assets/svg/statusIcons/REJECTED.svg';
import PopupAddAbsence from 'components/organisms/PopupAddAbsence/PopupAddAbsence';
import DashboardSVG from 'components/atoms/SVG/DashboardSVG/DashboardSVG';
import PopupCalendarTask from 'components/organisms/PopupCalendarTask/PopupCalendarTask';
import { useSelector } from 'react-redux';
import UserQuickSVG from 'components/atoms/SVG/UserQuickSVG/UserQuickSVG';
import PhoneQuickSVG from 'components/atoms/SVG/PhoneQuickSVG/PhoneQuickSVG';
import TaskQuickSVG from 'components/atoms/SVG/TaskQuickSVG/TaskQuickSVG';
import SettingsQuickSVG from 'components/atoms/SVG/SettingsQuickSVG/SettingsQuickSVG';
import MeetingQuickSVG from 'components/atoms/SVG/MeetingQuickSVG/MeetingQuickSVG';
import EmailQuickSVG from 'components/atoms/SVG/EmailQuickSVG/EmailQuickSVG';
import RegistrationProjectSVG from 'components/atoms/SVG/RegistrationProjectSVG/RegistrationProjectSVG';
import TasksSVG from 'components/atoms/SVG/TasksSVG/TasksSVG';
import ComponentWithPopup from '../ComponentWithPopup/ComponentWithPopup';

const handleColorType = (color) => {
  switch (color) {
    case 'ACCEPTED':
      return 'background: #D4E6FF; color: #414141; font-weight: 700; svg{width: 17px; path{stroke:#4F6C96; fill:#4F6C96 }}';
    case 'ACTIVE':
      return 'background: #DEFFE1; color: #414141; font-weight: 700; svg{width: 17px; path{stroke:#13AA22; fill:#13AA22 }}';
    case 'CLOSED_NEGATIVE':
      return 'background: #FFCBCB; color: #414141; font-weight: 700; svg{width: 17px; path{stroke:#E20707; fill:#E20707 }}';
    case 'CLOSED_POSITIVE':
      return 'background: #A4F0AB; color: #414141; font-weight: 700; svg{width: 17px; path{stroke:#158E21; fill:#158E21 }}';
    case 'EXPIRED':
      return 'background: #FFFFFF; color: #414141; font-weight: 700; svg{width: 17px; path{stroke:#747474; fill:#747474 }}';
    case 'FOR_ACCEPTANCE':
      return 'background: #FFF2D1; color: #414141; font-weight: 700; svg{width: 17px; path{stroke:#FFB800; fill:#FFB800 }}';
    case 'REJECTED':
      return 'background: #FFD3B6; color: #414141; font-weight: 700; svg{width: 17px; path{stroke:#E57878; fill:#E57878 }}';
    default:
      return 'background: #FFF0EC;  color: #414141; font-weight: 700; svg{width: 17px;path{stroke:#81148B; fill:#81148B }}';
  }
};

const handleTypeIcon = (type) => {
  switch (type) {
    case 'TASK_MEETING':
      return <UserQuickSVG />;
    case 'TASK_PHONE':
      return <PhoneQuickSVG />;
    case 'TASK_PROJECT_APPLICATION':
      return <TaskQuickSVG />;
    case 'TASK_OFFER':
      return <RegistrationProjectSVG />;
    case 'TASK_EMAIL':
      return <EmailQuickSVG />;
    case 'TASK_SERVICE_APPLICATION':
      return <SettingsQuickSVG />;
    case 'TASK_LOGISTICS':
      return <MeetingQuickSVG />;
    case 'TASK_PROJECT_SERVICE':
      return <TasksSVG />;

    case 'ABSENCE':
      return <CalendarSVG />;
    default:
      return null;
  }
};

const handleStatusIcon = (status) => {
  switch (status) {
    case 'ACCEPTED':
      return <ACCEPTED />;
    case 'ACTIVE':
      return <ACTIVE />;
    case 'CLOSED_NEGATIVE':
      return <CLOSED_NEGATIVE />;
    case 'CLOSED_POSITIVE':
      return <CLOSED_POSITIVE />;
    case 'EXPIRED':
      return <EXPIRED />;
    case 'FOR_ACCEPTANCE':
      return <FOR_ACCEPTANCE />;
    case 'REJECTED':
      return <REJECTED />;

    default:
      return <DashboardSVG />;
  }
};

const StyledEvent = styled.div`
  ${({ status }) => handleColorType(status)};
  display: flex;
  min-height: 25px;
  height: 100%;
  margin: 2px 0;
  width: 100%;

  /* ${({ status }) => status !== 'REJECTED'} {
    &::after {
      background-color: black;
      height: 2px;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      left: 0;
    }
  } */

  svg {
    :nth-child(1) {
      path {
        fill: rgba(229, 229, 229, 0);
      }
    }

    :nth-child(2) {
      path {
        stroke: rgba(229, 229, 229, 0);
      }
    }
  }
`;

const StyledEventWrapperLeft = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 12px;
  svg {
    margin: 5px;
    max-width: 17px;
    width: 100% !important;
  }
`;
const StyledEventWrapperRight = styled.div`
  margin-right: 8px;
  svg {
    width: 17px;
  }
`;

const StyledNameBox = styled.span`
  width: 100%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
`;

const StyledUserBox = styled.span`
  width: 100%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
`;

const StyledComponentWithPopup = styled(ComponentWithPopup)`
  /* height: 100%; */
  /* width: 100%; */
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 25px;
`;

const CalendarEvent = ({ event }) => {
  const eventRef = useRef();
  const textRef = useRef();
  const permission = useSelector((state) => state.permissionsReducer.data);

  const handlePopupType = (type) => {
    switch (type) {
      case 'ABSENCE':
        return PopupAddAbsence;
      case 'TASK':
        return PopupCalendarTask;
      default:
        return null;
    }
  };

  const handlePermissions = (type) => {
    switch (type) {
      case 'ABSENCE':
        return {
          'edytowanie nieobecności': permission.Kalendarz?.[`edytowanie nieobecności`],
          'usuwanie nieobecności': permission.Kalendarz?.['usuwanie nieobecności'],
        };
      case 'TASK':
        return {
          'edytowanie zadań': permission.Kalendarz?.[`edytowanie zadań`],
          'usuwanie zadań': permission.Kalendarz?.[`usuwanie zadań`],
        };
      default:
        return null;
    }
  };

  const handleEventType = (type) => {
    switch (type) {
      case 'contractor':
        return (
          <TextWrapper ref={textRef}>
            <StyledNameBox>
              {event?.data?.contractorName} {event.title}
            </StyledNameBox>
            <StyledUserBox>{event.data.investmentName}</StyledUserBox>
          </TextWrapper>
        );
      case 'investment':
        return (
          <TextWrapper ref={textRef}>
            <StyledNameBox>
              {event.data.investmentName} {event.title}
            </StyledNameBox>
            <StyledUserBox>{event.data.contractorName}</StyledUserBox>
          </TextWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <StyledComponentWithPopup
      h100
      content={handlePopupType(event.type)}
      data={{ ...event.data, permission: handlePermissions(event.type), inCalendar: true } || []}
    >
      <StyledEvent status={event.status} type={event.type} ref={eventRef}>
        <Wrapper>
          <StyledEventWrapperLeft>
            {handleEventType(event.calendarType)}
            {handleTypeIcon(event.type === 'TASK' ? event.data.type : event.type)}
          </StyledEventWrapperLeft>
          <StyledEventWrapperRight>{handleStatusIcon(event.status)}</StyledEventWrapperRight>
        </Wrapper>
      </StyledEvent>
    </StyledComponentWithPopup>
  );
};

export default CalendarEvent;
