import React, { useState, useRef } from 'react';
import Popup from 'components/organisms/Popup/Popup';
import IconButton from 'components/atoms/IconButton/IconButton';
import { usePopup } from 'hooks/usePopup';
import PropTypes from 'prop-types';

const IconButtonPopup = ({ buttonIcon, data, btnTooltip, content }) => {
  const [isActive, setActive] = useState(false);
  const popupRef = useRef(null);

  usePopup(popupRef, setActive);
  return (
    <div>
      <IconButton onClick={() => setActive(true)} icon={buttonIcon} tooltip={btnTooltip} />
      <Popup isActive={isActive} Content={content} handlePopup={setActive} data={data} />
    </div>
  );
};

IconButtonPopup.propTypes = {
  buttonIcon: PropTypes.element.isRequired,
  btnTooltip: PropTypes.string,
};

IconButtonPopup.defaultProps = {
  btnTooltip: '',
};

export default IconButtonPopup;
