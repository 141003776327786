import { serviceTask } from '../../../endpoints/endpoints';
import { getAuthorizationHeader } from '../getAuthorizationHeader';

import { apiSlice } from '../apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['ServiceTask'] });

export const serviceTaskApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getServiceTask: builder.query({
      query: () => ({
        url: serviceTask,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['ServiceTask'],
    }),

    deleteServiceTask: builder.mutation({
      query: (id) => ({
        url: `${serviceTask}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['ServiceTask'],
    }),

    postServiceTask: builder.mutation({
      query: (name) => ({
        url: serviceTask,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['ServiceTask'],
    }),
  }),
});

export const {
  useGetServiceTaskQuery,
  useLazyGetServiceTaskQuery,
  useDeleteServiceTaskMutation,
  usePostServiceTaskMutation,
} = serviceTaskApi;
