import React, { Suspense } from 'react';
import Loader from 'components/atoms/Loader/Loader';
import styled from 'styled-components';
import LeftSidebar from 'components/organisms/LeftSidebar/LeftSidebar';
import HeaderNav from 'components/organisms/HeaderNav/HeaderNav';
import { Routes, Route } from 'react-router-dom';
import PrivateRouteList from 'router/PrivateRouteList';
import AccessControl from 'components/atoms/AccessControl/AccessControl';
import { useSelector } from 'react-redux';

const DashboardWrapper = styled.section`
  display: flex;
  height: 100%;
`;

const DashboardContent = styled.div`
  display: block;
  margin-left: 12px;
  width: calc(100% - 12px);
  padding-top: 60px;
`;

const DashboardMainTemplate = () => {
  const user = useSelector((state) => state.userReducer.data?.customAccessGroup?.permissionGroups);

  const permissions = user?.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.groupName]: currentValue.permissions,
    }),
    '',
  );

  const userPermission = useSelector((state) => state.permissionsReducer.data);

  return (
    <div>
      <DashboardWrapper>
        <LeftSidebar />
        <DashboardContent className="pl-32 lg:pl-60">
          <HeaderNav />
          <Routes>
            {PrivateRouteList.map(({ path, Component, exact, permission }) => (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<Loader />}>
                    <AccessControl
                      permission={
                        permission
                          ? permissions?.[permission]?.some((item) => item.active === true)
                          : true
                      }
                      renderNoAccess={<div>brak dostępu</div>}
                    >
                      <Component permission={userPermission} />
                    </AccessControl>
                  </Suspense>
                }
                exact={exact}
              />
            ))}
          </Routes>
        </DashboardContent>
      </DashboardWrapper>
    </div>
  );
};

export default DashboardMainTemplate;
