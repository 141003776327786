import React from 'react';

const UsersSVG = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_749_2224)">
      <path
        d="M9.49995 3.30444C7.90562 3.30444 6.60864 4.60139 6.60864 6.19576C6.60864 7.79013 7.90558 9.08707 9.49995 9.08707C11.0943 9.08707 12.3913 7.79009 12.3913 6.19576C12.3913 4.60142 11.0943 3.30444 9.49995 3.30444ZM9.49995 8.26096C8.36162 8.26096 7.43475 7.33409 7.43475 6.19576C7.43475 5.05742 8.36162 4.13056 9.49995 4.13056C10.6383 4.13056 11.5651 5.05742 11.5651 6.19576C11.5651 7.33409 10.6383 8.26096 9.49995 8.26096Z"
        fill="#414141"
      />
      <path
        d="M15.2826 6.60864C14.1443 6.60864 13.2174 7.53552 13.2174 8.67385C13.2174 9.81219 14.1443 10.7391 15.2826 10.7391C16.421 10.7391 17.3478 9.81219 17.3478 8.67385C17.3478 7.53552 16.421 6.60864 15.2826 6.60864ZM15.2826 9.91299C14.5995 9.91299 14.0435 9.35704 14.0435 8.67385C14.0435 7.99066 14.5995 7.43472 15.2826 7.43472C15.9658 7.43472 16.5218 7.99066 16.5218 8.67385C16.5218 9.35704 15.9658 9.91299 15.2826 9.91299Z"
        fill="#414141"
      />
      <path
        d="M3.77605 6.60864C2.63686 6.60864 1.71085 7.53552 1.71085 8.67385C1.71085 9.81219 2.63771 10.7391 3.77605 10.7391C4.91438 10.7391 5.84124 9.81219 5.84124 8.67385C5.84124 7.53552 4.91519 6.60864 3.77605 6.60864ZM3.77605 9.91299C3.09286 9.91299 2.53692 9.35704 2.53692 8.67385C2.53692 7.99066 3.09286 7.43472 3.77605 7.43472C4.45923 7.43472 5.01517 7.99066 5.01517 8.67385C5.01517 9.35704 4.45919 9.91299 3.77605 9.91299Z"
        fill="#414141"
      />
      <path
        d="M9.49994 9.91309C6.53924 9.91309 4.13037 12.3219 4.13037 15.2826C4.13037 15.5106 4.31543 15.6957 4.54343 15.6957C4.77143 15.6957 4.95648 15.5106 4.95648 15.2826C4.95648 12.7771 6.99442 10.7392 9.49994 10.7392C12.0055 10.7392 14.0434 12.7771 14.0434 15.2826C14.0434 15.5106 14.2285 15.6957 14.4565 15.6957C14.6845 15.6957 14.8695 15.5106 14.8695 15.2826C14.8695 12.3219 12.4607 9.91309 9.49994 9.91309Z"
        fill="#414141"
      />
      <path
        d="M15.2828 11.5652C14.6029 11.5652 13.9371 11.7511 13.3571 12.1022C13.163 12.2211 13.1002 12.4747 13.2184 12.6697C13.3381 12.8646 13.5909 12.9266 13.7859 12.8085C14.2361 12.535 14.7532 12.3913 15.2828 12.3913C16.8771 12.3913 18.1741 13.6882 18.1741 15.2826C18.1741 15.5106 18.3591 15.6956 18.5871 15.6956C18.8151 15.6956 19.0002 15.5106 19.0002 15.2826C19.0001 13.2331 17.3323 11.5652 15.2828 11.5652Z"
        fill="#414141"
      />
      <path
        d="M5.64218 12.1013C5.06308 11.7511 4.39728 11.5652 3.71739 11.5652C1.66787 11.5652 0 13.2331 0 15.2826C0 15.5106 0.185056 15.6956 0.413056 15.6956C0.641056 15.6956 0.826112 15.5106 0.826112 15.2826C0.826074 13.6882 2.12305 12.3913 3.71739 12.3913C4.24692 12.3913 4.76403 12.535 5.21344 12.8076C5.40756 12.9258 5.66201 12.8638 5.78097 12.6688C5.89992 12.4739 5.83711 12.2203 5.64218 12.1013Z"
        fill="#414141"
      />
    </g>
    <defs>
      <clipPath id="clip0_749_2224">
        <rect width="19" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UsersSVG;
