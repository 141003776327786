import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-size: cover;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  grid-row-start: 1;
  grid-row-end: 3;
  cursor: pointer;
`;

const ProfilIcon = ({ user, ...rest }) =>
  user.avatar?.file ? (
    <StyledDiv>
      <img
        className="h-[46px] w-[46px] overflow-clip rounded-full object-cover"
        src={user.avatar?.file}
        alt="Zdjęcie profilu"
        {...rest}
      />
    </StyledDiv>
  ) : (
    <StyledDiv
      className="flex items-center justify-center bg-coloraccent font-black text-colorWhite"
      {...rest}
    >
      {user.name
        .split(' ')
        .map((item) => item.toUpperCase().substring(0, 1))
        .join(' ')}
    </StyledDiv>
  );

export default ProfilIcon;
