import React, { useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import CalendarSVG from 'components/atoms/SVG/CalendarSVG/CalendarSVG';
import 'react-datepicker/dist/react-datepicker.css';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { dateTimeFormat } from 'helpers/dateHelpers';
import { ReactComponent as CloseSVG } from 'assets/svg/closeSelect.svg';
import IconButton from 'components/atoms/IconButton/IconButton';
import './DateTimePicker.scss';
import { setHours, setMinutes } from 'date-fns';
import MaskedInput from 'react-text-mask';

registerLocale('pl', pl);

const StyledDataPicker = styled(ReactDatePicker)`
  background: #fff;
  border: 0;
  font-size: 14px;
  padding: 4px 7px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  width: 100%;
  min-width: 80px;
  color: #000;
  height: 26px;

  &:disabled {
    cursor: auto;
    color: ${({ theme }) => theme.textColorLight};
    background: hsl(0, 0%, 95%) !important;

    &::placeholder {
      color: ${({ theme }) => theme.textColorLight};
    }
  }

  ${({ error }) =>
    error
      ? css`
          border: 1px solid ${({ theme }) => theme.warrningColor};
        `
      : null}

  .react-datepicker-popper {
    z-index: 9;
    position: absolute;
  }
  &::placeholder {
    color: #000;
  }
  input {
    background: red;
  }
`;

const StyledDataPickerWrapper = styled.div`
  position: relative;
  margin-right: ${({ marginRight }) => marginRight};
  margin-left: ${({ marginLeft }) => marginLeft};
  width: 100%;
  height: 26px;
  display: flex;

  filter: ${(props) => (props.disabled ? 'grayscale(1)' : '0')};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const DateTimePicker = ({
  error,
  placeholderText,
  name,
  marginLeft,
  marginRight,
  fullWidth,
  value,
  disabled,
  clear,
  minDate,
  ...rest
}) => {
  const [startDate, setStartDate] = useState(value && new Date(value));

  useEffect(() => {
    if (value) {
      setStartDate(new Date(value));
    }
  }, [value]);

  const { setFieldValue } = useFormikContext();

  const handleDateChange = (dates) => {
    const offset = dates.getTimezoneOffset();
    const date = new Date(
      dates.getHours() >= 7 ? dates.getTime() - offset : dates.getTime() - offset + 7000 * 60 * 60,
    );

    setStartDate(date);
    setFieldValue(name, dateTimeFormat(date));
  };

  useEffect(() => {
    if (clear) {
      setFieldValue(name, '');
      setStartDate(null);
    }
  }, [clear]);

  const filterPassedTime = (time) => {
    const currentDate = new Date(minDate);
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const handleHideHours = (time) => (time.getHours() < 7 || time.getHours() > 20) && 'hidden';

  return (
    <StyledDataPickerWrapper
      marginLeft={marginLeft}
      marginRight={marginRight}
      fullwidth={fullWidth}
      disabled={disabled}
      className=""
    >
      <StyledDataPicker
        selected={startDate}
        showTimeSelect
        timeIntervals={30}
        locale="pl"
        disabled={disabled}
        dateFormat="yyyy/MM/dd HH:mm"
        timeInputLabel="Czas"
        error={error}
        onChange={(date) => {
          handleDateChange(date);
        }}
        customInput={
          <MaskedInput
            placeholder="Enter a phone number"
            id="my-input-id"
            mask={[
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '/',
              /\d/,
              /\d/,
              '/',
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              ':',
              /\d/,
              /\d/,
            ]}
          />
        }
        showIcon
        placeholderText={placeholderText || 'Wybierz'}
        filterTime={minDate && filterPassedTime}
        minTime={setHours(setMinutes(new Date(), 0), 7)}
        maxTime={setHours(setMinutes(new Date(), 0), 20)}
        minDate={minDate}
        timeClassName={handleHideHours}
        {...rest}
      />
      <ButtonWrapper>
        {startDate && !disabled && (
          <IconButton
            onClick={() => {
              setFieldValue(name, '');
              setStartDate(null);
            }}
            withoutBackground
            icon={
              <CloseSVG className=" fill-[#cccccc] duration-300 ease-out hover:fill-[#999999]" />
            }
          />
        )}
        <CalendarSVG />
      </ButtonWrapper>
    </StyledDataPickerWrapper>
  );
};
DateTimePicker.propTypes = {
  placeholderText: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.string,
};

DateTimePicker.defaultProps = {
  placeholderText: '',
  marginLeft: '0',
  marginRight: '15px',
  fullWidth: '140px',
};

export default DateTimePicker;
