import React from 'react';
import styled from 'styled-components';

const StyledPopupHeader = styled.div`
  padding: 15px;
  border-bottom: 1px solid #f1f1f1;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

const PopupHeader = ({ children, ...props }) => (
  <StyledPopupHeader {...props}>{children}</StyledPopupHeader>
);

export default PopupHeader;
