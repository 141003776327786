import { meetingTask } from '../../../endpoints/endpoints';
import { getAuthorizationHeader } from '../getAuthorizationHeader';

import { apiSlice } from '../apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['meetingTask'] });

export const meetingApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getMeetingTask: builder.query({
      query: () => ({
        url: meetingTask,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['meetingTask'],
    }),

    deleteMeetingTask: builder.mutation({
      query: (id) => ({
        url: `${meetingTask}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['meetingTask'],
    }),

    postMeetingTask: builder.mutation({
      query: (name) => ({
        url: meetingTask,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['meetingTask'],
    }),
  }),
});

export const {
  useGetMeetingTaskQuery,
  useLazyGetMeetingTaskQuery,
  useDeleteMeetingTaskMutation,
  usePostMeetingTaskMutation,
} = meetingApi;
