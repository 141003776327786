import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContentWrapper = styled.div`
  padding: 18px 22px 12px 22px;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: ${(props) => (props.setHeight ? 'none' : 'auto')};
`;

const ContentBox = ({ children, setHeight, ...rest }) => (
  <StyledContentWrapper setHeight={setHeight} {...rest}>
    {children}
  </StyledContentWrapper>
);

ContentBox.propTypes = {
  children: PropTypes.node.isRequired,
  setHeight: PropTypes.string,
};

ContentBox.defaultProps = {
  setHeight: '',
};

export default ContentBox;
