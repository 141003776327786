import { file } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';
import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['file'] });

export const fileApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getFile: builder.query({
      query: ({ page, size, sort, search, type, typeParam, investment }) => {
        const searchParams = new URLSearchParams({
          ...(type && { type }),
        });

        const params = new URLSearchParams({
          ...(page && { page }),
          ...(size && { size }),
          ...(sort && { sort }),
          ...(investment && { search: investment }),
          ...(search && {
            search: `${typeParam}&${searchParams.toString()}&${
              search?.name ? `name=%${search?.name}%&` : ''
            }${
              search?.user.length ? `${search.user.map((item) => `user=${item}`).join('|')}&` : ''
            }${search?.startDate ? `startDate>${search.startDate}&` : ''}${
              search?.endDate ? `endDate<${search.endDate}&` : ''
            }${
              search?.contractor?.length
                ? `${search.contractor.map((item) => `contractor=${item}`).join('|')}&`
                : ''
            }${
              search?.investment?.length
                ? `${search.investment.map((item) => `investment=${item}`).join('|')}|`
                : ''
            }${
              search?.type?.length ? `${search.type.map((item) => `type=${item}`).join('|')}|` : ''
            }`,
          }),
        });

        return {
          url: `${file}?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['file'],
    }),

    getFileInvestment: builder.query({
      query: ({ id, page, size, sort }) => {
        const params = new URLSearchParams({
          ...(page && { page }),
          ...(size && { size }),
          ...(sort && { sort }),
        });

        return {
          url: `${file}/investment/${id}?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['file'],
    }),

    deleteFile: builder.mutation({
      query: (id) => ({
        url: `${file}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['file'],
    }),

    postFile: builder.mutation({
      query: (data) => ({
        url: file,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['file'],
    }),

    postDownloadProject: builder.mutation({
      query: (data) => ({
        url: `${file}/download-project`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
    }),

    postUploadProjectData: builder.mutation({
      query: (data) => ({
        url: `${file}/upload-project-data`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
    }),

    postMultipleFiles: builder.mutation({
      query: (data) => ({
        url: `${file}/multiple-files`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['file'],
    }),

    putFile: builder.mutation({
      query: ({ id, requestBody }) => ({
        url: `${file}/${id}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: requestBody,
      }),
      invalidatesTags: ['file'],
    }),
  }),
});

export const {
  useGetFileQuery,
  useDeleteFileMutation,
  usePostFileMutation,
  usePutFileMutation,
  usePostMultipleFilesMutation,
  usePostDownloadProjectMutation,
  usePostUploadProjectDataMutation,
  useGetFileInvestmentQuery,
} = fileApi;
