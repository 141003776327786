import React from 'react';
import PopupHeader from 'components/molecules/PopupHeader/PopupHeader';
import FormContractors from './FormContractors';

const PopupContractors = ({ handlePopup, data }) => (
  <div>
    <PopupHeader>Lista kontrahentów</PopupHeader>
    <FormContractors handlePopup={handlePopup} data={data} />
  </div>
);

export default PopupContractors;
