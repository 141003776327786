import { investment, nextNumber } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';
import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [
    'investment',
    'investmentBlocking',
    'investmentRealizing',
    'putContractor',
    'Contractor',
  ],
});

export const investmentApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getInvestments: builder.query({
      query: ({ page, size, sort, search }) => {
        const params = new URLSearchParams({
          page,
          size,
          sort,
          search,
        });

        const keysForDel = [];
        params.forEach((value, key) => {
          if (value === 'undefined' || value === '') {
            keysForDel.push(key);
          }
        });

        keysForDel.forEach((key) => {
          params.delete(key);
        });

        return {
          url: `${investment}?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['investment'],
    }),

    getInvestmentsWithoutTasks: builder.query({
      query: ({ page, size, sort, search }) => {
        const params = new URLSearchParams({
          page,
          size,
          sort,
          search,
        });

        const keysForDel = [];
        params.forEach((value, key) => {
          if (value === 'undefined' || value === '') {
            keysForDel.push(key);
          }
        });

        keysForDel.forEach((key) => {
          params.delete(key);
        });

        return {
          url: `${investment}/without-tasks?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['investment'],
    }),

    getSubInvestments: builder.query({
      query: ({ page, size, sort, search, id }) => {
        const params = new URLSearchParams({
          page,
          size,
          sort,
          search,
        });

        const keysForDel = [];
        params.forEach((value, key) => {
          if (value === 'undefined' || value === '') {
            keysForDel.push(key);
          }
        });

        keysForDel.forEach((key) => {
          params.delete(key);
        });

        return {
          url: `${investment}/sub-investments/${id}?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['investment'],
    }),

    putSubInvestmentMarkAsMain: builder.mutation({
      query: (id) => ({
        url: `${investment}/sub-investments/${id}/mark-as-main`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['investment'],
    }),

    getTeleconference: builder.query({
      query: ({ page, size, sort, search }) => {
        const params = new URLSearchParams({
          page,
          size,
          sort,
          search: `${search?.startDate ? `startDate>${search.startDate}T00:00&` : ''}${
            search?.endDate ? `endDate<${search.endDate}T23:59&` : ''
          }${search?.amountFrom ? `value>${search.amountFrom}&` : ''}${
            search?.amountTo ? `value<${search.amountTo}&` : ''
          }${
            search?.department.length
              ? `${search.department.map((item) => `department=${item}`).join('|')}&`
              : ''
          }${
            search?.region.length
              ? `${search.region.map((item) => `region=${item}`).join('|')}&`
              : ''
          }${
            search?.coordinator.length
              ? `${search.coordinator.map((item) => `coordinator=${item}`).join('|')}&`
              : ''
          }${
            search?.status.length
              ? `${search.status.map((item) => `status=${item}`).join('|')}&`
              : ''
          }${
            search?.businessLine.length
              ? `${search.businessLine.map((item) => `businessLine=${item}`).join('|')}&`
              : ''
          }`,
        });

        const keysForDel = [];
        params.forEach((value, key) => {
          if (value === 'undefined' || value === '') {
            keysForDel.push(key);
          }
        });

        keysForDel.forEach((key) => {
          params.delete(key);
        });

        return {
          url: `${investment}/teleconference/?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['investment'],
    }),

    getTeleconferenceSimple: builder.query({
      query: ({ page, size, sort, search, type }) => {
        const params = new URLSearchParams({
          page,
          size,
          sort,
          search: `${search?.startDate ? `startDate>${search.startDate}T00:00&` : ''}${
            search?.endDate ? `endDate<${search.endDate}T23:59&` : ''
          }${search?.amountFrom ? `value>${search.amountFrom}&` : ''}${
            search?.amountTo ? `value<${search.amountTo}&` : ''
          }${
            search?.department.length
              ? `${search.department.map((item) => `department=${item}`).join('|')}&`
              : ''
          }${type ? `projectType=${type}&` : ''}${
            search?.region.length
              ? `${search.region.map((item) => `region=${item}`).join('|')}&`
              : ''
          }${
            search?.coordinator.length
              ? `${search.coordinator.map((item) => `coordinator=${item}`).join('|')}&`
              : ''
          }${
            search?.status.length
              ? `${search.status.map((item) => `status=${item}`).join('|')}&`
              : ''
          }${
            search?.businessLine.length
              ? `${search.businessLine.map((item) => `businessLine=${item}`).join('|')}&`
              : ''
          }`,
        });

        const keysForDel = [];
        params.forEach((value, key) => {
          if (value === 'undefined' || value === '') {
            keysForDel.push(key);
          }
        });

        keysForDel.forEach((key) => {
          params.delete(key);
        });

        return {
          url: `${investment}/teleconference/simple/?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['investment'],
    }),

    getInvestment: builder.query({
      query: (id) => ({
        url: `${investment}/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['investment'],
    }),

    getInvestmentList: builder.query({
      query: (name) => ({
        url: `${investment}/list?name=${name || ''}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['investment'],
    }),

    getContractorInvestments: builder.query({
      query: (id) => ({
        url: `${investment}/contractor/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['investment'],
    }),

    getEstimation: builder.query({
      query: (id) => ({
        url: `${investment}/estimation`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
    }),

    postInvestment: builder.mutation({
      query: (data) => ({
        url: investment,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['investment'],
    }),

    putInvestment: builder.mutation({
      query: (data) => ({
        url: investment,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['investment'],
    }),

    putContractorToInvestment: builder.mutation({
      query: ({ investmentId, contractorId }) => ({
        url: `${investment}/${investmentId}/contractor/${contractorId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['investment', 'putContractor'],
    }),

    deleteInvestment: builder.mutation({
      query: (id) => ({
        url: `${investment}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['investment'],
    }),

    deleteInvestmentContractor: builder.mutation({
      query: ({ investmentId, contractorId }) => ({
        url: `${investment}/${investmentId}/contractor/${contractorId}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['putContractor'],
    }),

    putInvestmentRealizing: builder.mutation({
      query: ({ investmentId, executorId, designerId, distributorId }) => {
        const params = new URLSearchParams({
          ...(executorId && { executorId }),
          ...(designerId && { designerId }),
          ...(distributorId && { distributorId }),
        });

        return {
          url: `${investment}/${investmentId}/set/realizing?${params.toString()}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthorizationHeader(),
          },
        };
      },
      invalidatesTags: ['investmentRealizing'],
    }),

    putInvestmentBlocking: builder.mutation({
      query: ({ investmentId, executorId, designerId, distributorId }) => {
        const params = new URLSearchParams({
          ...(executorId && { executorId }),
          ...(designerId && { designerId }),
          ...(distributorId && { distributorId }),
        });

        return {
          url: `${investment}/${investmentId}/set/blocking?${params.toString()}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthorizationHeader(),
          },
        };
      },
      invalidatesTags: ['investmentBlocking'],
    }),

    getInvestmentRealizing: builder.query({
      query: (investmentId) => ({
        url: `${investment}/${investmentId}/get/realizing`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['investmentRealizing'],
    }),

    getInvestmentBlocking: builder.query({
      query: (investmentId) => ({
        url: `${investment}/${investmentId}/get/blocking`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['investmentBlocking'],
    }),

    getNextNumber: builder.query({
      query: () => ({
        url: nextNumber,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['investmentBlocking'],
    }),
  }),
});

export const {
  useGetInvestmentsQuery,
  useGetSubInvestmentsQuery,
  useGetInvestmentListQuery,
  useLazyGetInvestmentListQuery,
  useGetContractorInvestmentsQuery,
  useGetInvestmentQuery,
  useLazyGetInvestmentQuery,
  useGetEstimationQuery,
  useGetTeleconferenceQuery,
  useGetTeleconferenceSimpleQuery,
  usePutInvestmentMutation,
  usePostInvestmentMutation,
  useDeleteInvestmentMutation,
  usePutContractorToInvestmentMutation,
  usePutInvestmentRealizingMutation,
  usePutInvestmentBlockingMutation,
  useGetInvestmentRealizingQuery,
  useGetInvestmentBlockingQuery,
  useGetInvestmentsWithoutTasksQuery,
  usePutSubInvestmentMarkAsMainMutation,
  useGetNextNumberQuery,
  useDeleteInvestmentContractorMutation,
} = investmentApi;
