import React from 'react';

const MeetingQuickSVG = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7002 13.4187C16.3686 12.6333 15.8874 11.9199 15.2834 11.3182C14.6813 10.7147 13.9679 10.2336 13.1829 9.90137C13.1758 9.89785 13.1688 9.89609 13.1618 9.89258C14.2569 9.10156 14.9688 7.81309 14.9688 6.35938C14.9688 3.95117 13.0176 2 10.6094 2C8.20122 2 6.25004 3.95117 6.25004 6.35938C6.25004 7.81309 6.96196 9.10156 8.05708 9.89434C8.05004 9.89785 8.04301 9.89961 8.03598 9.90312C7.24848 10.2354 6.54184 10.7117 5.9354 11.3199C5.33196 11.9221 4.85083 12.6354 4.5186 13.4205C4.19221 14.1891 4.01618 15.0132 4.00004 15.848C3.99957 15.8668 4.00287 15.8855 4.00972 15.9029C4.01658 15.9204 4.02686 15.9363 4.03997 15.9498C4.05307 15.9632 4.06873 15.9739 4.08603 15.9812C4.10332 15.9884 4.1219 15.9922 4.14067 15.9922H5.19536C5.2727 15.9922 5.33422 15.9307 5.33598 15.8551C5.37114 14.498 5.91606 13.2271 6.87934 12.2639C7.87602 11.2672 9.19965 10.7188 10.6094 10.7188C12.0192 10.7188 13.3428 11.2672 14.3395 12.2639C15.3028 13.2271 15.8477 14.498 15.8829 15.8551C15.8846 15.9324 15.9461 15.9922 16.0235 15.9922H17.0782C17.0969 15.9922 17.1155 15.9884 17.1328 15.9812C17.1501 15.9739 17.1658 15.9632 17.1789 15.9498C17.192 15.9363 17.2023 15.9204 17.2091 15.9029C17.216 15.8855 17.2193 15.8668 17.2188 15.848C17.2012 15.0078 17.0272 14.1904 16.7002 13.4187ZM10.6094 9.38281C9.80258 9.38281 9.04321 9.06816 8.47192 8.49688C7.90063 7.92559 7.58598 7.16621 7.58598 6.35938C7.58598 5.55254 7.90063 4.79316 8.47192 4.22187C9.04321 3.65059 9.80258 3.33594 10.6094 3.33594C11.4163 3.33594 12.1756 3.65059 12.7469 4.22187C13.3182 4.79316 13.6329 5.55254 13.6329 6.35938C13.6329 7.16621 13.3182 7.92559 12.7469 8.49688C12.1756 9.06816 11.4163 9.38281 10.6094 9.38281Z"
      fill="#498FE2"
    />
  </svg>
);

export default MeetingQuickSVG;
