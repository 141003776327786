import React, { useState, useEffect } from 'react';
import { MultiSelect as MultiSelectComponent } from 'react-multi-select-component';
import SelectIconSVG from 'components/atoms/SVG/SelectIconSVG/SelectIconSVG';
import Close2SVG from 'components/atoms/SVG/Close2SVG/Close2SVG';
import './MultiSelect.scss';
import styled, { css } from 'styled-components';

const ArrowRenderer = () => <SelectIconSVG />;

const StyledMultiSelect = styled(MultiSelectComponent)`
  width: ${(props) => (props.fullWidth ? '100%' : '140px')};
  margin-right: ${(props) => (props.fullWidth ? '0' : '15px')};
  min-width: 44px;
  margin-bottom: ${(props) => (props.filterPanel ? '7px' : '0')};
  transition: none !important;
  /* z-index: 11; */

  .dropdown-heading {
    font-weight: ${(props) => (props.filterPanel ? '600' : '400')};
    ${({ disabled }) =>
      disabled
        ? css`
            color: ${({ theme }) => theme.textColorLight};
          `
        : null}
  }

  .gray {
    ${({ disabled }) =>
      disabled
        ? css`
            color: ${({ theme }) => theme.textColorLight};
          `
        : null}
  }

  .dropdown-container {
    > * {
      cursor: ${(props) => (props.disabled ? '' : 'pointer')} !important;
    }
  }

  .dropdown-content {
    z-index: 20;
  }

  .clear-selected-button {
    display: ${(props) => (props.disabled ? 'none' : '')} !important;
  }

  ${({ error }) =>
    error
      ? css`
          border: 1px solid ${({ theme }) => theme.warrningColor};
          border-radius: 6px;
        `
      : null}
`;

const MultiSelect = ({
  name,
  placeholder,
  data,
  onChange,
  fullWidth,
  selectId,
  error,
  filterPanel,
  disabled,
  defaultValues,
  selectAll,
  isLoading,
  unselectAll,
}) => {
  const [selected, setSelected] = useState(
    data?.filter((el) => defaultValues?.some((f) => f === el.label || f === el.value)) || null,
  );

  const customValueRenderer = () =>
    selected?.length ? selected.map(({ label }) => ` ${label} `) : placeholder;

  useEffect(() => {
    if (onChange)
      onChange(
        name,
        selectId ? selected?.map((item) => item.value) : selected?.map((item) => item.label),
      );
  }, [selected]);

  useEffect(() => {
    if (selectAll) {
      setSelected([...data]);
    }
  }, [selectAll]);

  useEffect(() => {
    if (unselectAll) {
      setSelected([]);
    }
  }, [unselectAll]);

  return (
    <StyledMultiSelect
      options={data}
      isLoading={isLoading}
      shouldToggleOnHover={('shouldToggleOnHover', false)}
      value={selected}
      disabled={disabled}
      onChange={setSelected}
      valueRenderer={customValueRenderer}
      ArrowRenderer={ArrowRenderer}
      ClearIcon={<Close2SVG />}
      fullWidth={fullWidth}
      error={error}
      filterPanel={filterPanel}
      overrideStrings={{
        noOptions: 'Brak danych',
        search: 'Szukaj',
        selectAll: 'Zaznacz wszystkie',
        selectAllFiltered: 'Zaznacz wszystkie',
      }}
    />
  );
};

export default MultiSelect;
