import React from 'react';
import { Field } from 'formik';
import styled, { css } from 'styled-components';

const StyledSimilarInput = styled(Field)`
  background: #fff;
  font-size: 14px;
  padding: 4px 7px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  font-weight: 400;
  width: 100%;
  text-align: left;
  cursor: text;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 18px;
  position: relative;
  outline: none;
  min-height: 100px;
  overflow: auto;
  &:disabled {
    cursor: auto;
  }

  ${({ error }) =>
    error
      ? css`
          border: 1px solid ${({ theme }) => theme.warrningColor};

          &:focus {
            border: 1px solid ${({ theme }) => theme.warrningColor};
          }
        `
      : null}

  &:disabled {
    cursor: auto;
    color: ${({ theme }) => theme.textColorLight};
    background: hsl(0, 0%, 95%) !important;
  }
`;

const CustomTextarea = ({ disabled, error, value, ...rest }) => (
  <StyledSimilarInput
    component="textarea"
    disabled={disabled}
    error={error}
    value={value || ''}
    {...rest}
  />
);

CustomTextarea.defaultProps = {
  value: '',
};

export default CustomTextarea;
