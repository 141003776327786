import { shoppingGroup } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';

import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['Industry'] });

export const shoppingGroupApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getShoppingGroup: builder.query({
      query: () => ({
        url: shoppingGroup,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['shoppingGroup'],
    }),
    deleteShoppingGroup: builder.mutation({
      query: (id) => ({
        url: `${shoppingGroup}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['shoppingGroup'],
    }),
    postShoppingGroup: builder.mutation({
      query: (name) => ({
        url: shoppingGroup,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['shoppingGroup'],
    }),
  }),
});

export const {
  useGetShoppingGroupQuery,
  useDeleteShoppingGroupMutation,
  usePostShoppingGroupMutation,
} = shoppingGroupApi;
