import React, { Suspense, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from 'components/atoms/Loader/Loader';
import { logowanie } from 'helpers/AllRouteName';

function PrivateRoute({ render, isAuthenticated }) {
  const location = useLocation();

  return (
    <Suspense fallback={<Loader />}>
      <div>
        {!isAuthenticated ? (
          <Navigate to={`${logowanie.path}${location.search && location.search}`} />
        ) : (
          render
        )}
      </div>
    </Suspense>
  );
}

PrivateRoute.propTypes = {
  render: PropTypes.element.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};
export default PrivateRoute;
