import React from 'react';

const CloseSVG = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.41406" width="16.4773" height="2" transform="rotate(45 1.41406 0)" fill="black" />
    <rect y="11.6523" width="16.4773" height="2" transform="rotate(-45 0 11.6523)" fill="black" />
  </svg>
);

export default CloseSVG;
