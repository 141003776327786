import React from 'react';
import LoaderSrc from 'assets/btnLoader.svg';
import styled from 'styled-components';

const StyledLoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.accentColor};
  top: 0;
  left: 0;
  cursor: wait;
`;

function LoaderBtn({ className }) {
  return (
    <StyledLoaderWrapper className={className}>
      <img src={LoaderSrc} alt="Loading" />
    </StyledLoaderWrapper>
  );
}

export default LoaderBtn;
