import React, { useRef } from 'react';
import styled from 'styled-components';
import { usePopup } from 'hooks/usePopup';
import CloseSVG from 'components/atoms/SVG/CloseSVG/CloseSVG';
import Modal from 'react-modal';

const StyledButtonClose = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  svg path {
    fill: ${({ theme }) => theme.textColor};
  }
`;
Modal.setAppElement('#root');

const Popup = ({ Content, isActive, handlePopup, width, data, overflowAuto, name }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      width: '90%',
      maxWidth: width !== 'small' ? '1200px' : '650px',
      maxHeight: 'calc(100vh - 110px)',
      overflow: overflowAuto ? 'auto' : 'initial',
    },
    overlay: { zIndex: 1000, background: 'rgb(0, 0, 0, 0.5)' },
  };

  const popupRef = useRef(null);
  usePopup(popupRef, handlePopup);

  return (
    <Modal
      isOpen={isActive || false}
      onRequestClose={() => handlePopup(false)}
      ariaHideApp={false}
      contentLabel="Selected Option"
      style={customStyles}
      id="modal"
    >
      <Content id="modal-content" data={data} handlePopup={handlePopup} name={name} />
      <StyledButtonClose type="button" onClick={() => handlePopup(false)}>
        <CloseSVG />
      </StyledButtonClose>
    </Modal>
  );
};

export default Popup;
