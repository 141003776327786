import React from 'react';

function CheckIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6925 1.09662C20.2824 0.686544 19.6177 0.686544 19.2076 1.09662L6.6279 13.6764L1.79248 8.84098C1.38245 8.43091 0.717671 8.43095 0.307556 8.84098C-0.102519 9.25102 -0.102519 9.9158 0.307556 10.3259L5.88543 15.9037C6.29534 16.3137 6.96062 16.3134 7.37036 15.9037L20.6925 2.58155C21.1026 2.17151 21.1025 1.50669 20.6925 1.09662Z"
        fill="#414141"
      />
    </svg>
  );
}

export default CheckIcon;
