import React from 'react';

const AttachmentSVG = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.68725 20.1252C4.45004 20.1279 3.26302 19.6362 2.3902 18.7594L1.36553 17.7347C-0.455148 15.9141 -0.455189 12.9622 1.36549 11.1415L1.36553 11.1415L9.75345 2.75354C9.92133 2.57976 10.1983 2.57492 10.3721 2.7428C10.5459 2.91068 10.5507 3.18762 10.3828 3.36145C10.3793 3.3651 10.3758 3.36867 10.3721 3.37219L1.98418 11.7592C0.505537 13.2384 0.505537 15.636 1.98418 17.1152L3.00971 18.1407C4.48888 19.6193 6.88649 19.6193 8.36566 18.1407L19.3252 7.18114C20.3914 6.11442 20.3914 4.38554 19.3252 3.31883L18.5561 2.54969C17.4747 1.51969 15.7752 1.51969 14.6938 2.54969L4.86557 12.3778C4.21149 13.0318 4.2114 14.0922 4.86541 14.7463C4.86545 14.7464 4.86553 14.7464 4.86557 14.7465L5.37832 15.2592C6.04475 15.8828 7.08054 15.8828 7.74697 15.2592L15.0035 8.00274C15.1713 7.82895 15.4483 7.82411 15.6221 7.99199C15.7959 8.15987 15.8007 8.43681 15.6329 8.61064C15.6293 8.61429 15.6258 8.61786 15.6221 8.62138L8.36566 15.8779C7.35141 16.828 5.77396 16.828 4.75971 15.8779L4.24697 15.3652C3.25121 14.3694 3.25121 12.755 4.24697 11.7592L14.0751 1.93104C15.4834 0.52283 17.7665 0.52283 19.1747 1.931C19.1747 1.931 19.1747 1.931 19.1747 1.93104L19.9439 2.70018C21.3521 4.1084 21.3521 6.39153 19.9439 7.79975C19.9439 7.79975 19.9439 7.79975 19.9439 7.79979L8.98431 18.7594C8.11148 19.6362 6.92447 20.1279 5.68725 20.1252Z"
      fill="#CFD8DC"
    />
  </svg>
);

export default AttachmentSVG;
