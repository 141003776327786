import React, { useEffect } from 'react';
import PopupHeader from 'components/molecules/PopupHeader/PopupHeader';
import FormTabs from 'components/organisms/FormTabs/FormTabs';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import PopupFooter from 'components/molecules/PopupFooter/PopupFooter';
import Button from 'components/atoms/Button/Button';
import { usePostContractorMutation } from 'redux/services/contractorApi';
import { toast } from 'react-toastify';
import { usePutContractorToInvestmentMutation } from 'redux/services/investmentApi';
import LoaderBtn from 'components/atoms/LoaderBtn/LoaderBtn';
import TabContentBasicData from './Tabs/TabContentBasicData';
import ContractorAddSchemaValidate from './ContractorAddSchemaValidate';

const StyledForm = styled(Form)`
  padding: 15px;
  position: relative;
  padding-top: 15px;
  width: 100%;
`;

const PopupContentContractorAdd = ({ handlePopup, data, name }) => {
  const [postContractor, result] = usePostContractorMutation();
  const [putContractorToInvestment] = usePutContractorToInvestmentMutation();

  useEffect(() => {
    if (result.isSuccess && data.addToInvestment) {
      toast.success('Kontrahent dodany pomyślnie');
      putContractorToInvestment({
        investmentId: data.addToInvestment,
        contractorId: result.data.id,
      });
      handlePopup();
    } else if (result.isSuccess) {
      setTimeout(() => {
        toast.success('Kontrahent dodany pomyślnie');
        handlePopup();
      }, 300);
    } else if (result.error) {
      toast.error(result.error.data);
    }
  }, [result]);

  return (
    <div>
      <PopupHeader>Dodawanie kontrahenta</PopupHeader>
      <Formik
        initialValues={{
          name: '',
          central: '',
          type: data.type || '',
          status: '',
          phone1: '',
          phone2: '',
          www: '',
          mail: '',
          street: '',
          postCode: '',
          city: '',
          province: '',
          country: '',
          industry: '',
          group: '',
          promising: '',
          level: '',
          region: [],
          traders: [],
          shoppingGroups: [],
          nameSurname: '',
          phone: '',
          email: '',
          position: '',
        }}
        onSubmit={async (values, setFieldValue) => {
          const requestBody = {
            name: values.name,
            ...(values.central && { central: values.central }),
            type: values.type,
            isActive: true,
            ...(values.status && {
              status: {
                id: values.status,
              },
            }),
            phone1: values.phone1,
            ...(values.phone2 && { phone2: values.phone2 }),
            ...(values.www && { website: values.www }),
            ...(values.mail && { email: values.mail }),
            ...(values.industry && { industry: { id: values.industry } }),
            ...(values.group && { group: { id: values.group } }),
            ...(values.promising && { promising: values.promising }),
            ...(values.level && { level: values.level }),
            ...(values.shoppingGroups.length && {
              shoppingGroups: values.shoppingGroups.map((shoppingGroup) => ({
                id: shoppingGroup,
              })),
            }),
            ...(values.region.length && {
              regions: values.region.map((region) => ({
                id: region,
              })),
            }),
            ...(values.traders.length && {
              traders: values.traders.map((trader) => ({
                id: trader,
              })),
            }),
            newContact: {
              nameSurname: values.nameSurname,
              phone: values.phone,
              email: values.email,
              position: values.position,
            },
            ...(values.street && { street: values.street }),
            ...(values.postCode && { zipCode: values.postCode }),
            ...(values.city && { city: values.city }),
            ...(values.province && { province: values.province }),
            ...(values.country && { country: values.country }),
          };

          const { data: ContractorData } = await postContractor(requestBody);

          data.setContractors({ label: ContractorData.name, value: ContractorData.id });
          if (ContractorData && name) {
            setFieldValue(name, ContractorData.id);
          }
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={ContractorAddSchemaValidate}
      >
        {({ values, errors }) => (
          <StyledForm>
            <FormTabs
              TabContent={TabContentBasicData}
              contentTab
              values={values}
              data={data}
              errors={errors}
            />
            <PopupFooter>
              <Button disabled={result.isSuccess} type="submit">
                Zapisz {result.isSuccess && <LoaderBtn />}
              </Button>
            </PopupFooter>
          </StyledForm>
        )}
      </Formik>
    </div>
  );
};

export default PopupContentContractorAdd;
