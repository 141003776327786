import React, { useEffect } from 'react';
import PopupHeader from 'components/molecules/PopupHeader/PopupHeader';
import { useLazyGetAbsenceQuery } from 'redux/services/absenceApi';
import FormAddAbsence from './FormAddAbsence';

const PopupAddAbsence = ({ data, handlePopup }) => {
  const [trigger, { data: absenceData }] = useLazyGetAbsenceQuery();
  useEffect(() => {
    if (data?.objectId || data?.id) {
      trigger(data.objectId || data.id);
    }
  }, []);

  return (
    <div>
      <PopupHeader>{data ? 'Nieobecność' : 'Dodaj nieobecność'}</PopupHeader>
      {data ? (
        <FormAddAbsence
          data={absenceData}
          permission={data?.permission}
          handlePopup={handlePopup}
        />
      ) : (
        <FormAddAbsence data={[]} permission={data?.permission} handlePopup={handlePopup} />
      )}
    </div>
  );
};

export default PopupAddAbsence;
