import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledList = styled.ul`
  padding: 0;
  margin-top: 0;
`;

const MenuList = ({ children }) => <StyledList className="mb-10 md:mb-16">{children}</StyledList>;

MenuList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MenuList;
