import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormLabel from '../../atoms/FormLabel/FormLabel';

const StyledDataPickerWrapper = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : 'calc(100% - 120px)')};
`;

const StyledFieldForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
`;

const FormDoubleField = ({ label, render }) => (
  <StyledFieldForm>
    <FormLabel>{label}</FormLabel>
    <StyledDataPickerWrapper>{render}</StyledDataPickerWrapper>
  </StyledFieldForm>
);

FormDoubleField.propTypes = {
  label: PropTypes.string,
};

export default FormDoubleField;
