import React from 'react';

const CloseSVG = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.93757 8.00465L15.7961 1.14611C16.0608 0.890455 16.0682 0.468636 15.8125 0.203925C15.5569 -0.0607856 15.135 -0.0681256 14.8703 0.187527C14.8648 0.192876 14.8593 0.198342 14.8539 0.203925L7.99535 7.06247L1.1368 0.203886C0.872091 -0.0517667 0.450272 -0.0444266 0.194619 0.220284C-0.0547872 0.478513 -0.0547872 0.887878 0.194619 1.14611L7.05316 8.00465L0.194619 14.8632C-0.065524 15.1234 -0.065524 15.5452 0.194619 15.8054C0.4548 16.0655 0.87662 16.0655 1.1368 15.8054L7.99535 8.94683L14.8539 15.8054C15.1186 16.061 15.5404 16.0537 15.7961 15.789C16.0454 15.5307 16.0454 15.1214 15.7961 14.8632L8.93757 8.00465Z"
      fill="#E20707"
    />
  </svg>
);

export default CloseSVG;
