/* eslint-disable no-shadow */
import React from 'react';
import { usePagination } from 'hooks/usePagination';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButtonNumber = styled.button`
  border: 1px solid ${({ active, theme }) => (active ? theme.accentColor : '#C1C1C1')};
  background: ${({ active, theme }) => (active ? theme.accentColor : 'transparent')};
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.textColor)};
  font-size: 14px;
  padding: 6px;
  min-width: 30px;
  border-right: 0;
  cursor: pointer;
  &:first-child {
    border-left: 0;
  }
`;

const StyledButton = styled.button`
  border: 1px solid ${({ active, theme }) => (active ? theme.accentColor : '#C1C1C1')};
  background: ${({ active, theme }) => (active ? theme.accentColor : 'transparent')};
  font-size: 14px;
  padding: 6px;
  font-weight: 600;
  color: ${({ accent, theme }) => (accent ? theme.accentColor : theme.textColor)};
  cursor: pointer;
  &:first-child {
    border-right: 0;
  }
`;

const StyledPagination = styled.div`
  display: flex;
  margin-top: 27px;
  justify-content: flex-end;
`;

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount = 10,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <StyledPagination className={className}>
      {/* Left navigation arrow */}
      <StyledButton type="button" disabled={currentPage === 1} onClick={onPrevious}>
        Poprzednia
      </StyledButton>
      {paginationRange.map((pageNumber, idx) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === 'DOTS') {
          return (
            <StyledButtonNumber key={pageNumber} className="pagination-item dots">
              &#8230;
            </StyledButtonNumber>
          );
        }

        // Render our Page Pills
        return (
          <StyledButtonNumber
            type="button"
            active={pageNumber === currentPage}
            onClick={() => onPageChange(pageNumber)}
            key={pageNumber}
          >
            {pageNumber}
          </StyledButtonNumber>
        );
      })}
      {/*  Right Navigation arrow */}
      <StyledButton type="button" disabled={currentPage === lastPage} onClick={onNext} accent>
        Następna
      </StyledButton>
    </StyledPagination>
  );
};

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Pagination.defaultProps = {
  siblingCount: 1,
  className: '',
};

export default Pagination;
