import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { logout } from 'actions/user/user.actions';
import PropTypes from 'prop-types';

const StyledDropdown = styled.ul`
  position: absolute;
  top: 65px;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px -2px #4a4a4a;
  -moz-box-shadow: 0px 0px 10px -2px #4a4a4a;
  box-shadow: 0px 0px 10px -2px #4a4a4a;
  margin: 0;
  background: #fff;
  display: ${({ isactive }) => (isactive ? 'block' : 'none')};
`;
const StyledLi = styled.li`
  font-size: 14px;
  margin-bottom: 10px;
  list-style: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.accentColor};
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.textColor};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.accentColor};
  }
`;

const ProfilInformationMenuDropdown = ({ logoutUser, isActive, ...rest }) => {
  const user = useSelector((state) => state.userReducer.data);

  return (
    <StyledDropdown isactive={isActive} {...rest}>
      <StyledLi>
        <StyledLink to="/ustawienia-systemu">Ustawienia systemu</StyledLink>
      </StyledLi>
      <StyledLi onClick={logoutUser}>Wyloguj</StyledLi>
    </StyledDropdown>
  );
};

ProfilInformationMenuDropdown.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

ProfilInformationMenuDropdown.defaultProps = {
  isActive: false,
};
const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(ProfilInformationMenuDropdown);
