import { task } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';

import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['Task', 'Statistic'] });

export const taskApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query({
      query: ({ page, size, sort, search }) => {
        const params = new URLSearchParams({
          ...(page && { page }),
          ...(size && { size }),
          ...(sort && { sort }),
          search: `${
            search?.search
              ? `producer:${search?.search}|contractor:${search?.search}|investment:${search?.search}&`
              : ''
          }${search?.startDate ? `startDate>${search.startDate}T00:00&` : ''}${
            search?.endDate ? `endDate<${search.endDate}T23:59&` : ''
          }${
            search?.producer.length
              ? `${search.producer.map((item) => `producer=${item}`).join('|')}&`
              : ''
          }${
            search?.contractor.length
              ? `${search.contractor.map((item) => `contractor=${item}`).join('|')}&`
              : ''
          }${
            search?.department.length
              ? `${search.department.map((item) => `department=${item}`).join('|')}&`
              : ''
          }${
            search?.regionContractor?.length
              ? `${search.regionContractor.map((item) => `regionContractor=${item}`).join('|')}&`
              : ''
          }${
            search?.regionInvestment?.length
              ? `${search.regionInvestment.map((item) => `regionInvestment=${item}`).join('|')}&`
              : ''
          }${
            search?.industry.length
              ? `${search.industry.map((item) => `industry=${item}`).join('|')}&`
              : ''
          }${search?.type.length ? `${search.type.map((item) => `type=${item}`).join('|')}&` : ''}${
            search?.status.length
              ? `${search.status.map((item) => `status=${item}`).join('|')}&`
              : ''
          }`,
        });

        return {
          url: `${task}?${params.toString()}`,

          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['Task'],
    }),

    getTaskContractor: builder.query({
      query: ({ id, page, size, sort }) => {
        const params = new URLSearchParams({
          ...(page && { page }),
          ...(size && { size }),
          ...(sort && { sort }),
        });

        return {
          url: `${task}/contractor/${id}?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['Task'],
    }),

    getContractorActivity: builder.query({
      query: ({ id, page, size, sort }) => {
        const params = new URLSearchParams({
          ...(page && { page }),
          ...(size && { size }),
          ...(sort && { sort }),
        });

        return {
          url: `${task}/last-activity/contractor/${id}?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['Task'],
    }),

    getInvestmentTask: builder.query({
      query: ({ id, page, size }) => {
        const params = new URLSearchParams({
          ...(page && { page }),
          ...(size && { size }),
        });

        return {
          url: `${task}/investment/${id}?${params.toString()}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['Task'],
    }),

    getTask: builder.query({
      query: (id) => ({
        url: `${task}/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['Task'],
    }),

    getInvestmentContractorTask: builder.query({
      query: ({ investmentId, type, contractorId }) => {
        const params = new URLSearchParams({
          ...(type && { type }),
          ...(contractorId && { contractorId }),
        });

        return {
          url: `${task}/investment/${investmentId}/contractors?${params.toString()}`,
          method: 'GET',
          headers: {
            ...getAuthorizationHeader(),
          },
        };
      },
      providesTags: ['Task'],
    }),

    deleteTask: builder.mutation({
      query: (id) => ({
        url: `${task}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Task', 'Statistic'],
    }),

    postTask: builder.mutation({
      query: (data) => ({
        url: task,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['Task', 'Statistic'],
    }),

    putTask: builder.mutation({
      query: (data) => ({
        url: task,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: data,
      }),
      invalidatesTags: ['Task', 'Statistic'],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetInvestmentTaskQuery,
  useLazyGetTaskQuery,
  useGetTaskContractorQuery,
  useGetContractorActivityQuery,
  useDeleteTaskMutation,
  usePostTaskMutation,
  usePutTaskMutation,
  useGetInvestmentContractorTaskQuery,
} = taskApi;
