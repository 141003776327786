import * as Yup from 'yup';

export const handleNewField = (information) =>
  information?.map((value) => ({
    label: value.name,
    value: value.id,
    ...(value.isDisabled && { isDisabled: value.isDisabled }),
  }));

export const enumToSelect = (enumData) =>
  Object.entries(enumData).map(([key, value]) => ({
    id: key,
    name: value,
  }));

export const passwordPattern = Yup.string()
  .required('Hasło jest wymagane')
  .matches(/\d/, 'Hasło musi zawierać cyfrę')
  .matches(/^\S*$/, 'Spacje nie są dozwolone')
  .matches(/[!+@#$%^&*()\-_"=+{}; :,<.>]/, 'Hasło musi zawierać znak specjalny')
  .matches(/[a-zA-Z]/, 'Hasło musi zawierać literę')
  .min(8, ({ min }) => `Hasło musi zawierać conajmniej ${min} znaków`)
  .max(30, `Hasło może zawierać 30 znaków`);

export const confirmPassword = Yup.string()
  .required('Hasło jest wymagane')
  .oneOf([Yup.ref('password')], 'Oba hasła muszą być takie same.');
