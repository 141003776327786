import PropTypes from 'prop-types';

const AccessControl = ({ permission, children, renderNoAccess }) => {
  const permitted = permission;

  if (permitted) {
    return children;
  }
  return renderNoAccess;
};

AccessControl.propTypes = {
  allowedPermissions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.element.isRequired,
  renderNoAccess: PropTypes.oneOfType([PropTypes.element, PropTypes.number]),
};
AccessControl.defaultProps = {
  renderNoAccess: null,
};

export default AccessControl;
