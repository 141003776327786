import React from 'react';
import styled from 'styled-components';
import FilterWrapper from 'components/molecules/FilterWrapper/FilterWrapper';
import DataPickerComponent from 'components/atoms/DataPicker/DataPicker';
import { Formik, Form } from 'formik';
import Button from 'components/atoms/Button/Button';
import { useSearchParams } from 'react-router-dom';
import SearchInput from 'components/molecules/SearchInput/SearchInput';
import Checkbox from 'components/molecules/Checkbox/Checkbox';
import MultiSelect from 'components/molecules/MultiSelect/MultiSelect';
import { enums } from 'staticData/enums';
import { handleNewField } from 'helpers/formsHelpers';
import FilterWrapperContractorsValidate from './FilterWrapperContractorsValidate';

const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
`;
const StyledButton = styled(Button)`
  margin-bottom: 7px;
`;

const StyledFirstColumn = styled.div`
  width: 27%;
  margin-right: 15px;
  max-width: 318px;
`;

const StyledSecoundColumn = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
`;

const FilterWrapperContractors = ({ data, setSearchParams }) => {
  const [search, setSearch] = useSearchParams();
  return (
    <FilterWrapper>
      <Formik
        initialValues={{
          province: search.getAll('province') || '',
          trader: search.getAll('trader') || '',
          status: search.getAll('status') || '',
          name: search.get('name') || '',
          customerLevel: search.getAll('customerLevel') || '',
          startDate: search.get('startDate') || '',
          endDate: search.get('endDate') || '',
          promising: search.getAll('promising') || '',
          industry: search.getAll('industry') || '',
          group: search.getAll('group') || '',
          // type: [data.type] || search.getAll('type') || '',
        }}
        onSubmit={async (values) => {
          // eslint-disable-next-line no-unused-expressions
          setSearchParams ? setSearchParams(values) : setSearch(values);
        }}
        validationSchema={FilterWrapperContractorsValidate}
      >
        {({ values, errors, setFieldValue }) => (
          <StyledForm method="get">
            <StyledFirstColumn>
              <SearchInput name="name" value={values.name} />
              {/* <Checkbox
                name="connectWithBaseSAP"
                label="Połącz z bazą SAP"
                accentColor="#C1C1C1"
                marginTop="7px  "
              /> */}
            </StyledFirstColumn>
            <StyledSecoundColumn>
              {data.provinceData && (
                <MultiSelect
                  name="province"
                  onChange={setFieldValue}
                  filterPanel
                  placeholder="Województwo"
                  data={handleNewField(data.provinceData)}
                  defaultValues={values.province}
                />
              )}

              {data.clientStatusData && (
                <MultiSelect
                  name="status"
                  onChange={setFieldValue}
                  filterPanel
                  placeholder="Status"
                  data={handleNewField(data.clientStatusData)}
                  defaultValues={values.status}
                />
              )}
              <MultiSelect
                name="customerLevel"
                onChange={setFieldValue}
                filterPanel
                placeholder="Poziom klienta"
                data={handleNewField(enums.levels)}
                selectId
                defaultValues={values.customerLevel}
              />
              <MultiSelect
                name="promising"
                onChange={setFieldValue}
                filterPanel
                placeholder="Rokujący"
                data={handleNewField(enums.promising)}
                selectId
                defaultValues={values.promising}
              />
              {data.industryData && (
                <MultiSelect
                  name="industry"
                  onChange={setFieldValue}
                  filterPanel
                  placeholder="Branża"
                  data={handleNewField(data.industryData)}
                  defaultValues={values.industry}
                />
              )}
              {data.groupData && (
                <MultiSelect
                  name="group"
                  onChange={setFieldValue}
                  filterPanel
                  placeholder="Grupa"
                  data={handleNewField(data.groupData)}
                  defaultValues={values.group}
                />
              )}

              <DataPickerComponent
                value={values.startDate}
                placeholderText="Data od"
                name="startDate"
                marginRight="15px"
                error={errors.startDate}
              />
              <DataPickerComponent
                value={values.endDate}
                placeholderText="Data do"
                name="endDate"
                disabled={!values.startDate}
                minDate={new Date(values.startDate)}
                clear={!values.startDate}
                error={errors.endDate}
              />
              <StyledButton type="submit">filtruj</StyledButton>
            </StyledSecoundColumn>
          </StyledForm>
        )}
      </Formik>
    </FilterWrapper>
  );
};

export default FilterWrapperContractors;
