import { createSlice } from '@reduxjs/toolkit';

export const filtersUtilsSlice = createSlice({
  name: 'filtersUtilsSlice',
  initialState: {
    calendar: [],
    isReady: false,
  },
  reducers: {
    setCalendarFilters: (state, action) => ({
      ...state,
      calendar: action.payload,
    }),
  },
});

export const { setCalendarFilters } = filtersUtilsSlice.actions;

export default filtersUtilsSlice.reducer;
