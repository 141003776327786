import React, { useState, useRef, useEffect } from 'react';
import Popup from 'components/organisms/Popup/Popup';
import { usePopup } from 'hooks/usePopup';
import styled from 'styled-components';

const StyledEvent = styled.div`
  cursor: pointer;
  height: ${({ h100 }) => (h100 ? '100%' : null)};
  width: 100%;
  height: 100%;
  display: inline-block;
  text-overflow: ellipsis;
`;

const ComponentWithPopup = ({
  data,
  content,
  width,
  overflowauto,
  children,
  h100,
  handler,
  markAsRead,
  rest,
}) => {
  const [isActive, setActive] = useState();
  const popupRef = useRef(null);

  useEffect(() => {
    if (handler && !markAsRead && isActive === false) {
      handler();
    }
  }, [isActive]);

  usePopup(popupRef, setActive);
  return (
    <div {...rest}>
      <StyledEvent
        h100={h100}
        onClick={() => {
          setActive(true);
        }}
      >
        {children}
      </StyledEvent>
      <Popup
        isActive={isActive}
        width={width || data?.informations?.width}
        Content={content}
        handlePopup={setActive}
        handlePopup2={handler}
        data={data}
        overflowAuto={overflowauto || !!data?.informations?.overflowauto}
      />
    </div>
  );
};

export default ComponentWithPopup;
