import { group } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';

import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['Group'] });

export const groupApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getGroup: builder.query({
      query: () => ({
        url: group,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['Group'],
    }),
    deleteGroup: builder.mutation({
      query: (id) => ({
        url: `${group}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Group'],
    }),
    postGroup: builder.mutation({
      query: (name) => ({
        url: group,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['Group'],
    }),
  }),
});

export const { useGetGroupQuery, useDeleteGroupMutation, usePostGroupMutation } = groupApi;
