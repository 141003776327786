import React from 'react';
import DashboardSVG from 'components/atoms/SVG/DashboardSVG/DashboardSVG';
import TargetSVG from 'components/atoms/SVG/TargetSVG/TargetSVG';
import CalendarSVG from 'components/atoms/SVG/CalendarSVG/CalendarSVG';
import ContractorsSVG from 'components/atoms/SVG/ContractorsSVG/ContractorsSVG';
import TasksSVG from 'components/atoms/SVG/TasksSVG/TasksSVG';
import RepositorySVG from 'components/atoms/SVG/RepositorySVG/RepositorySVG';
import UsersSVG from 'components/atoms/SVG/UsersSVG/UsersSVG';
import InvestmentSVG from 'components/atoms/SVG/InvestmentSVG/InvestmentSVG';
import {
  home,
  prowizjeTargety,
  kalendarz,
  kontrahenci,
  zadania,
  zamowienia,
  serwis,
  repozytorium,
  uzytkownicy,
  investments,
  nieobecnosci,
  nieobecnosciZaplanowane,
  nieobecnosciDoAkceptacji,
  nieobecnosciTrwajace,
} from 'helpers/AllRouteName';
import { useSelector } from 'react-redux';

export const menuItemListFirstSection = [
  {
    Icon: <DashboardSVG />,
    to: home.path,
    name: 'Dashboard',
    permission: 'Dashboard',
  },
  {
    Icon: <TargetSVG />,
    to: prowizjeTargety.path,
    name: 'Prowizje i targety',
    permission: 'Prowizje i targety',
  },
];

export const menuItemListSecondSection = () => {
  const permission = useSelector((state) => state.permissionsReducer.data?.Nieobecności);

  return [
    {
      Icon: <CalendarSVG />,
      to: `${kalendarz.path}/?wszystko=true`,
      name: 'Kalendarz',
      permission: 'Kalendarz',
      submenu: [
        {
          to: nieobecnosci.path,
          name: 'Nieobecności',
          permission: Object.values(permission || [])?.some((item) => item === true),
          submenu: [
            {
              to: nieobecnosciZaplanowane.path,
              name: 'Zaplanowane',
              permission: permission?.['dostęp do modułu "Zaplanowane"'],
            },
            {
              to: nieobecnosciDoAkceptacji.path,
              name: 'Do akceptacji',
              permission: permission?.['dostęp do modułu "Do akceptacji"'],
            },
            {
              to: nieobecnosciTrwajace.path,
              name: 'Trwające',
              permission: permission?.['dostęp do modułu "Trwające"'],
            },
            {
              to: '/kalendarz?nieobecnosci=true',
              name: 'Wszystkie',
              permission: permission?.['dostęp do modułu "Wszystkie"'],
            },
          ],
        },
        // {
        //   to: kalendarz.path,
        //   name: 'Zadania',
        // },
      ],
    },
    {
      Icon: <ContractorsSVG />,
      to: kontrahenci.path,
      name: 'Kontrahenci',
      permission: 'Kontrahenci',
    },
    {
      Icon: <InvestmentSVG />,
      to: investments.path,
      name: 'Inwestycje',
      permission: 'Inwestycje',
    },
    {
      Icon: <TasksSVG />,
      to: zadania.path,
      name: 'Zadania',
      permission: 'Zadania',
    },
    // {
    //   Icon: <OrdersSVG />,
    //   to: zamowienia.path,
    //   name: 'Zamówienia',
    //   permission: 'Zamówienia',
    // },
  ];
};

export const menuItemListThirdSection = [
  // {
  //   Icon: <SettingsSVG />,
  //   to: serwis.path,
  //   name: 'Serwis',
  //   permission: 'Serwis',
  // },
  {
    Icon: <RepositorySVG />,
    to: repozytorium.path,
    name: 'Repozytorium',
    permission: 'Pliki',
  },
  {
    Icon: <UsersSVG />,
    to: uzytkownicy.path,
    name: 'Użytkownicy',
  },
];
