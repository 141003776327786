import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledFilterWrapper = styled.div`
  background: #fff;
  padding: 7px 15px 0 15px;
  
  margin-bottom: 14px;
  display: flex;
`;
const FilterWrapper = ({ children }) => <StyledFilterWrapper>{children}</StyledFilterWrapper>;

FilterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FilterWrapper;
