import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { logout } from 'actions/user/user.actions';
import PropTypes from 'prop-types';

import CalendarTemplateNotification from 'components/templates/CalendarTemplateNotification/CalendarTemplateNotification';

const StyledDropdown = styled.ul`
  position: absolute;
  top: 50px;
  left: -106px;
  width: 400px;
  padding: 0px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px -2px #4a4a4a;
  -moz-box-shadow: 0px 0px 10px -2px #4a4a4a;
  box-shadow: 0px 0px 10px -2px #4a4a4a;
  margin: 0;
  background: #fff;
  display: ${({ isactive }) => (isactive ? 'block' : 'none')};
  min-height: 600px;
  height: 100%;
  z-index: 40;
`;

const StyledHeaderLi = styled.li`
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  background: #f3f7ff;
  border-radius: 5px 5px 0 0;
`;

const ShowAllLink = styled(Link)`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.textColor};
  text-decoration: none;
  line-height: 14px;
  &:hover {
    color: ${({ theme }) => theme.accentColor};
  }
`;

const CalendarDropdown = ({ isActive, setActive, workHours, ...rest }) => (
  <StyledDropdown isactive={isActive} {...rest}>
    <StyledHeaderLi>
      kalendarz
      <ShowAllLink onClick={() => setActive(false)} to="/kalendarz">
        Pokaż więcej
      </ShowAllLink>
    </StyledHeaderLi>
    <div className="max-h-[540px] overflow-auto">
      <CalendarTemplateNotification workHours={workHours} />
    </div>
  </StyledDropdown>
);

CalendarDropdown.propTypes = {
  isActive: PropTypes.bool,
};

CalendarDropdown.defaultProps = {
  isActive: false,
};
const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(CalendarDropdown);
