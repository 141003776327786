import { industry } from '../../endpoints/endpoints';
import { getAuthorizationHeader } from './getAuthorizationHeader';

import { apiSlice } from './apiSlice';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: ['Industry'] });

export const industryApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getIndustry: builder.query({
      query: () => ({
        url: industry,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      providesTags: ['Industry'],
    }),
    deleteIndustry: builder.mutation({
      query: (id) => ({
        url: `${industry}/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
      }),
      invalidatesTags: ['Industry'],
    }),
    postIndustry: builder.mutation({
      query: (name) => ({
        url: industry,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthorizationHeader(),
        },
        body: { name },
      }),
      invalidatesTags: ['Industry'],
    }),
  }),
});

export const { useGetIndustryQuery, useDeleteIndustryMutation, usePostIndustryMutation } =
  industryApi;
