import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Pagination from 'components/molecules/Pagination/Pagination';
import { useSearchParams } from 'react-router-dom';
import SortUpSVG from 'components/atoms/SVG/SortUpSVG/SortUpSVG';
import SortDownSVG from 'components/atoms/SVG/SortDownSVG/SortDownSVG';
import { usePutMarkReadNotificationMutation } from 'redux/services/notificationApi';

const StyledTable = styled.table`
  width: 99%;
  border-collapse: collapse;
`;

const StyledTableHeader = styled.th`
  text-align: ${({ headerAlignText }) => headerAlignText};
  padding: ${({ paddingTh }) => paddingTh};
  border-left: ${({ offBorderLeft }) =>
    offBorderLeft ? '0px solid #dddddd' : '1px solid #dddddd'};
  border-right: ${({ offBorderRight }) =>
    offBorderRight ? '0px solid #dddddd' : '1px solid #dddddd'};
  /* border-top: ${({ offBorderTop }) =>
    offBorderTop ? '0px solid #dddddd' : '1px solid #dddddd'}; */
  border-bottom: ${({ offBorderBottom }) =>
    offBorderBottom ? '0px solid #dddddd' : '1px solid #dddddd'};
`;

const StyledTableHeaderWrapper = styled.div`
  display: flex;
`;

const StyledThead = styled.thead`
  box-shadow: inset 0 -2px 0 #498fe2;
`;

const StyledTableCell = styled.td`
  text-align: left;
  border-left: ${({ offBorderLeft }) =>
    offBorderLeft ? '0px solid #dddddd' : '1px solid #dddddd'};
  border-right: ${({ offBorderRight }) =>
    offBorderRight ? '0px solid #dddddd' : '1px solid #dddddd'};
  border-top: ${({ offBorderTop }) => (offBorderTop ? '0px solid #dddddd' : '1px solid #dddddd')};
  border-bottom: ${({ offBorderBottom }) =>
    offBorderBottom ? '0px solid #dddddd' : '1px solid #dddddd'};
  font-size: ${({ fontsizetd }) => fontsizetd};
`;

const StyledTr = styled.tr`
  transition: 0.2s background ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.lightBlueBackground};
  }
`;

const StyledSortButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  justify-content: center;
  align-items: center;
`;

const StyledItem = styled.div`
  padding: ${({ padding }) => padding};
`;

const StyledSortButton = styled.button`
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  line-height: 0px;
  padding: 1px;
  cursor: pointer;
`;

const PageSize = 10;
const Table = ({
  data,
  paddingTh,
  paddingTd,
  padding,
  offBorderLeft,
  offBorderRight,
  offBorderTop,
  offBorderBottom,
  headerAlignText,
  fontSizeTd,
  headerData,
  activeSort,
  setSort,
  totalElements,
  information,
  Component,
  popup,
  link,
  tableIds,
  markAsRead,
  permission,
  details,
  repositoryList,
  setPage,
  onClickHandler,
  className,
  selectedId,
}) => {
  const [search, setSearch] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    search.get('page') ? Number(search.get('page')) : 1,
  );

  const [sortStatus, setSortStatus] = useState('');

  useEffect(() => {
    setCurrentPage(search.get('page') ? Number(search.get('page')) : 1);
  }, [search.get('page')]);

  const [putMarkReadNotification] = usePutMarkReadNotificationMutation();

  const rednerRow = (dataToCreateRow) => {
    const result = dataToCreateRow?.map((row, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <StyledTr key={index}>
        {Object.keys(row).map((key) => (
          <StyledTableCell
            fontsizetd={fontSizeTd}
            offBorderBottom={offBorderBottom}
            offBorderTop={offBorderTop}
            offBorderRight={offBorderRight}
            offBorderLeft={offBorderLeft}
            paddingTd={paddingTd}
            key={key}
            className={`${key === 'permission' && 'w-full'} ${
              selectedId?.includes(information[index].id) && 'bg-colorlightBackground'
            }`}
          >
            {key !== 'option' && Component ? (
              <Component
                {...(onClickHandler &&
                  !selectedId?.includes(information[index].id) && {
                    onClick: () =>
                      onClickHandler({
                        label: information[index].name,
                        value: information[index].id,
                      }),
                  })}
                content={
                  information[index].eventType
                    ? popup?.component(information[index].eventType)
                    : popup?.component
                }
                {...(markAsRead && {
                  handler: () => putMarkReadNotification(information[index].id),
                })}
                width={popup?.width}
                overflowauto={popup?.overflowAuto ? popup?.overflowAuto : undefined}
                data={{ data: information[index], id: tableIds?.[index], permission, ...details }}
                to={
                  // eslint-disable-next-line no-nested-ternary
                  link?.to === 'investment'
                    ? `/inwestycje/${information[index].id}/dane-podstawowe`
                    : link?.to === 'contractor'
                    ? `/kontrahenci/${information[index].id}/dane-podstawowe`
                    : ''
                }
              >
                <StyledItem
                  className={`${selectedId?.includes(information[index].id) && 'cursor-auto'}`}
                  padding={padding}
                >
                  {row[key]}
                </StyledItem>
              </Component>
            ) : (
              <StyledItem padding={padding}>{row[key]}</StyledItem>
            )}
          </StyledTableCell>
        ))}
      </StyledTr>
    ));
    return result;
  };

  return (
    <>
      <div
        className={`max-h-[calc(100% - 56px)] h-full overflow-auto ${
          repositoryList && 'pb-14'
        } ${className}`}
      >
        <StyledTable>
          <StyledThead className="sticky top-0 z-10 bg-colorWhite">
            <tr>
              {Object.keys(headerData).map((key) => (
                <StyledTableHeader
                  headerAlignText={headerAlignText}
                  offBorderBottom={offBorderBottom}
                  offBorderTop={offBorderTop}
                  offBorderRight={offBorderRight}
                  offBorderLeft={offBorderLeft}
                  paddingTh={paddingTh}
                  key={key}
                >
                  <StyledTableHeaderWrapper>
                    {headerData[key]}
                    {headerData[key] !== 'Opcje' &&
                      headerData[key] !== 'Status' &&
                      headerData[key] !== '' &&
                      activeSort && (
                        <StyledSortButtonWrapper>
                          <StyledSortButton
                            type="button"
                            onClick={() => {
                              setSortStatus(`${key},asc`);
                              setSort(`${key},asc`);
                            }}
                          >
                            <SortUpSVG
                              className={`${
                                sortStatus === `${key},asc`
                                  ? 'scale-150 fill-coloraccentHover'
                                  : 'fill-colortext'
                              }`}
                            />
                          </StyledSortButton>
                          <StyledSortButton
                            type="button"
                            onClick={() => {
                              setSort(`${key},desc`);
                              setSortStatus(`${key},desc`);
                            }}
                          >
                            <SortDownSVG
                              className={`${
                                sortStatus === `${key},desc`
                                  ? ' scale-150 fill-coloraccentHover'
                                  : 'fill-colortext'
                              }`}
                            />
                          </StyledSortButton>
                        </StyledSortButtonWrapper>
                      )}
                  </StyledTableHeaderWrapper>
                </StyledTableHeader>
              ))}
            </tr>
          </StyledThead>
          <tbody className="overflow-auto">{rednerRow(data || data?.tableData)}</tbody>
        </StyledTable>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalElements}
        pageSize={PageSize}
        onPageChange={(page) => {
          // eslint-disable-next-line no-unused-expressions
          setPage ? setPage(page - 1) : (search.set('page', page), setSearch(search));

          setCurrentPage(page);
        }}
      />
    </>
  );
};

Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  headerData: PropTypes.any,
  data: PropTypes.arrayOf(PropTypes.object),
  paddingTh: PropTypes.string,
  paddingTd: PropTypes.string,
  padding: PropTypes.string,
  offBorderLeft: PropTypes.bool,
  offBorderRight: PropTypes.bool,
  offBorderTop: PropTypes.bool,
  offBorderBottom: PropTypes.bool,
  headerAlignText: PropTypes.string,
  fontSizeTd: PropTypes.string,
  activeSort: PropTypes.bool,
};

Table.defaultProps = {
  paddingTh: '7px',
  padding: '17px',
  paddingTd: '29px 26px 27px',
  fontSizeTd: '16px',
  offBorderLeft: false,
  offBorderRight: false,
  offBorderTop: false,
  offBorderBottom: false,
  headerAlignText: 'center',
  activeSort: false,
};

export default Table;
